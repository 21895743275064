import { createSlice } from "@reduxjs/toolkit";
interface ISelectionInfo {
  quantity: number;
  total: number;
  subjectCodeForChange: string;
}

const initialState: ISelectionInfo = {
  quantity: 0,
  total: 0,
  subjectCodeForChange: "",
};

export const subjectSelectionSlice = createSlice({
  name: "subjectSelection",
  initialState,
  reducers: {
    sumSubjectPrice: (state, action) => {
      if (action.payload.quantity) {
        state.quantity = state.quantity + action.payload.quantity;
      } else {
        state.quantity = state.quantity + 1;
      }
      state.total = state.total + +action.payload.cost;
    },
    substractSubjectPrice: (state, action) => {
      state.quantity = state.quantity - 1;
      state.total = state.total - +action.payload;
    },
    resetSelection: (state) => {
      state.quantity = 0;
      state.total = 0;
    },
    setSubjectCodeForChange: (state, action) => {
      state.subjectCodeForChange = action.payload;
    },
  },
});

export const { sumSubjectPrice, substractSubjectPrice, setSubjectCodeForChange, resetSelection } =
  subjectSelectionSlice.actions;

export default subjectSelectionSlice.reducer;
