import { Button, Flex, Constants } from "@nubeteck/components";
import styled from "styled-components";

const { maxDevice } = Constants;

export const ButtonsContainer = styled(Flex)`
  width: 98%;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;

  @media ${maxDevice.mobileL} {
    flex-direction: column-reverse;
  }
`;

export const ButtonPrimaryStyled = styled(Button)`
  margin: 20px 10px;
  color: #fff;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary500};
  @media ${maxDevice.mobileL} {
    width: 100%;
    margin: 20px 0px;
    font-size: 18px;
  }
`;
export const ButtonAlternativeStyled = styled(Button)`
  margin: 10px 10px;
  color: ${({ theme }) => theme.colors.primary500};
  font-weight: 700;
  border: 2px solid #006aea;

  @media ${maxDevice.mobileL} {
    width: 100%;
    margin: 10px 0px;
    font-size: 18px;
  }
`;
