import {
  ISchedule,
  ISection,
  ISectionColoured,
  ISelectedSubject,
  ISubject,
} from "src/Interfaces/subject";
import { convertHourToNumber, getRandomColor } from "src/utils";
import { useCallback, useState, useEffect } from "react";
import useManageSlots from "./useManageSlots";

interface ICollisionResult {
  subjectCollision: ISelectedSubject | undefined;
  section: ISection;
}

export default function useManageSubject(
  selectedData: any[],
  registeredSubjects: any[],
  subjects: ISubject[],
  selectedSubject: ISelectedSubject | undefined,
  confirm: (newSection: ISection, actualSection: ISelectedSubject) => void,
  updateData: () => void,
  periodId: number,
) {
  const [selectedSections, setSelectedSections] = useState<Array<ISectionColoured>>([]);
  const [sectionsSlots, setSectionsSlots] = useState<any[]>([]);

  const { addSlot, removeSlot, getAvailableSlots, isLoadingSomething } = useManageSlots(
    (secciones: any[]) => setSectionsSlots(secciones),
  );

  useEffect(() => {
    if (selectedData?.length == 0) {
      setSelectedSections([]);
    } else if (subjects?.length > 0) {
      const tempData = selectedData;
      setSelectedSections(
        tempData?.map((seccion: ISection) => {
          const sec = subjects
            ?.find((s) => s.asignaturaId === seccion.asignaturaId)
            ?.secciones?.find((se) => se.seccionId === seccion.seccionId);

          return {
            ...seccion,
            color: getRandomColor(),
            asignaturaCodigo: seccion?.asignaturaCodigo,
            asignaturaNombre: seccion?.asignaturaNombre ?? "",
            asignaturaCosto: seccion?.costo,
            cupo: sec?.cupo ?? "",
            asignaturaSeccionCodigo: sec?.asignaturaSeccionCodigo ?? "",
            seccionId: sec?.seccionId ?? 0,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, subjects]);

  const getSlotsBySection = useCallback(
    (sectionId: number) => {
      const slots = sectionsSlots?.find((sec) => sec.seccionId === sectionId)?.cupoString;
      return slots ?? 0;
    },
    [sectionsSlots],
  );

  const verifyCollision = useCallback(
    (section: ISectionColoured) => {
      const subjectCollision = selectedSections
        ?.concat(registeredSubjects?.map((r) => ({ ...r, estaInscrita: true })) ?? [])
        .find(
          (currentSelectedSection) =>
            currentSelectedSection.horarios.some((horario: ISchedule) =>
              section.horarios.some(
                (sectionHorario) =>
                  sectionHorario.diaId !== 8 &&
                  sectionHorario.diaId !== 9 &&
                  sectionHorario.diaId === horario.diaId &&
                  convertHourToNumber(sectionHorario.horaInicio) <
                    convertHourToNumber(horario.horaFin) &&
                  convertHourToNumber(sectionHorario.horaFin) >
                    convertHourToNumber(horario.horaInicio),
              ),
            ) && currentSelectedSection.asignaturaId !== section.asignaturaId,
        );

      return { subjectCollision, section } as ICollisionResult;
    },
    [selectedSections, registeredSubjects],
  );

  const handleCollision = (obj: ICollisionResult) => {
    if (obj.subjectCollision) confirm(obj.section, obj.subjectCollision);
    else addSection(obj.section);
  };

  const addSection = (section: ISection) => {
    removeSlot(section.seccionId, section.carreraId, periodId, updateData);
  };

  const removeSection = (section: ISection) => {
    addSlot(section.seccionId, section.carreraId, periodId, updateData);
  };

  const findScheduleCoincidence = (
    startHour: { twentyFour: string; twelve: string },
    sectionsToCheck: ISectionColoured[],
  ) => {
    const coincidences: Array<{ seccion: ISectionColoured; horarios: ISchedule[] }> = [];

    sectionsToCheck?.forEach((seccion) => {
      seccion.horarios.forEach((horario) => {
        if (
          convertHourToNumber(horario.horaInicio).toString().split(".")[0] ===
          convertHourToNumber(startHour.twelve).toString().split(".")[0]
        ) {
          coincidences.push({ seccion, horarios: [horario] });
        }
      });
    });

    return coincidences;
  };

  return {
    getSlotsBySection,
    findScheduleCoincidence,
    removeSection,
    handleCollision,
    addSection,
    verifyCollision,
    getAvailableSlots,
    isLoadingSomething,
    selectedSections,
  };
}
