import { styled } from "styled-components";
import { Box, Flex, Heading, Space, Text, Constants, Card } from "@nubeteck/components";
import { maxDevice } from "@nubeteck/components/build/utils/constansts";

const { device } = Constants;

export const UserCardBody = styled(Space)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: 10px !important;
  justify-content: space-between;

  @media ${device.mobileL}, @media ${device.mobileM} {
    flex-direction: row;
    align-items: center;
  }

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export const CardBody = styled(Space)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobileL} {
    align-items: center;
  }
`;

export const ImageContainer = styled(Box)`
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;

export const CardUserFullName = styled(Flex)`
  align-items: center;
  gap: 16px;
  min-width: 215px;

  span.ant-typography {
    display: block;
    overflow: hidden;
    max-width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media ${maxDevice.laptop} {
    min-width: 100px;
    span.ant-typography {
      max-width: 70px;
    }
  }
`;

export const CardUserId = styled(Space)`
  @media ${device.mobileL} {
    text-align: right;
  }

  @media ${device.tablet} {
    text-align: left;
  }
`;
export const CardUserInvoiceName = styled(Space)`
  span.ant-typography {
    display: block;
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${device.laptopL} {
    padding-right: 96px;
  }

  @media ${device.mobileL} {
    text-align: right;
  }

  @media ${device.tablet} {
    text-align: left;
  }
`;

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.colorAccent} !important;
  font-weight: 700 !important;
  font-size: 15.5px
  line-height: 0;
  margin-bottom:-2px;
  margin-top:0;
`;

export const CardText = styled(Text)`
  color: ${({ theme }) => theme.colors.colorBlack} !important;
  font-weight: 600 !important;
  font-size: 13px;
`;
export const CardTextStrong = styled(Text)`
  color: ${({ theme }) => theme.colors.colorBlack} !important;
  font-weight: 800 !important;
  font-size: 13px;
  margin-right: 10px;
`;

export const CustomDivider = styled(Box)`
  height: 64px;
  border-left: 3px solid ${({ theme }) => theme.colors.colorLightGray};
  display: none;

  @media ${device.mobileL} {
    display: block;
  }
`;

export const CardNotOfficialReport = styled(Card)`
  min-width: 300px;
  background: ${({ theme }) => theme.colors.colorAccent};
  border-radius: 20px;
`;
export const CardNotOfficialReportBody = styled(Flex)`
  flex-direction: column;
`;

export const NotOfficialReportHeading = styled.h3`
  font-size: 24px;
  color: #fff;
  margin: 0;
  line-height: 28px;
`;

export const NotOfficialReportText = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: #fff;
`;

export const CareerCardBody = styled(Flex)`
  gap: 32px;
  align-items: center;
`;

export const CarrerCardDesc = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CareerHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.colorAccent} !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  line-height: 22px !important;
`;

export const CareerText = styled(Text)`
  color: ${({ theme }) => theme.colors.colorAccent} !important;
  font-size: 16px !important;
  line-height: 0;
`;

export const CareerImage = styled(Box)`
  display: none;

  @media ${device.mobileL} {
    display: block;
  }
`;
