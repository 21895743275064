import styled from "styled-components";
import { Box, Card, Constants, Flex, Space } from "@nubeteck/components";
import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
//import { devices } from "src/constants";

const { maxDevice, device } = Constants;

export const CartResumeContainerStyled = styled(Box)`
  @media ${device.tablet && device.mobileL} {
    margin-top: 105px;
  }
  @media ${device.laptop} {
    margin-top: 30px;
  }
`;
export const TotalContainerStyled = styled(Space)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary100};
  top: -20px;
  right: -10px;
  padding: 10px 15px;
  border-radius: 15px;

  @media ${device.tablet} {
    border-radius: 20px;
    padding: 15px;
    margin-top: 10px;
  }
  @media ${maxDevice.tablet} {
    position: fixed;
    z-index: 12;
    left: 0;
    top: 80px;
    height: 100px;
  }
  @media ${maxDevice.mobileL} {
    border-radius: 0;
  }
  @media ${device.laptop} {
    margin: 0;
    padding: 10px 15px;
    border-radius: 15px;
  }
`;
export const TotalFlexStyled = styled(Flex)`
  gap: 10px;
`;

export const CartHeadingStyled = styled(LargeHeadingStyled)`
  @media ${maxDevice.tablet} {
    margin-top: 35px;
  }
`;

export const DownloadContainerStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const ImageButtonContainerStyled = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
  }
`;

export const FlexPDFButtonsStyled = styled(Flex)`
  gap: 15px;
  justify-content: right;
  margin: 20px 0px;
  button,
  a {
    margin: 0;
  }
`;
export const SpinnerContainerStyled = styled(Flex)`
  width: 100%;
  padding: 0px 80px;
  box-sizing: border-box;
  min-height: 200px;
  min-width: 380px;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
`;
export const FeesContainerStyled = styled(Card)`
  h5 {
    margin: 0;
  }
`;
