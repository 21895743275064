import styled from "styled-components";
import { Flex, Constants } from "@nubeteck/components";
import pattern from "src/assets/FramePattern.png";

const { device, maxDevice } = Constants;

export const FlexContainerStyled = styled(Flex)`
  align-items: center;
  height: 100vh;
  gap: 90px;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    flex-direction: column;
    justify-content: center;
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    justify-content: center;
  }
`;
export const ImageContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    display: none;
  }
`;
export const LoginButtonContainer = styled.div`
  justify-self: center;
`;

export const HeadingStyled = styled.h3`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 10px;
  @media ${device.tablet} {
    font-size:2.5rem;
`;
export const SubHeadingStyled = styled.h3`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-size: 1.7rem;
  font-weigth: 700;
`;
export const Frame = styled.div`
  background-image: url(${pattern});
  background-size: cover;
  padding: 100px;
  box-sizing: border-box;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  min-width: 50%;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    background-image: none;
    padding: 0px;
    max-width: 100%;
    min-width: 100%;
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    background-image: url(${pattern});
    background-size: cover;
    padding: 100px;
    max-width: 90%;
    min-width: 90%;
    max-height: 90%;
    min-height: 90%;
    .ant-image {
      width: 200px !important;
    }

    button {
      font-size: 20px;
      height: 45px;
    }
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 50px;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  min-height: 65vh;
  max-height: max-content;
  max-width: max-content;
  min-width: 30vw;
  box-sizing: border-box;

  @media ${device.mobileS} and ${maxDevice.tablet} {
    min-height: 100%;
    max-height: 100%;
    justify-content: center;
    padding: 0px 50px;
    max-width: 100%;
    min-width: 100%;
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    padding: 140px;
    justify-content: start;
  }
`;
