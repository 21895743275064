import { Button } from "@nubeteck/components";
import styled from "styled-components";

export const ButtonExport = styled(Button)`
  width: fit-content;
  padding: 24px !important;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
`;
