import { Flex, Constants } from "@nubeteck/components";
import styled from "styled-components";
//import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const FlexStyled = styled(Flex)`
  justify-content: space-between;
  gap: 5%;
  align-items: start;
  margin-top: 24px;

  // @media ${device.laptop} {
  //   min-width: 980px;
  // }

  & > .ant-image {
    padding: 50px 20px 10px 20px;
  }

  @media ${maxDevice.tablet} {
    flex-direction: column;
    gap: 50px;
    justify-content: left;
    .ant-image {
      display: none;
    }
  }
  @media ${device.mobileL && maxDevice.tablet} {
    margin-top: 50px;
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    align-items: center;
  }
`;
