import React from "react";
import { AccionCardStyled } from "../../../pages/dashboard/dashboard.styled";
import { SmallHeadingStyled } from "../texts/texts.styled";
import { Box, Button, Image } from "@nubeteck/components";
import { DocSVG } from "../../../assets";

const ActionCard = ({
  title,
  subtitle,
  actionName,
  action,
}: {
  title: string;
  subtitle: string;
  actionName?: string;
  action?: () => void;
}) => {
  return (
    <AccionCardStyled>
      <Box>
        <h3>{title}</h3>
        <SmallHeadingStyled>{subtitle}</SmallHeadingStyled>
        <Box>
          <br />
          <br />
          <br />
          {action && (
            <Button type="primary" size="large" onClick={action}>
              {actionName}
            </Button>
          )}
        </Box>
      </Box>
      <Image preview={false} src={DocSVG} width={180} />
    </AccionCardStyled>
  );
};

export default ActionCard;
