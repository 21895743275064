import React from "react";
import { MediumHeadingStyled } from "src/components/custom/texts/texts.styled";
import {
  InnerFlexStyled,
  SmallCardSharedStyled,
  SmallCardStyled,
  SmallCardTextContainertyled,
  SmallCardsContainerStyled,
} from "./card.styled";
import { Box } from "@nubeteck/components";

type Card = {
  title: string;
  information: string | JSX.Element;
  icon?: JSX.Element;
  onClick?: () => void;
};

interface ISmallCardGroup {
  cards: Card[];
}

const SmallCardsGroup = ({ cards }: ISmallCardGroup) => {
  return (
    <Box>
      <SmallCardsContainerStyled>
        <SmallCardSharedStyled>
          <InnerFlexStyled className="shared-card-child">
            <span className="icon" style={{ marginLeft: "0px" }}>
              {cards[0].icon}
            </span>
            <Box>
              <MediumHeadingStyled>{cards[0].title}</MediumHeadingStyled>
              <label>{cards[0].information}</label>
            </Box>
          </InnerFlexStyled>
          <InnerFlexStyled>
            <span className="icon">{cards[1].icon}</span>
            <SmallCardTextContainertyled>
              <MediumHeadingStyled>{cards[1].title}</MediumHeadingStyled>
              <label>{cards[1].information}</label>
            </SmallCardTextContainertyled>
          </InnerFlexStyled>
        </SmallCardSharedStyled>
        {cards?.map(
          (card, index) =>
            cards.indexOf(card) > 1 && (
              <SmallCardStyled
                key={`${card.title} ${index}`}
                style={{ textAlign: "end", cursor: card.onClick ? "pointer" : "default" }}
                onClick={card?.onClick}
              >
                <span className="icon">{card.icon}</span>
                <SmallCardTextContainertyled>
                  <MediumHeadingStyled>{card.title}</MediumHeadingStyled>
                  <label>{card.information}</label>
                </SmallCardTextContainertyled>
              </SmallCardStyled>
            ),
        )}
      </SmallCardsContainerStyled>
    </Box>
  );
};

export default SmallCardsGroup;
