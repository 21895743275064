import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Card, Form, Input, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";

function CareersImport({
  isLoading,
  getData,
  data,
}: {
  isLoading: boolean;
  getData: (params: { rowsCount: number; page: number; nombre: string }) => void;
  data: any;
}) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [careerName, setCareerName] = React.useState("");

  useEffect(() => {
    getData({ rowsCount, page: pageNumber, nombre: careerName });
  }, [careerName, rowsCount, pageNumber, getData]);

  const columns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Carrera no.",
        dataIndex: "carreraId",
      },
      {
        title: "Carrera",
        dataIndex: "carreraNombre",
      },
    ],
    [],
  );
  return (
    <>
      <MediumLargeHeadingStyled>Carreras importadas</MediumLargeHeadingStyled>

      <Form.Item label="Búsqueda por nombre de carrera">
        <Input
          type="text"
          onChange={(e) => setCareerName(e.target.value)}
          placeholder="Búsqueda por nombre de carrera"
        />
      </Form.Item>

      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<any>
          loading={isLoading}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.carreraId}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            current: pageNumber,
            total: data?.data?.pageCount * rowsCount,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
}

export default CareersImport;
