import { horas } from "src/constants";

export const getRandomColor = (): string => {
  const randomColor = `rgb(
      ${Math.floor(Math.random() * 256)},
      ${Math.floor(Math.random() * 256)},
      ${Math.floor(Math.random() * 256)}`;
  return randomColor;
};

export const convertHourToNumber = (hour: string) => {
  const twelveHourSystem = hour.split(" ")[1];
  const minutes = hour.split(" ")[0].split(":")[1];
  let hourSearched: string | undefined;

  if (!twelveHourSystem) {
    hourSearched = horas.find((hr) => hr.twentyFour.split(":")[0] === hour.split(":")[0])
      ?.twentyFour;
  } else {
    hourSearched = horas.find(
      (hr) =>
        hr.twelve.split(":")[0] === hour.split(":")[0] &&
        hr.twelve.split(" ")[1] === hour.split(" ")[1],
    )?.twentyFour;
  }

  if (hourSearched) {
    const hourSplitted = hourSearched.split(":");
    const hourResult = `${hourSplitted[0]}.${minutes}`;
    return +hourResult;
  }
  return 0;
};
