import { api } from "./apiBaseQuery";

export const sponsorsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getSponsors: builder.mutation({
      query: () => {
        return {
          url: `/Patrocinios/Obtener/Patrocinadores`,
          method: "GET",
        };
      },
    }),
    updateSponsor: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Patrocinios/Patrocinadores/${id}`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
    deleteSponsor: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Patrocinios/Patrocinadores/Estado/${id}`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
    createSponsor: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Patrocinadores`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
  }),
});

export const {
  useGetSponsorsMutation,
  useUpdateSponsorMutation,
  useCreateSponsorMutation,
  useDeleteSponsorMutation,
} = sponsorsServices;
