import { Card, Constants, Flex } from "@nubeteck/components";
import styled from "styled-components";
//import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const CardStyled = styled(Card)`
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.05));
  border-radius: 20px;
`;

export const WarningCardStyled = styled(CardStyled)`
  flex: 1;
  border-color: #d79920;

  position: relative;

  .ant-card-body {
    display: flex;
    min-height: inherit;
  }

  p {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.colorText};
  }

  span {
    color: ${({ theme }) => theme.colors.colorAccent};
    font-size: 14px;
    font-weight: 700;
  }

  .ant-image {
    min-height: 80px;
    img {
      position: absolute;
      bottom: 15px;
    }
  }
`;
export const SmallCardStyled = styled(CardStyled)`
  min-width: 20%;
  overflow: hidden;

  .ant-card-body {
    max-height: 65px;
    padding: 5px 15px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin: 8px;
      left: 10px;
      bottom: 15px;
    }

    h2 {
      margin: 0px;
    }
  }
  @media ${device.mobileS} and ${maxDevice.laptop} {
    background: none;
    filter: none;
    border: none;
    width: 100%;
    justify-content: left;
    text-align: start !important;
    padding: 0px 25px 5px 25px;

    .ant-card-body span.icon {
      display: none;
    }
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    width: 32%;
  }
`;
export const SmallCardSharedStyled = styled(SmallCardStyled)`
  width: 100%;
  .ant-card-body {
    justify-content: start;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    .ant-card-body {
      flex-wrap: wrap;
      height: fit-content;
      max-height: fit-content;
    }
  }
`;
export const SmallCardTextContainertyled = styled.div`
  width: 100%;
`;

export const InnerFlexStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
  white-space: nowrap;

  &.shared-card-child {
    padding-right: 10px;
    margin-right: -12px;
    border-right: 3px solid #ebebeb;

    label {
      text-overflow: ellipsis;
      display: block;
      width: 140px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  div label > .ant-progress-line {
    margin-bottom: 0px;
  }

  @media ${device.mobileS} and ${maxDevice.tablet} {
    margin: 10px 0px;
    &.shared-card-child {
      border-right: none;
    }
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    &.shared-card-child {
      margin-right: 12px;
      label {
        width: 300px;
      }
    }
  }
`;
export const SmallCardsContainerStyled = styled(Flex)`
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;

  @media ${device.mobileS} and ${maxDevice.laptop} {
    flex-wrap: wrap;
    background: white;
    gap: 0;
    padding: 10px 0px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    flex-wrap: wrap;
    margin: 40px 0px;
  }
`;

export const CardsGroupContainer = styled(Flex)`
  flex-direction: column;
  position: relative;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${maxDevice.laptop} {
    margin-top: 0px;
  }
`;
