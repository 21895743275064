export const themeColors = {
  primary100: "#C9E1FF",
  primary200: "#AED3FF",
  primary300: "#95C3FA",
  primary400: "#95C3FA",
  primary600: "#2E83EA",
  primary500: "#006AEA",
  primary700: "#0A5FC6",
  primary800: "#00479B",
  primary900: "#00479B",
  colorBgContainer: "#F4F6FA",
  colorTextHeading: "#263958",
  colorTextDescription: "#d1cece",
  colorText: "#263958",
  colorBgBase: "#F4F6FA",
  colorMenuIcons: "#a7a7a7",
  colorTableHeader: "#E1EFFF",

  // Custom colors

  colorBlack: "#373B4E",
  colorGray: "#A7A7A7",
  colorAccent: "#00479B",
  colorLightGray: "#EBEBEB",
};

export const vars = {
  cornerRadius: 20,
};

export type ThemeColors = keyof typeof themeColors;
