import React, { useState } from "react";
import { ReportsContainer, ReportsHeading } from "./reports.styled";
import { Button, List, Spin, TabsProps } from "antd";
import { ReportContainer } from "../../../components";
import { useGetReportsQuery } from "src/services";
import { SmallHeadingStyled } from "src/components/custom/texts/texts.styled";
import { IReport } from "@/Interfaces/reports";
import { FlexStyled } from "src/components/registration/register-confirmation/confirmation.styled";
import { useMediaQuery } from "src/hooks";

const Reports = () => {
  const { data: reports, isLoading: isLoadingReports } = useGetReportsQuery("", {
    refetchOnMountOrArgChange: true,
  });
  const [activeReport, setActiveReport] = useState<IReport>();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const items: TabsProps["items"] = reports?.data?.map((report: IReport) => ({
    key: `${report.reporteId}`,
    label: report.reporteNombre,
  }));

  return (
    <ReportsContainer>
      <Spin spinning={isLoadingReports} size="large">
        <div>
          <ReportsHeading level={2}>Reportes</ReportsHeading>
          <SmallHeadingStyled style={{ fontSize: 16 }}>
            {items?.find((item) => item.key == `${activeReport?.reporteId}`)?.label}
          </SmallHeadingStyled>
        </div>
        <FlexStyled style={{ justifyContent: "space-between" }}>
          {items && items?.length > 0 && (
            <div>
              <List
                size="small"
                header={null}
                footer={null}
                style={{ background: "white" }}
                bordered
                dataSource={items}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="text"
                      onClick={() =>
                        setActiveReport(
                          reports?.data?.find((re: IReport) => re.reporteId == +item.key),
                        )
                      }
                    >
                      {item.label}
                    </Button>
                  </List.Item>
                )}
              />
              {/* <Form.Item label="Reporte" name="reporte">
                <Select
                  onChange={(activeKey) =>
                    setActiveReport(
                      reports?.data?.find((re: IReport) => re.reporteId == +activeKey),
                    )
                  }
                  options={items?.map((it) => ({ label: it.label, value: it.key }))}
                  placeholder="Seleccione un reporte a visualizar"
                  value={activeReport?.reporteNombre}
                  defaultValue={activeReport?.reporteNombre}
                  style={{ minWidth: "320px" }}
                />
              </Form.Item> */}
            </div>
          )}
          <div style={{ maxWidth: isMobile ? "auto" : "67%" }}>
            <ReportContainer reporte={activeReport} />,
          </div>
        </FlexStyled>
      </Spin>
    </ReportsContainer>
  );
};

export default Reports;
