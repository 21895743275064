import React, { useState } from "react";
import {
  // RegisterConfirmation,
  RegisterIntroduction,
  RegisterSelection,
  RegisterSteps,
  RegisterForm,
  UserProgramCards,
} from "../../../components";
import { BlockingContainer, RegistrationContainer } from "./registration.styled";
import { useAppDispatch } from "src/core";
import { resetSelection, sumSubjectPrice } from "src/core/store/slices/subjectSelectionSlice";
import SubjectImg from "src/assets/SubjectImg.svg";
import {
  useGetCareersQuery,
  useGetSelectedSubjectsMutation,
  useGetSelectionDataByCareerPeriodMutation,
  useGetSubjectsByCareerPeriodMutation,
  useGetSufficiencySubjectsMutation,
  useRegisterSufficiencySubjectMutation,
} from "src/services";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store/store";
import { Button, Card, Image, List, Modal } from "antd";
import {
  LargeHeadingStyled,
  SmallHeadingStyled,
  XsHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { IntroductionContainer } from "src/components/registration/register-introduction/register-introduction.styled";
import { Box, Drawer, Paragraph } from "@nubeteck/components";
import { RegisterSVG } from "src/assets";
import { ISelectedSubject, ISubject } from "@/Interfaces/subject";
import useManageTimer from "src/hooks/useManageTimer";
import { FormatterUtils } from "@nubeteck/utils";

interface IRegisterStepProps {
  id: number;
  title: string;
  component: JSX.Element;
}

interface IBloqueo {
  bloqueo: string;
  bloqueoDescripcionLarga: string;
  departamentoCorrespondiente: string;
  matricula: string;
  razon: string;
}

const Registration = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useSelector((state: RootState) => state.user);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [careerForSelectionId, setCareerForSelectionId] = useState<number>(0);
  const [PeriodForSelectionId, setPeriodForSelectionId] = useState<number>(0);
  const [subjectsDrawerOpen, setSubjectsDrawerOpen] = useState(false);
  const [sufficiencyDrawerVisible, setSufficiencyDrawerVisible] = useState(false);

  const [getSufficiencySubjects, { data: sufficiencySubjects, isLoading: isLoadingSufficiencies }] =
    useGetSufficiencySubjectsMutation();
  const [
    registerSufficiency,
    {
      isLoading: isLoadingRegisterSufficiency,
      isSuccess: isSuccessRegisterSufficiency,
      reset: resetRegisterSufficiency,
    },
  ] = useRegisterSufficiencySubjectMutation();

  const { getTimer, selectionMinutes } = useManageTimer(
    currentStep === 0,
    true,
    isSuccessRegisterSufficiency,
  );
  const matriculaAdministrativa = localStorage.getItem("matriculaAdministrativa") === "true";
  const { data: careers, isLoading: isLoadingCareers } = useGetCareersQuery(
    matriculaAdministrativa,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [getSelectedSections, { data: selectedData, isLoading: isLoadingSelected }] =
    useGetSelectedSubjectsMutation();
  const [getSubjects, { data: subjects, isLoading: isLoadingSubjects }] =
    useGetSubjectsByCareerPeriodMutation();
  const [getSelectionData, { data: selectionData, isLoading: isLoadingSelectionData }] =
    useGetSelectionDataByCareerPeriodMutation();

  React.useEffect(() => {
    if (subjects?.data?.totalFacturaMaterias > 0) {
      dispatch(
        sumSubjectPrice({
          cost: subjects?.data?.totalFacturaMaterias,
          quantity: subjects?.data?.materiasSeleccionadas,
        }),
      );
    }
    return () => {
      dispatch(resetSelection());
    };
  }, [dispatch, subjects]);

  const obtenerProyeccionAsignaturas = () => {
    getSubjects({
      careerId: careerForSelectionId,
      periodId: PeriodForSelectionId,
      esPrematricula: matriculaAdministrativa,
    });
  };

  React.useEffect(() => {
    if (careerForSelectionId !== 0) {
      obtenerProyeccionAsignaturas();
      getSelectedSections(careerForSelectionId);

      getSelectionData({
        careerId: careerForSelectionId,
        periodId: PeriodForSelectionId,
        esPrematricula: matriculaAdministrativa,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerForSelectionId]);

  React.useEffect(() => {
    if (isSuccessRegisterSufficiency) {
      getSufficiencySubjects({ careerId: careerForSelectionId, periodId: PeriodForSelectionId });
      obtenerProyeccionAsignaturas();
      resetRegisterSufficiency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessRegisterSufficiency]);

  const stepIncrease = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };
  const stepDecrease = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  const steps: IRegisterStepProps[] = [
    {
      id: 0,
      title: "Inicio",
      component: (
        <RegisterIntroduction
          careers={careers?.data}
          setCareer={setCareerForSelectionId}
          setPeriod={setPeriodForSelectionId}
          isLoading={isLoadingCareers}
          goNext={stepIncrease}
          timeValue={selectionMinutes}
        />
      ),
    },
    {
      id: 1,
      title: "Selección de materias",
      component: (
        <RegisterSelection
          periodId={PeriodForSelectionId}
          subjectsData={subjects?.data?.seleccionAsignaturas}
          registeredSubjects={subjects?.data?.inscritasOPorValidarAsignaturas ?? []}
          isLoading={
            isLoadingSubjects ||
            isLoadingSelectionData ||
            isLoadingSelected ||
            isLoadingRegisterSufficiency
          }
          selectedData={selectedData?.data}
          setSufficiencyDrawerVisible={() => {
            getSufficiencySubjects({
              careerId: careerForSelectionId,
              periodId: PeriodForSelectionId,
            });
            setSufficiencyDrawerVisible(true);
          }}
          updateSubjects={() => {
            obtenerProyeccionAsignaturas();
            getSelectionData({
              careerId: careerForSelectionId,
              periodId: PeriodForSelectionId,
              esPrematricula: matriculaAdministrativa,
            });
            getSelectedSections(careerForSelectionId);
          }}
        />
      ),
    },
    {
      id: 2,
      title: "Carrito",
      component: <></>,
    },
    {
      id: 3,
      title: "Caja",
      component: <></>,
    },
  ];

  const decideStepRendering = () => {
    return steps?.find((step) => step.id === currentStep)?.component;
  };

  return (
    <RegistrationContainer>
      <RegisterSteps
        steps={steps.map((step) => ({ title: step.title }))}
        currentStep={currentStep}
        getExternalTimer={getTimer}
      />
      {currentStep > 0 && (
        <UserProgramCards
          isLoading={isLoadingSelectionData}
          userInfo={{
            ...selectionData?.data,
            monedaSimbolo: subjects?.data?.seleccionAsignaturas[0]?.monedaId === 2 ? "₡" : "$",
          }}
          setSelectedSubjectsVisible={() => setSubjectsDrawerOpen(true)}
        />
      )}
      {!userInfo.hayProcesoAbierto ? (
        <Card>
          <IntroductionContainer>
            <Box>
              <LargeHeadingStyled>No hay ningún proceso de matrícula abierto</LargeHeadingStyled>
              <p>
                Espera a que haya algún periodo disponible y vuelve para seleccionar tus
                asignaturas.
              </p>
            </Box>
            <Image src={RegisterSVG} width={285} alt="Ilustración registro" preview={false} />
          </IntroductionContainer>
        </Card>
      ) : userInfo.bloqueos?.length > 0 ? (
        <Card>
          <IntroductionContainer>
            <Box>
              <LargeHeadingStyled>
                Su acceso al proceso de matrícula ha sido bloqueado.
              </LargeHeadingStyled>
              {userInfo.bloqueos?.map((bloq: IBloqueo) => (
                <Box key={`${bloq.bloqueo} ${bloq.razon} ${bloq.departamentoCorrespondiente}`}>
                  <BlockingContainer
                    key={`${bloq.bloqueo} ${bloq.razon} ${bloq.departamentoCorrespondiente}`}
                  >
                    <SmallHeadingStyled>Bloqueo: {bloq.razon}</SmallHeadingStyled>
                    <p>Descripción: {bloq.bloqueoDescripcionLarga}.</p>
                    <p>Comuníquese con el departamento de {bloq.departamentoCorrespondiente}.</p>
                  </BlockingContainer>
                </Box>
              ))}
            </Box>
            <Image src={RegisterSVG} width={285} alt="Ilustración registro" preview={false} />
          </IntroductionContainer>
        </Card>
      ) : (
        <RegisterForm
          careers={careers?.data}
          callbackIfPreviousRoute={(program: number, period: number) => {
            setCareerForSelectionId(program);
            setPeriodForSelectionId(period);
            setCurrentStep(1);
          }}
          formCurrentItem={decideStepRendering()}
          readyForSubmit={currentStep === 1}
          goBack={stepDecrease}
          goToNext={stepIncrease}
          stepIndex={currentStep}
        />
      )}

      <Drawer
        placement="right"
        title="Asignaturas seleccionadas"
        open={subjectsDrawerOpen}
        onClose={() => setSubjectsDrawerOpen(false)}
      >
        <List
          loading={isLoadingSelected}
          dataSource={selectedData?.data}
          renderItem={(item: ISelectedSubject & { seccionPrecio: number }) => (
            <List.Item key={item.asignaturaId}>
              <List.Item.Meta
                avatar={<Image preview={false} src={SubjectImg} width={35} />}
                title={
                  <SmallHeadingStyled style={{ margin: "0px 0px 10px 0px" }}>
                    {item.asignaturaNombre}
                  </SmallHeadingStyled>
                }
                description={
                  <>
                    <XsHeadingStyled>Créditos: {item.creditos}</XsHeadingStyled>
                    <XsHeadingStyled>
                      Costo: {item.monedaId === 1 ? "$" : "₡"}{" "}
                      {FormatterUtils.number(`${item.seccionPrecio}`)}
                    </XsHeadingStyled>
                    {item.horarios?.map((hor) => (
                      <div key={`${hor.diaId}${hor.horaFin}${hor.horaInicio}`}>
                        <Paragraph
                          type="secondary"
                          style={{ marginBottom: "3px", color: "#000000d6" }}
                        >
                          {hor.diaNombre}, {hor.horaInicio} - {hor.horaFin}
                        </Paragraph>
                      </div>
                    ))}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>

      <Modal
        title="Agregar asignatura por suficiencia"
        open={sufficiencyDrawerVisible}
        onCancel={() => setSufficiencyDrawerVisible(false)}
        footer={
          <Button type="primary" onClick={() => setSufficiencyDrawerVisible(false)}>
            Cerrar
          </Button>
        }
      >
        <List
          loading={isLoadingSufficiencies || isLoadingRegisterSufficiency}
          dataSource={sufficiencySubjects?.data}
          renderItem={(item: ISubject) => (
            <List.Item key={item.asignaturaId}>
              <List.Item.Meta
                avatar={<Image preview={false} src={SubjectImg} width={35} />}
                title={
                  <SmallHeadingStyled style={{ margin: "0px 0px 10px 0px" }}>
                    {item.asignaturaNombre}
                  </SmallHeadingStyled>
                }
                description={
                  <>
                    <XsHeadingStyled>Créditos: {item.creditos}</XsHeadingStyled>
                    <Button
                      onClick={() =>
                        registerSufficiency({
                          asignaturaId: item.asignaturaId,
                          periodoId: PeriodForSelectionId,
                          carreraId: careerForSelectionId,
                        })
                      }
                    >
                      Agregar
                    </Button>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </RegistrationContainer>
  );
};

export default Registration;
