import React from "react";
import { ProgressContainerStyled } from "./steps.styled";
import { Modal, Statistic } from "antd";
import { useNavigate } from "react-router-dom";
import { valueType } from "antd/es/statistic/utils";

interface ITimer {
  minutes: number;
  verifyEnding: (seconds: valueType | undefined) => void;
  hoursFormat: boolean;
  style?: React.CSSProperties;
}

const Timer = ({ minutes, verifyEnding, hoursFormat, style }: ITimer) => {
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();

  const throwTimeAlert = () => {
    modal.info({
      title: `Estimado(a) estudiante:`,
      content: `Su tiempo se agotó sin completar el proceso de matrícula, por lo tanto, todas las operaciones realizadas serán reversadas y usted no quedará inscrito en ninguno de los cursos que haya seleccionado. Se le sugiere iniciar nuevamente.`,
      okText: "Aceptar",
      onOk: () => navigate("/"),
      style: { top: 200 },
    });
  };

  return (
    <ProgressContainerStyled style={style}>
      <Statistic.Countdown
        value={minutes}
        onFinish={() => {
          throwTimeAlert();
        }}
        onChange={(val) => verifyEnding(val)}
        valueStyle={{
          fontWeight: "bold",
          color: "#00479B",
          fontSize: hoursFormat ? "14px" : "16px",
          border: "4px solid white",
          borderRadius: "100%",
          width: "70px",
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        format={hoursFormat ? "HH:mm:ss" : "mm:ss"}
      />

      {contextHolder}
    </ProgressContainerStyled>
  );
};

export default Timer;
