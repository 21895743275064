import React, { MutableRefObject, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Flex } from "@nubeteck/components";
import { Header, MobileMenu } from "../../layout";
import { MainContainer } from "./main-layout.styled";
import { useGetUserInfoMutation } from "src/services";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/core/store/store";
import { setUserInfo } from "src/core/store/slices/userSlice";
import {
  decodeJwt,
  getIsUserImpersonated,
  getRolesByToken,
  getRolesByTokenImpersonated,
} from "src/utils";
import { Alert, Button } from "antd";
import { useMediaQuery } from "src/hooks";
import { IMenuItem } from "src/Interfaces/options";

const MainLayout = () => {
  const [isMenuMobileVisible, setMenuMobileVisible] = React.useState(false);
  const [getUserInfo, { data: userInfo }] = useGetUserInfoMutation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 1180px)");

  const matriculaAdministrativa = localStorage.getItem("matriculaAdministrativa") === "true";
  const token = localStorage.getItem("token");
  const roles = matriculaAdministrativa
    ? getRolesByTokenImpersonated(token ?? "")
    : getRolesByToken(token ?? "");
  const isAdmin =
    roles.includes("administrador(a) del sistema") ||
    roles.includes("cajero") ||
    roles.includes("vicerrectoria") ||
    roles.includes("matricula administrativa");
  matriculaAdministrativa;

  const isATeacher = roles.includes("profesor");

  const userImpersonated: MutableRefObject<any> = useRef(null);

  const menuItems: IMenuItem[] = [
    { name: "Inicio", link: "/", icon: "" },
    { name: "Materias", link: "/subjects-list", icon: "" },
    {
      name: "Matrícula",
      link: "/subjects-registration/initial",
      icon: "",
      exactLink: "subjects-registration",
    },
    { name: "Caja", link: "/cart/general", icon: "", exactLink: "cart" },
    { name: "Calendario", link: "/calendar", icon: "" },
    { name: "Solicitudes", link: "/requests", icon: "" },
    { name: "Reportes", link: "/reports", icon: "" },
  ];

  const adminMenuItems: IMenuItem[] = [
    {
      name: "Inicio",
      link: "/",
      icon: "",
      roles: [
        "cajero",
        "administrador(a) del sistema",
        "perfil financiero",
        "vicerrectoria",
        "matricula administrativa",
      ],
      tooltip: "Inicio",
    },
    {
      name: "Patrocinios",
      link: "/admin/sponsorships",
      icon: "",
      roles: ["perfil financiero"],
      tooltip: "Patrocinios",
    },
    {
      name: "Transferencias",
      link: "/admin/reviews",
      icon: "",
      roles: ["perfil financiero"],
      tooltip: "Transferencias",
    },
    {
      name: "Importación",
      link: "/admin/data-imports",
      icon: "",
      roles: ["vicerrectoria"],
      tooltip: "Importaciones de datos",
    },
    {
      name: "Caja",
      link: "/admin/charges",
      icon: "",
      roles: ["cajero"],
      tooltip: "Caja administrativa",
    },
    {
      name: "Configuración",
      link: "/admin/settings",
      icon: "",
      roles: ["administrador(a) del sistema"],
      tooltip: "Configuraciones",
    },
    {
      name: "Ingresar como",
      link: "admin/user-impersonation",
      icon: "",
      roles: ["administrador(a) del sistema"],
      tooltip: "Ingresar como usuario estudiante",
    },
    {
      name: "Pre-matrícula",
      link: "admin/user-selection",
      icon: "",
      roles: ["matricula administrativa"],
      tooltip: "Pre-matrícula",
    },
    {
      name: "Retiro admin",
      link: "/admin/subjects-withdraw",
      icon: "",
      roles: ["retiro administrativo"],
      tooltip: "Retiro administrativo",
    },
  ];
  const teacherMenuItems: IMenuItem[] = [
    {
      name: "Inicio",
      link: "/",
      icon: "",
    },
    { name: "Cursos", link: "teachers/courses", icon: "" },
    { name: "Solicitudes", link: "/requests", icon: "" },
  ];
  useEffect(() => {
    getUserInfo("");
  }, [getUserInfo]);

  useEffect(() => {
    if (userInfo?.data) {
      dispatch(setUserInfo(userInfo?.data));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    const tokenT = localStorage.getItem("token");
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
    userImpersonated.current = getIsUserImpersonated(tokenT ?? "");
  }, [navigate]);

  const getUserName = () => {
    return matriculaAdministrativa && userImpersonated.current
      ? JSON.parse(decodeJwt(userImpersonated.current)).name
      : userInfo?.data?.fullName;
  };

  const getMenuByAdminRoles = (menuItems: IMenuItem[]) => {
    const result = menuItems.filter((menu) => menu.roles?.find((rol) => roles.includes(rol)));
    return result;
  };

  const logoutImpersonated = () => {
    localStorage.setItem("token", userImpersonated.current);
    localStorage.setItem("matriculaAdministrativa", "false");
    localStorage.setItem("refreshToken", JSON.stringify(userImpersonated.current.refreshToken));
    getUserInfo("");
    navigate("/");
    window.location.reload();
  };

  const logout = () => {
    localStorage.setItem("matriculaAdministrativa", "false");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  return (
    <Box>
      <MobileMenu
        isFakeSelecting={matriculaAdministrativa}
        logoutImpersonated={logoutImpersonated}
        menuItems={
          isAdmin ? getMenuByAdminRoles(adminMenuItems) : isATeacher ? teacherMenuItems : menuItems
        }
        userName={getUserName()}
        logout={logout}
        isHelpVisible={!isAdmin}
        isVisible={isMenuMobileVisible}
        hide={() => {
          setMenuMobileVisible(false);
        }}
      />
      <Header
        isFakeSelecting={matriculaAdministrativa}
        logoutImpersonated={logoutImpersonated}
        isAdmin={isAdmin !== undefined}
        menuItems={
          isAdmin ? getMenuByAdminRoles(adminMenuItems) : isATeacher ? teacherMenuItems : menuItems
        }
        userName={getUserName()}
        isHelpVisible={!isAdmin}
        logout={logout}
        openMenu={() => {
          setMenuMobileVisible(true);
        }}
      />
      <MainContainer
        style={{ paddingTop: isMobile && userImpersonated.current ? "250px" : "auto" }}
      >
        {userImpersonated.current ? (
          <>
            <br />
            <Flex
              style={{
                display: "flex",
                alignItems: isMobile ? "start" : "center",
                gap: isMobile ? "7px" : "15px",
                flexDirection: isMobile ? "column" : "row",
                backgroundColor: "#c9e1ff",
                position: "absolute",
                top: isMobile ? "80px" : "63px",
                left: 0,
                width: "100%",
                justifyContent: "center",
                padding: isMobile ? "10px" : "0px",
              }}
            >
              <Alert
                message={
                  matriculaAdministrativa
                    ? `Matrícula del estudiante ${userInfo?.data.fullName} - ${userInfo?.data.id}`
                    : `El usuario del estudiante ${userInfo?.data.fullName} - ${userInfo?.data
                        .id} está siendo utilizado por ${JSON.parse(
                        decodeJwt(userImpersonated.current),
                      )?.name}`
                }
                type="info"
                style={{ backgroundColor: "transparent", border: "none" }}
                showIcon
              />
              <Button onClick={logoutImpersonated}>
                {matriculaAdministrativa ? "Salir" : "Volver a tu usuario"}
              </Button>
            </Flex>
          </>
        ) : // </Tooltip>
        null}
        <Outlet />
      </MainContainer>
    </Box>
  );
};

export default MainLayout;
