import React from "react";
import {
  CurrentSubjectsContainer,
  DashboardContainer,
  DashboardRow,
  ScrollViewStyled,
  SubjectInfoContainerStyled,
  SubjectResumeStyled,
  SubjectTitleStyled,
  UserCardInfo,
  UserCardInfoContainer,
  UserCardStyled,
  UserInfoContainerStyled,
  UserInfoLabel,
  UserInfoText,
  UserInfoTextBlue,
} from "./dashboard.styled";
import { Space, Image, Box, EmptyState } from "@nubeteck/components";
import { DocSVG, TextIconSVG, UserSVG } from "../../assets";
import { ActionCard, WarningCard, WelcomeCard } from "../../components/custom/cards";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store/store";
import { useGetCurrentSubjectsQuery } from "src/services";
import { ISubject } from "@/Interfaces/subject";
import { Skeleton } from "antd";
import { CardStyled } from "src/components/custom/cards/card.styled";
import { getRolesByToken } from "src/utils";
import { useNavigate } from "react-router";

interface IUserCardInfoItemProps {
  label: string;
  content: string;
}

const UserCardInfoItem = ({ label, content }: IUserCardInfoItemProps) => (
  <Space direction="vertical" style={{ minWidth: 125 }}>
    <UserInfoLabel type="secondary">{label}</UserInfoLabel>
    <UserInfoText>{content}</UserInfoText>
  </Space>
);

const UserCard = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);

  return (
    <UserCardStyled
      style={{ flex: 1 }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <UserCardInfoContainer>
        <UserCardInfo>
          <UserCardInfoItem label="ID" content={userInfo.id} />
          <UserCardInfoItem label="Correo electrónico" content={userInfo.correoElectronico} />
          <UserCardInfoItem label="Teléfono" content={userInfo.telefono} />
        </UserCardInfo>
        <UserInfoContainerStyled>
          <Image preview={false} src={UserSVG} width={65} />
          <UserInfoTextBlue>{`${userInfo.nombres} ${userInfo.apellidos}`}</UserInfoTextBlue>
          <Image preview={false} src={TextIconSVG} width={65} />
        </UserInfoContainerStyled>
      </UserCardInfoContainer>
    </UserCardStyled>
  );
};

const CurrentSubjects = ({ isATeacher }: { isATeacher: boolean }) => {
  const { data: materiasActuales, isLoading } = useGetCurrentSubjectsQuery("");
  return (
    <CurrentSubjectsContainer>
      <SubjectTitleStyled>
        {isATeacher ? "Clases asignadas" : "Materias en curso"}
      </SubjectTitleStyled>
      <ScrollViewStyled>
        <Skeleton loading={isLoading} active round />
        <Skeleton loading={isLoading} active round />
        {!isLoading && materiasActuales?.data?.length > 0 ? (
          materiasActuales?.data?.map((materia: ISubject) => (
            <SubjectResumeStyled key={materia.asignaturaId}>
              <SubjectInfoContainerStyled>
                <Box>
                  <h4>{materia.asignaturaNombre}</h4>
                  <Box>
                    <span>{materia.asignaturaCodigo}</span>
                  </Box>
                </Box>
                <Image preview={false} src={DocSVG} alt="Ilustración documento" />
              </SubjectInfoContainerStyled>
            </SubjectResumeStyled>
          ))
        ) : (
          <CardStyled>
            <EmptyState
              emptyText={
                isATeacher
                  ? "No tiene asignado ningún curso en este periodo."
                  : "No está cursando ninguna materia en este periodo"
              }
            />
          </CardStyled>
        )}
      </ScrollViewStyled>
    </CurrentSubjectsContainer>
  );
};

const Dashboard = () => {
  const roles = getRolesByToken(localStorage.getItem("token") ?? "");
  const esAdmin =
    roles.includes("administrador(a) del sistema") ||
    roles.includes("cajero") ||
    roles.includes("vicerrectoria") ||
    roles.includes("matricula administrativa");
  const isATeacher = roles.includes("profesor");
  const { userInfo } = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();

  return (
    <DashboardContainer>
      <DashboardRow>
        <WelcomeCard
          welcomeText={
            isATeacher
              ? "Gestione la información correspondiente a sus cursos de forma ágil y sencilla."
              : esAdmin
              ? "Gestione la información correspondiente al proceso de matrícula de forma ágil y sencilla"
              : "Consulta tu calendario, tus reportes, y matricúlate de forma ágil y sencilla."
          }
        />
        <UserCard />
      </DashboardRow>
      <DashboardRow>
        {!esAdmin || isATeacher ? (
          <CurrentSubjects isATeacher={isATeacher} />
        ) : (
          <ActionCard
            title="Configure y gestione los datos del sistema en sencillos pasos."
            subtitle={
              roles.includes("administrador(a) del sistema")
                ? "Configure los formularios de las solicitudes de servicios."
                : roles.includes("cajero")
                ? "Realice la validación de pagos en caja."
                : roles.includes("matricula administrativa")
                ? "Realice la matriculación para un estudiante."
                : roles.includes("vicerrectoria")
                ? "Realice las importaciones de datos."
                : "Asigne los patrocinios correspondientes a los estudiantes."
            }
            actionName={
              roles.includes("administrador(a) del sistema")
                ? "Ir a la configuración de solicitudes"
                : roles.includes("cajero")
                ? "Ir a caja"
                : roles.includes("matricula administrativa")
                ? "Ir a pre-matricula."
                : roles.includes("vicerrectoria")
                ? "Ir a importaciones"
                : "Ir a patrocinios"
            }
            action={
              roles.includes("administrador(a) del sistema")
                ? () => navigate("/admin/requests")
                : roles.includes("cajero")
                ? () => navigate("/admin/charges")
                : roles.includes("matricula administrativa")
                ? () => navigate("/admin/user-selection")
                : roles.includes("vicerrectoria")
                ? () => navigate("/admin/data-imports")
                : () => navigate("/admin/sponsorships")
            }
          />
        )}
        {isATeacher ? (
          <ActionCard
            title="Administre sus clases en sencillos pasos"
            subtitle="Gestione la lista de clase, calificaciones y actividades."
            actionName="Ir a mis cursos"
            action={() => navigate("teachers/courses")}
          />
        ) : esAdmin ? (
          <></>
        ) : userInfo?.estaSuspendido ? (
          <WarningCard
            size="large"
            title="En este momento estás suspendido y no podrás utilizar esta herramienta."
            message="Comunícate con tu escuela para más información"
          />
        ) : userInfo?.hayProcesoAbierto ? (
          <ActionCard
            title="¡Empieza el proceso de matrícula!"
            subtitle="Selecciona tus materias y realiza el pago en sencillos pasos."
            actionName="Empezar"
            action={() => navigate("/subjects-registration/initial")}
          />
        ) : (
          <ActionCard
            title="Proceso de matricula cerrado"
            subtitle="Espera a que se abra un periodo de matricula y selecciona tus asignaturas correspondientes."
          />
        )}
      </DashboardRow>
    </DashboardContainer>
  );
};

export default Dashboard;
