import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Card, Form, Input, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { Button, Flex, Icon } from "@nubeteck/components";
import { ITransferencia } from "src/Interfaces/transfer";
import { useGetTransfersStatusQuery } from "src/services/reviewsServices";
import { ArrayUtils, FormatterUtils } from "@nubeteck/utils";

function Transfers({
  isLoading,
  getData,
  data,
  getFile,
  isLoadingFile,
  changeState,
  pageNumber,
  setPageNumber,
  rowsCount,
  setRowsCount,
}: any) {
  const [studentName, setStudentName] = React.useState("");
  const [studentId, setStudentId] = React.useState("");
  const [estadoId, setEstadoId] = React.useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const { data: status } = useGetTransfersStatusQuery();

  const filterStatusOptions = ArrayUtils.selectLabelValue(
    status?.data ?? [],
    "estadoNombre",
    "estadoId",
  );

  const confirm = (record: ITransferencia) => {
    modal.confirm({
      title: `¿Desea ${record.estadoId === 7 ? "aceptar" : "denegar"} este aval de transferencia?`,
      icon: <Icon name="" color="#FFC53D" />,
      content: `La trasferencia notificada por ${record.nombreCompleto} el ${new Date(
        record.fecha,
      ).toLocaleDateString()} será ${record.estadoId === 7 ? "aceptada" : "denegada"}.`,
      okText: record.estadoId === 7 ? "Aceptar transferencia" : "Denegar transferencia",
      onOk: () => {
        changeState({
          estadoId: record.estadoId,
          pagoFormaPagoId: record.pagoFormaPagoId,
          fileId: record.fileId,
        });
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  // const { data: status } = useGetTransfersStatusQuery();

  useEffect(() => {
    getData({
      rowsCount,
      page: pageNumber,
      nombre: studentName,
      estadoId: estadoId,
      id: studentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentName, rowsCount, pageNumber, estadoId, studentId]);

  // const filterStatusOptions = ArrayUtils.selectLabelValue(status?.data ?? [], "estado", "estadoId");

  const columns = React.useMemo<ColumnsType<ITransferencia>>(
    () => [
      {
        title: "Fecha",
        dataIndex: "fecha",
        render: (value) => new Date(value).toLocaleDateString(),
      },
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Estudiante",
        dataIndex: "nombreCompleto",
      },
      {
        title: "Entidad financiera",
        dataIndex: "entidadFinancieraNombre",
      },
      {
        title: "Número de transacción",
        dataIndex: "numero",
      },
      {
        title: "Monto",
        dataIndex: "monto",
        render: (value) => FormatterUtils.number(value.toFixed(2)),
      },
      {
        title: "Moneda",
        dataIndex: "monedaNombre",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "Archivo",
        dataIndex: "fileId",
        render: (value) => (
          <Button
            type="link"
            icon={<Icon name="" />}
            onClick={() => getFile("Aval transferencia", value)}
          ></Button>
        ),
      },
      {
        title: "",
        dataIndex: "pagoFormaPagoId",
        render: (pagoFormaPagoId, record) => {
          const reg = data?.data?.result?.find((tr: any) => tr.pagoFormaPagoId === pagoFormaPagoId);
          return (
            <Flex>
              {reg.estadoId !== 7 && reg.estadoId !== 10 ? (
                <Button type="text" onClick={() => confirm({ ...record, estadoId: 7 })}>
                  Aceptar
                </Button>
              ) : null}
              {reg.estadoId !== 10 && reg.estadoId !== 7 ? (
                <Button type="text" onClick={() => confirm({ ...record, estadoId: 10 })}>
                  Denegar
                </Button>
              ) : null}
            </Flex>
          );
        },
      },
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeState, getFile],
  );
  return (
    <>
      <MediumLargeHeadingStyled>Transferencias notificadas</MediumLargeHeadingStyled>
      <Flex style={{ gap: "15px", alignItems: "center" }}>
        <Form.Item label="Búsqueda por ID">
          <Input
            type="text"
            onChange={(e) => setStudentId(e.target.value)}
            placeholder="Búsqueda por ID"
          />
        </Form.Item>
        <Form.Item label="Búsqueda por nombre de estudiante">
          <Input
            type="text"
            onChange={(e) => setStudentName(e.target.value)}
            placeholder="Búsqueda por nombre de estudiante"
          />
        </Form.Item>
        <Form.Item label="Filtro por estado">
          <Select
            options={filterStatusOptions}
            placeholder="Seleccione un estado"
            allowClear
            onChange={(e) => {
              setEstadoId(e);
            }}
          />
        </Form.Item>
      </Flex>
      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<ITransferencia>
          loading={isLoading || isLoadingFile}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.pagoId}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            current: pageNumber,
            total: data?.data?.pageCount * rowsCount,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
      {contextHolder}
    </>
  );
}

export default Transfers;
