import { api } from "./apiBaseQuery";

export const sponsorshipsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudentsSponsorships: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Admin/Obtener`,
          method: "POST",
          body,
        };
      },
    }),
    getSponsorshipsChanges: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Admin/ObtenerHistorial`,
          method: "POST",
          body,
        };
      },
    }),
    getSponsorshipDetail: builder.mutation({
      query: (id) => {
        return {
          url: `/Patrocinios/Admin/${id}`,
          method: "GET",
        };
      },
    }),
    updateSponsorships: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Patrocinios/Admin/Actualizar/${id}`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
    deleteSponsorship: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Patrocinios/Admin/Estado/${id}`,
          method: "DELETE",
          body,
          responseHandler: "text",
        };
      },
    }),
    deleteUnactiveSponsorships: builder.mutation({
      query: () => {
        return {
          url: `/Patrocinios/Admin`,
          method: "DELETE",
          responseHandler: "text",
        };
      },
    }),
    createSponsorship: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Admin/Crear`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    uploadSponsorships: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Admin/ImportarExcel`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    downloadSponsorshipsTemplate: builder.mutation({
      query: () => {
        return {
          url: `/Patrocinios/PlantillaExcel`,
          method: "GET",
        };
      },
    }),
    downloadSponsorships: builder.mutation({
      query: (body) => {
        return {
          url: `/Patrocinios/Admin/ObtenerExcel`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetStudentsSponsorshipsMutation,
  useGetSponsorshipsChangesMutation,
  useGetSponsorshipDetailMutation,
  useUpdateSponsorshipsMutation,
  useCreateSponsorshipMutation,
  useDeleteSponsorshipMutation,
  useDeleteUnactiveSponsorshipsMutation,
  useUploadSponsorshipsMutation,
  useDownloadSponsorshipsMutation,
  useDownloadSponsorshipsTemplateMutation,
} = sponsorshipsServices;
