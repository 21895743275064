import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const authAdmApi = createApi({
  reducerPath: "authApiAdm",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,
  }),
  endpoints: (builder) => ({
    loginAdmUser: builder.mutation({
      query: (body) => {
        return {
          url: "Usuarios/Admin/Auth",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useLoginAdmUserMutation } = authAdmApi;
