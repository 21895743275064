import { ISubject } from "@/Interfaces/subject";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Box, Card, Flex, Icon } from "@nubeteck/components";
import { Button, Image, Layout, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useCallback, useEffect, useState } from "react";
import {
  useGetCurrentSubjectsDetailMutation,
  useGetWithdrawMessageMutation,
  useWithdrawSubjectMutation,
} from "src/services/subjectsServices";
import toast from "react-hot-toast";
import NoResults from "src/assets/NoResults.svg";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
// import { useGetCareersByStudentIdMutation } from "src/services";
// import { useSelector } from "react-redux";
// import { RootState } from "src/core/store/store";

const { Content } = Layout;

interface ISpecificCourse extends ISubject {
  seccionId: number;
}

const Subjects: React.FC = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [getSubjects, { data, isLoading }] = useGetCurrentSubjectsDetailMutation();
  const [getWithdrawMessage] = useGetWithdrawMessageMutation();
  const [
    withdrawSubject,
    { isLoading: isLoadingWithdraw, isSuccess: isSuccessWithdraw, error: errorWithdraw },
  ] = useWithdrawSubjectMutation();
  const [materiaParaRetiro, setMateriaParaRetiro] = useState<ISpecificCourse>();
  // const { userInfo } = useSelector((state: RootState) => state.user);
  // const [getCareers, { data: careers }] = useGetCareersByStudentIdMutation();
  // const [carreraId, setCarreraId] = React.useState();

  // useEffect(() => {
  //   getCareers(userInfo.id);
  // }, [userInfo, getCareers]);

  // useEffect(() => {
  //   if (careers?.data?.length == 1) {
  //     setCarreraId(careers?.data[0].carreraId);
  //   }
  // }, [careers]);

  useEffect(() => {
    // if (carreraId) {
    getSubjects();
    // }
  }, [getSubjects, isSuccessWithdraw]);

  useEffect(() => {
    if (isSuccessWithdraw) {
      toast.success("¡Asignatura retirada correctamente!", toastSuccessStyle);
    }
  }, [isSuccessWithdraw]);

  useEffect(() => {
    if (errorWithdraw) {
      toast.error(
        errorWithdraw?.data?.message ?? JSON.parse(errorWithdraw?.data)?.message,
        toastErrorStyle,
      );
    }
  }, [errorWithdraw]);
  const confirm = useCallback(
    (mensaje: string) => {
      modal.confirm({
        title: `¿Está seguro(a) de que desea retirar la asignatura de ${materiaParaRetiro?.asignaturaNombre}?`,
        icon: <Icon name="" color="#FFC53D" />,
        content: mensaje,
        okText: "Aceptar",
        onOk: () => {
          withdrawSubject(materiaParaRetiro?.seccionId);
        },
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    },
    [modal, withdrawSubject, materiaParaRetiro],
  );

  useEffect(() => {
    if (materiaParaRetiro) {
      getWithdrawMessage(materiaParaRetiro.seccionId).then((mensaje: any) =>
        confirm(mensaje?.data?.data),
      );
    }
  }, [materiaParaRetiro, getWithdrawMessage, confirm]);

  const columns = React.useMemo<ColumnsType<ISpecificCourse>>(
    () => [
      {
        title: "Código",
        dataIndex: "asignaturaCodigo",
      },
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        title: "Créditos",
        dataIndex: "creditos",
      },
      {
        title: "Sección",
        dataIndex: "asignaturaSeccionCodigo",
      },
      {
        title: "Horario",
        dataIndex: "horarios",
      },
      {
        title: "Periodo",
        dataIndex: "periodoName",
      },
      {
        title: "",
        dataIndex: "asignaturaId",
        fixed: "right",
        width: 100,
        render: (_, item) => (
          <Button
            type="text"
            onClick={() => setMateriaParaRetiro(item)}
            style={{ borderColor: "#dc3e3e", color: "#dc3e3e" }}
          >
            Retirar
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>Asignaturas inscritas</LargeHeadingStyled>
        {/* <Form.Item label="Filtro por carrera">
          <Select
            allowClear
            value={carreraId}
            options={careers?.data?.map((c: any) => ({
              label: c.carreraNombre,
              value: c.carreraId,
            }))}
            onChange={(value) => {
              setCarreraId(value);
            }}
            placeholder="Seleccione una carrera"
          />
        </Form.Item> */}
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<ISpecificCourse>
            locale={{
              emptyText: (
                <Box>
                  <Box>
                    <SmallHeadingStyled>
                      No se encontraron asignaturas inscritas
                      {/* {carreraId
                        ? "No se encontraron asignaturas inscritas"
                        : "Seleccione una carrera para mostrar las asignaturas correspondientes"} */}
                    </SmallHeadingStyled>
                    <Image
                      src={NoResults}
                      preview={false}
                      alt="Ilustración sin resultados"
                      width={200}
                    />
                  </Box>
                </Box>
              ),
            }}
            loading={isLoading || isLoadingWithdraw}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => `${v.asignaturaCodigo} ${v.asignaturaNombre}`}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
        </Card>
      </Content>
      {contextHolder}
    </Flex>
  );
};

export default Subjects;
