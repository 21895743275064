import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MobileMenuShapeTopSVG, MobileMenuShapesBottomSVG } from "../../../assets";
import { Icon, Image } from "@nubeteck/components";
import {
  MenuButtonStyled,
  MobileUserSectionStyled,
  MobileMenuStyled,
  MobileMenuContainerStyled,
  UserNameStyled,
  TopShapeStyled,
  BottomShapeStyled,
  MenuButton,
} from "./mobile-menu.style";
import { UserHelp } from "src/components/other";

interface IMenuItem {
  name: string;
  link: string;
  icon: string;
}

interface IMenuMobile {
  menuItems: IMenuItem[];
  userName: string;
  isVisible: boolean;
  isHelpVisible: boolean;
  hide: () => void;
  logout: () => void;
  logoutImpersonated: () => void;
  isFakeSelecting: boolean;
}

interface IButtonProps {
  icon: JSX.Element;
  title: string;
  onClick?: () => void;
}

const MobileMenuButton = ({ icon, title, onClick }: IButtonProps) => {
  return (
    <MenuButton type="text" withIcon icon={icon} onClick={onClick}>
      {title}
    </MenuButton>
  );
};

const MobileMenu = ({
  menuItems,
  userName,
  isVisible,
  hide,
  isHelpVisible,
  logout,
  logoutImpersonated,
  isFakeSelecting,
}: IMenuMobile) => {
  const navigate = useNavigate();
  return (
    <MobileMenuContainerStyled className={isVisible ? "is-visible" : ""}>
      <MenuButtonStyled type="text" onClick={hide}>
        <Icon name="" size={32} />
      </MenuButtonStyled>
      <UserNameStyled>
        <Icon name="" /> {userName}
      </UserNameStyled>
      <MobileMenuStyled>
        {menuItems.map((item) => (
          <li key={item.name}>
            <NavLink
              onClick={
                isFakeSelecting
                  ? () => {
                      logoutImpersonated();
                      navigate(item.link);
                      window.location.reload();
                      hide();
                    }
                  : () => hide()
              }
              to={item.link}
              end={true}
              className={({ isActive }) => (isActive ? "is-active" : "")}
            >
              <Icon name={item.icon} /> {item.name}
            </NavLink>
          </li>
        ))}
        <li>
          <MobileUserSectionStyled>
            {/* <Notifications /> */}
            {isHelpVisible && <UserHelp />}

            <MobileMenuButton icon={<Icon name="" />} title="Cerrar sesión" onClick={logout} />
          </MobileUserSectionStyled>
        </li>
      </MobileMenuStyled>
      <TopShapeStyled>
        <Image src={MobileMenuShapeTopSVG} width={80} className="top-shape" preview={false} />
      </TopShapeStyled>
      <BottomShapeStyled>
        <Image
          src={MobileMenuShapesBottomSVG}
          width={150}
          className="bottom-shape"
          preview={false}
        />
      </BottomShapeStyled>
    </MobileMenuContainerStyled>
  );
};

export default MobileMenu;
