import React from "react";
import { Box } from "@nubeteck/components";
import { LargeHeadingStyled } from "../texts/texts.styled";
import {
  DashboardImage,
  WelcomeCardStyled,
  WelcomeDescText,
} from "../../../pages/dashboard/dashboard.styled";
import { DashboardSVG } from "../../../assets";

const WelcomeCard = ({ welcomeText }: { welcomeText: string }) => {
  return (
    <WelcomeCardStyled>
      <Box>
        <LargeHeadingStyled>Bienvenido(a) al sistema de Autoservicio</LargeHeadingStyled>
        <WelcomeDescText>{welcomeText}</WelcomeDescText>
      </Box>
      <DashboardImage preview={false} src={DashboardSVG} />
    </WelcomeCardStyled>
  );
};

export default WelcomeCard;
