import React, { useEffect, useState } from "react";
import "./App.css";
import { RouteElements } from "./routes/navigation";
import { Layout } from "@nubeteck/components";
import toast, { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import eventBus from "src/services/EventBus";
import { useNavigate, useLocation } from "react-router-dom";
import { useRefreshTokenMutation } from "src/services/userServices";
import { Spin, Modal } from "antd";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [refreshData, setRefreshData] = useState<{ token: string } | null>();
  const [refresh, { data: token, isLoading, isSuccess }] = useRefreshTokenMutation();

  useEffect(() => {
    eventBus.on("logout", () => {
      setRefreshData(null);
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      navigate("/login");
    });
    eventBus.on("refresh", (data: any) => {
      setRefreshData(data);
    });
    if (!localStorage.getItem("token")) {
      if (
        location.pathname !== "/login" &&
        location.pathname !== "/loginAdm" &&
        location.pathname !== "/loginFake"
      )
        navigate("/login");
    }
  }, [navigate, location]);

  useEffect(() => {
    if (isSuccess && token) {
      localStorage.setItem("token", token.data.token);
      setRefreshData(null);
      toast.success(`¡Sección renovada exitosamente!`);
    }
  }, [isSuccess, token]);

  const renderLoading = React.useCallback(() => {
    return <Layout style={{ width: "100vw", height: "100vh" }}>Cargando...</Layout>;
  }, []);

  return (
    <GoogleOAuthProvider clientId="864154840542-6gktgdv1orlmgvcrt8jg2u66k25ouhbs.apps.googleusercontent.com">
      <React.Suspense fallback={renderLoading()}>
        <Spin spinning={isLoading} size="large">
          <Modal
            open={refreshData !== null && refreshData !== undefined}
            title="Sesión próxima a vencer"
            okText="Aceptar"
            onOk={() => {
              refresh(refreshData).catch((err) => {
                eventBus.dispatch("logout", null);
                toast.error(`Error: ${err}`);
              });
            }}
            onCancel={() => eventBus.dispatch("logout", null)}
            cancelText="Cancelar"
            style={{ top: 200 }}
          >
            {" "}
            <p>La sesión esta a punto de expirar. ¿Desea renovarla?</p>
          </Modal>
          <RouteElements />
        </Spin>
        <Toaster />
      </React.Suspense>
    </GoogleOAuthProvider>
  );
};

export default App;
