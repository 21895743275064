import { Flex, Constants, RadioGroup } from "@nubeteck/components";
import styled from "styled-components";
// import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const CareersOptionsContainer = styled(Flex)`
  flex-direction: column;
  padding: 15px 0px;
  border-bottom: #c3c3c3 1px solid;
`;

export const IntroductionContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  & > div {
    width: 50%;
    padding: 0 70px;
    &.ant-image {
      padding: 45px 90px 0px 0px;
      width: 285px;
    }
  }

  @media ${device.mobileS} and ${maxDevice.tablet} {
    & > div {
      width: 100%;
      padding: 0px;
      &.ant-image {
        display: none;
      }
    }
  }

  @media ${device.tablet} and ${maxDevice.laptop} {
    flex-direction: column-reverse;
    padding: 100px 0px;
    gap: 20px;
    & > div {
      width: 80%;
      &.ant-image {
        display: flex;
        padding: 10px 0px;
        img {
          height: 200px;
        }
      }
    }
  }
`;
export const RadioGroupStyled = styled(RadioGroup)`
  @media ${device.mobileS && maxDevice.mobileL} {
    label span {
      display: block;
      overflow: hidden;
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
export const OptionsContainer = styled(Flex)`
  margin: 30px 0px;
  div {
    display: flex;
    flex-wrap: nowrap;
    min-width: max-content;
    transition: all 0.5s ease 0s;
  }
`;
export const FlexStyled = styled(Flex)`
  align-items: center;
  gap: 15px;
`;
