import styled from "styled-components";
import { Box, Constants } from "@nubeteck/components";

const { maxDevice } = Constants;

export const StepsTimerContainerStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

export const ProgressContainerStyled = styled.div`
  padding: 6px;
  border: 6px solid #004a8c;
  border-radius: 100%;
  background-color: #95c3fa;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${maxDevice.tablet} {
    position: fixed;
    z-index: 16;
    top: 60px;
    right: 25px;
  }
`;

export const StepsContainerStyled = styled.div`
  border-radius: 0px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  overflow: hidden;
  height: 50px;
  max-height: 60px;
  filter: drop-shadow(10px 4px 9px rgba(0, 0, 0, 0.08));
  padding: 10px 100px 10px 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 95%;

  & > div {
    font-size: 13px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -30%;
    right: -55px;
    width: 70px;
    height: 78px;
    border-radius: 100%;
    box-shadow: 0px 300px 0px 100vw #ffff;
  }

  @media ${maxDevice.tablet} {
    background: white;
    border-radius: 10px;
    width: 100%;
    min-height: fit-content;
    height: fit-content;
    max-height: fit-content;
    margin: 25px 0px 30px 0px;
    padding: 20px;
    &::before {
      content: none;
    }
    .ant-steps-item-content .ant-steps-item-title {
      font-size: 18px;
    }
  }
`;
