import { api } from "./apiBaseQuery";

export const selectionDatesServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getRulesVerification: builder.mutation({
      query: (data: any) => {
        return {
          url: `/Estudiantes/Reglas?matricula=${data.matriculaVerificacion}&periodoId=${data.periodoVerificacion}&anio=${data.anioVerificacion}&curriculum=${data.curriculumVerificacion}`,
          method: "GET",
        };
      },
    }),
    getRuleTypes: builder.mutation({
      query: () => {
        return {
          url: `/Reglas`,
          method: "GET",
        };
      },
    }),
    getRules: builder.mutation({
      query: (periodoId: number) => {
        return {
          url: `/Admins/Reglas?periodoId=${periodoId}`,
          method: "GET",
        };
      },
    }),
    getRulesVariables: builder.mutation({
      query: () => {
        return {
          url: `/Admins/Variables`,
          method: "GET",
        };
      },
    }),
    getSelectionDates: builder.mutation({
      query: (anio: number) => {
        return {
          url: `/Admins/ProcesoMatriculacion?anio=${anio}`,
          method: "GET",
        };
      },
    }),
    getPeriodVariants: builder.mutation({
      query: (procesoId: number) => {
        return {
          url: `Admins/Variantes/${procesoId}`,
          method: "GET",
        };
      },
    }),
    getPeriodVariantById: builder.mutation({
      query: (reglaId: number) => {
        return {
          url: `Admins/Variantes/Reglas/${reglaId}`,
          method: "GET",
        };
      },
    }),
    createSelectionDate: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/ProcesoMatriculacion`,
          method: "POST",
          body,
        };
      },
    }),
    createPeriodVariant: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Variantes`,
          method: "POST",
          body,
        };
      },
    }),
    updateSelectionDate: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Admins/ProcesoMatriculacion/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    updatePeriodVariant: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Admins/Variantes/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    updatePeriodVariantState: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Variantes/Reglas/${body.id}?activar=${body.value}`,
          method: "PUT",
        };
      },
    }),
    updateSelectionState: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Admins/ProcesoMatriculacion/Estado/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetSelectionDatesMutation,
  useGetRulesMutation,
  useGetRulesVerificationMutation,
  useCreateSelectionDateMutation,
  useUpdateSelectionDateMutation,
  useUpdateSelectionStateMutation,
  useGetPeriodVariantsMutation,
  useCreatePeriodVariantMutation,
  useUpdatePeriodVariantMutation,
  useUpdatePeriodVariantStateMutation,
  useGetPeriodVariantByIdMutation,
  useGetRuleTypesMutation,
  useGetRulesVariablesMutation,
} = selectionDatesServices;
