import styled from "styled-components";
import { CardStyled } from "../../custom/cards/card.styled";
import { Flex, Icon, Constants, Box } from "@nubeteck/components";
//import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const BillBoxStyled = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const BillFlexStyled = styled(Flex)`
  justify-content: space-between;
  min-width: 270px;
  max-width: 385px;
  align-items: center;
  button {
    padding: 0px;
  }
  @media ${device.laptop} {
    & > span,
    h4 {
      width: 140px;
      padding: 0px 7px;
      word-break: keep-all;
    }
  }
`;
export const TransferDataStyled = styled.div`
  text-align: right;
`;
export const InputContainerStyled = styled.div`
  margin: 10px 0px;
`;
export const BillCardStyled = styled(CardStyled)`
  .ant-card-body {
    padding: 30px 40px;
  }
  @media ${device.mobileS} and ${maxDevice.laptop} {
    .ant-card-body {
      padding: 30px 30px;
    }
    width: 86vw;
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    max-width: 360px;
  }
`;

export const IconStyled = styled(Icon)`
  font-size: 15px;
  cursor: pointer;
  position: relative;
  top: 4px;
`;
export const BillConceptStyled = styled.span`
  font-weight: 700;
  @media ${device.laptop} {
    width: 140px;
    padding: 0px 7px;
    word-break: keep-all;
  }
`;

export const SmallHeadingStyled = styled.span`
  color: ${({ theme }) => theme.colors.colorAccent};
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0;
  @media ${device.laptop} {
    width: 140px;
    padding: 0px 7px;
    word-break: keep-all;
  }
`;
export const RedTextStyled = styled.span`
  color: #ac0000;
  font-weight: 600;
  @media ${device.laptop} {
    width: 140px;
    padding: 0px 7px;
  }
`;
