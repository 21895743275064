import styled from "styled-components";
import { Button, Box, Constants } from "@nubeteck/components";

const { maxDevice } = Constants;

export const MobileMenuContainerStyled = styled(Box)`
  display: none;
  position: fixed;
  transition: all ease 0.7s;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 20;
  padding: 30px 0px;
  background-color: ${({ theme }) => theme.colors.colorAccent};

  @media ${maxDevice.laptop} {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateX(400px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    overflow: auto;

    &.is-visible {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      transform: translateX(0px);
    }
  }

  @media (max-width: 1180px) {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateX(400px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    overflow: auto;

    &.is-visible {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      transform: translateX(0px);
    }
  }
`;

export const MobileMenuStyled = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  font-size: 20px;
  padding: 0;
  width: 105%;

  li {
    list-style-type: none;
    min-width: 100%;
    text-align: center;

    a {
      color: white;
      padding: 13px 40px;
      gap: 12px;
      width: 100%;
      display: flex;
      text-align: start;
      justify-content: flex-start;
      text-decoration: none;
      &.is-active {
        background-color: ${({ theme }) => theme.colors.primary500};
      }
    }
  }
`;

export const MenuButton = styled(Button)`
  &:hover {
    background: transparent !important;
    color: #fff !important;
  }
  width: 100%;
`;

export const MobileUserSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width: 100%;
  gap: 8px;
  padding-bottom: 50px;

  button {
    color: white;
    font-size: 20px;
    height: 35px;
    padding-left: 40px;
    span {
      height: 100%;
    }
  }
`;
export const UserNameStyled = styled.span`
  color: white;
  font-size: 22px;
  font-weight: bolder;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  right: 7px;
`;
export const BottomShapeStyled = styled(Box)`
  position: absolute;
  bottom: 60px;
  right: 0px;
`;
export const TopShapeStyled = styled(Box)`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const MenuButtonStyled = styled(Button)`
  color: white;
  padding: 0;
  margin-bottom: 10px;
`;
