import React from "react";
import { ButtonAlternativeStyled, ButtonPrimaryStyled, ButtonsContainer } from "./buttons.styled";

interface IBackNextButtonProps {
  goBack: () => void;
  goToNext: () => void;
  omitNext: boolean;
  omitBack: boolean;
}
const BackNextButtons = ({ goBack, goToNext, omitBack, omitNext }: IBackNextButtonProps) => {
  return (
    <ButtonsContainer>
      {!omitBack && (
        <ButtonAlternativeStyled type="primary" size="large" onClick={goBack} ghost>
          Atrás
        </ButtonAlternativeStyled>
      )}
      {!omitNext && (
        <ButtonPrimaryStyled type="primary" size="large" onClick={goToNext}>
          Siguiente
        </ButtonPrimaryStyled>
      )}
    </ButtonsContainer>
  );
};

export default BackNextButtons;
