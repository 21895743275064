import React from "react";

export function useMediaQuery(query: string): boolean {
  const getMatches = React.useCallback((query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  }, []);

  const [matches, setMatches] = React.useState<boolean>(getMatches(query));

  const handleChange = React.useCallback((): void => {
    requestAnimationFrame(() => {
      setMatches(getMatches(query));
    });
  }, [getMatches, query]);

  React.useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [handleChange, query]);

  return matches;
}
