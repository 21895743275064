import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Flex, Icon, Text } from "@nubeteck/components";
import { FlexStyled } from "../../../components/registration/register-confirmation/confirmation.styled";
import {
  ButtonAlternativeStyled,
  ButtonPrimaryStyled,
  ButtonsContainer,
} from "../../../components/custom/buttons/buttons.styled";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Bill, CardsGroup, RegisterSteps, ResumeList, ScrollView } from "../../../components";
import toast from "react-hot-toast";
import { BillBoxStyled } from "../../../components/billing/bill/bill.styled";
import { Card, Checkbox, Image, MenuProps, Modal, Skeleton } from "antd";
import {
  DropdownSubIcon,
  ResumeCardStyled,
  ResumeFlexStyled,
} from "../../../components/billing/resume-card/resumeCard.styled";
import {
  useDeleteCartItemMutation,
  useGetCartMutation,
  useGetCartTermsMutation,
  useGetIsThereAPaymentInProcessMutation,
  useGetPaymentInfoMutation,
  useSaveBillMutation,
  useSaveInvoicePersonDataMutation,
} from "src/services/cartServices";
import useManageTimer from "src/hooks/useManageTimer";
import { toastErrorStyle, toastSuccessStyle, toastWarningStyle } from "src/constants";
import { ICartItem } from "@/Interfaces/cart";
import { themeColors } from "src/core";
import {
  CartHeadingStyled,
  CartResumeContainerStyled,
  FeesContainerStyled,
  ImageButtonContainerStyled,
  SpinnerContainerStyled,
} from "./cart.styled";
import {
  CardText,
  CardTitle,
  CardUserInvoiceName,
} from "src/components/reports/cards/report-cards.styled";
import { FormatterUtils } from "@nubeteck/utils";
import { useSelector } from "react-redux";
import { PaymentSVG } from "src/assets";
import { RootState } from "@/core/store/store";
import { useMediaQuery } from "src/hooks";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAssignCartToCashierMutation } from "src/services/chargesSettingsServices";
import ThirdPersonForm from "./third-person-form";
import { getIsUserImpersonated } from "src/utils";
import CartMoneyInfo from "src/components/billing/cart-money-info/cart-money-info";
declare global {
  interface Window {
    AlignetVPOS2: { openModal: (host: string, designID: number) => void };
  }
}

interface IRegisterStepProps {
  id: number;
  title: string;
  component: JSX.Element;
}
interface IFees {
  id: number;
  descripcion: string;
  saldo: number;
  moneda: number;
  fechaVencimiento: string;
}

export const Payments = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  const [modal, contextHolder] = Modal.useModal();
  const [totalPendiente, setTotalPendiente] = useState({ colones: 0, dolares: 0 });
  const [totalCuotas, setTotalCuotas] = useState(0);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [totalAmountCol, setTotalAmountCol] = useState(0);
  const [totalAmountDol, setTotalAmountDol] = useState(0);
  const [cargosMonedas, setCargosMonedas] = useState({ colones: false, dolares: false });
  const [cuotasPasadas, setCuotasPasadas] = useState<IFees[]>([]);
  const [aceptaTerminos, setAceptaTerminos] = useState(false);
  const [modalInvoiceData, setModalInvoiceData] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [getCart, { data: cartInfo, isLoading, error: errorGet }] = useGetCartMutation();
  const [getPaymentInProcess, { isLoading: isLoadingGettingPaymentInProcess }] =
    useGetIsThereAPaymentInProcessMutation();
  const [getCartTerms, { data: cartTerms }] = useGetCartTermsMutation();
  const [getPaymentInfo, { data: paymentData, isLoading: isLoadingPaymentInfo }] =
    useGetPaymentInfoMutation();
  const [saveCart, { isLoading: isLoadingPayment, error: errorPayment }] = useSaveBillMutation();
  const { getTimer, timeLeft, extend, isonHours, hoursToExtend, hoursForPresential } =
    useManageTimer(false, false);
  const [
    deleteItem,
    { isLoading: isLoadingDeleting, isSuccess: isSuccessDeleting, isError: isErrorDeleting },
  ] = useDeleteCartItemMutation();
  const [
    savePerson,
    { isLoading: isLoadingSavingPerson, isSuccess: isSuccesSavingPerson, error: errorSavingPerson },
  ] = useSaveInvoicePersonDataMutation();
  const [
    AssignCartToCashier,
    {
      isLoading: isLoadingCartToCashier,
      isSuccess: isSuccesCartToCashier,
      error: errorCartToCashier,
    },
  ] = useAssignCartToCashierMutation();

  const [materiasCuotas, setMateriasCuotas] = useState<Array<{ id: number; costo: number }>>([]);
  const [form, setForm] = useState<any>({
    saldoAFavorAUsarColones: 0,
    saldoAFavorAUsarDolar: 0,
    cantidadCuotas: 0,
    montoTransferencia: 0,
    monedaTransferencia: 0,
  });

  const { previous } = useParams();
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);
  const [modalTerminos, contextHolderTerminos] = Modal.useModal();

  const isUserImpersonated = getIsUserImpersonated(localStorage.getItem("token") ?? "");

  useEffect(() => {
    const tempMatCuotas = cartInfo?.data?.matriculasCuotas?.map((mat: any) => ({
      id: mat.matriculaId,
      costo: mat.costo,
    }));
    if (JSON.stringify(tempMatCuotas ?? []) !== JSON.stringify(materiasCuotas ?? []))
      getCart({ materiasCuotas: materiasCuotas?.map((mat) => mat.id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materiasCuotas]);

  useEffect(() => {
    getCart({ materiasCuotas: materiasCuotas?.map((mat) => mat.id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleting]);

  useEffect(() => {
    getCartTerms();
  }, [getCartTerms]);

  useEffect(() => {
    setTotalCuotas(materiasCuotas.map((mat) => mat.costo).reduce((prev, next) => prev + next, 0));
  }, [materiasCuotas]);

  const getCuotasPasadas: (saldos: IFees[]) => IFees[] = (saldos) => {
    let saldosParaFiltrar = saldos;
    const result: IFees[] = [];
    saldos?.map((saldo) => {
      const saldosCodigoYFechaSimilar = saldosParaFiltrar.filter(
        (s) => s.descripcion == saldo.descripcion && s.fechaVencimiento == saldo.fechaVencimiento,
      );
      const sumaSaldos = saldosCodigoYFechaSimilar.reduce((previous, current) => {
        saldosParaFiltrar = saldosParaFiltrar.filter((sa) => sa.id !== current.id);
        return previous + current.saldo;
      }, 0);
      if (saldosCodigoYFechaSimilar.length > 0) result.push({ ...saldo, saldo: sumaSaldos });
    });
    return result;
  };

  useEffect(() => {
    if (cartInfo?.data?.matriculasCuotas)
      setMateriasCuotas(
        cartInfo?.data?.matriculasCuotas?.map((mat: any) => ({
          id: mat.matriculaId,
          costo: mat.costo,
        })),
      );
    setCuotasPasadas(getCuotasPasadas(cartInfo?.data?.saldosNoVencidos));
    if (
      cartInfo?.data?.asignaturas?.length === 0 &&
      cartInfo?.data?.servicios?.length === 0 &&
      (cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 ||
        cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 ||
        cartInfo?.data?.dolares?.deuda?.saldoVencido > 0)
    ) {
      setCurrentStep(1);
    }
    const infoColones = cartInfo?.data?.colones;
    const infoDolares = cartInfo?.data?.dolares;

    setTotalPendiente({
      colones:
        infoColones?.deuda?.costoTotal -
        infoColones?.descuento?.totalDescuentoBeca -
        infoColones?.descuento?.totalDescuentoPatrocinio,
      dolares:
        infoDolares?.deuda?.costoTotal -
        infoDolares?.descuento?.totalDescuentoBeca -
        infoDolares?.descuento?.totalDescuentoPatrocinio,
    });

    setCargosMonedas({
      colones:
        infoColones?.deuda?.saldoNoVencido > 0 ||
        infoColones?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.asignaturas.find((as: ICartItem) => as.monedaId == 2) !== undefined ||
        cartInfo?.data?.servicios.find((ser: ICartItem) => ser.monedaId == 2) !== undefined,
      dolares:
        infoDolares?.deuda?.saldoNoVencido > 0 ||
        infoDolares?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.asignaturas.find((as: ICartItem) => as.monedaId == 1) !== undefined ||
        cartInfo?.data?.servicios.find((ser: ICartItem) => ser.monedaId == 1) !== undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartInfo]);

  useEffect(() => {
    if (errorPayment) {
      toast.error(errorPayment?.data?.message, toastErrorStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPayment]);

  useEffect(() => {
    if (errorGet) {
      toast.error(errorGet?.data?.message, toastErrorStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGet]);

  useEffect(() => {
    if (errorSavingPerson) {
      toast.error(errorSavingPerson?.data?.message, toastErrorStyle);
    }
    if (isSuccesSavingPerson) {
      toast.success("¡Operación realizada correctamente!", toastSuccessStyle);
      getCart({ materiasCuotas: materiasCuotas?.map((mat) => mat.id) });
      setModalInvoiceData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSavingPerson, isSuccesSavingPerson]);

  useEffect(() => {
    if (errorCartToCashier) {
      toast.error(errorCartToCashier?.data?.message, toastErrorStyle);
    }
    if (isSuccesCartToCashier) {
      toast.success(
        <div>
          <h3 style={toastSuccessStyle.style}>
            ¡Operación realizada correctamente! El pago será procesado por el cajero(a).
          </h3>

          <p>El tiempo que tiene para pagar ha sido extendido a {hoursForPresential} hora(s).</p>

          {cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
          cartInfo?.data?.dolares?.deuda?.saldoVencido > 0 ? (
            <p style={{ ...toastWarningStyle.style, width: "auto" }}>
              Si tiene alguna duda respecto a su saldo vencido, comuníquese con el departamento
              financiero.
            </p>
          ) : null}
        </div>,

        { duration: 8000 },
      );
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCartToCashier, isSuccesCartToCashier]);

  useEffect(() => {
    if (isErrorDeleting) {
      toast.error("Ha ocurrido un error. Intente de nuevo", toastErrorStyle);
    }
    if (isSuccessDeleting) {
      toast.success("¡Operación realizada correctamente!", toastSuccessStyle);
    }
  }, [isSuccessDeleting, isErrorDeleting]);

  useEffect(() => {
    if (paymentData?.data !== undefined) {
      window.AlignetVPOS2.openModal("", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  const itemsSubjects: (data: any) => MenuProps["items"] = (data) => [
    {
      key: "1",
      label: "Eliminar",
      icon: <DropdownSubIcon name="" color="#c4c1c1" />,
      onClick: () => {
        confirmDelete(
          { itemId: data.seccionId, esAsignatura: true },
          data.nombre,
          data.matriculaId,
        );
      },
    },
  ];

  const itemsServices: (data: any) => MenuProps["items"] = (data) =>
    !data.esObligatorio
      ? [
          {
            key: "2",
            label: "Eliminar",
            icon: <DropdownSubIcon name="" color="#c4c1c1" />,
            onClick: () => {
              confirmDelete({ itemId: data.servicioId, esAsignatura: false }, data.nombre);
            },
          },
        ]
      : undefined;

  const mostrarTerminos = () => {
    modalTerminos.info({
      title: `Términos y condiciones`,

      content: (
        <ScrollView height="500px">
          <div dangerouslySetInnerHTML={{ __html: cartTerms }}></div>
        </ScrollView>
      ),
      okText: "Cerrar",
      style: { top: 50 },
    });
  };

  const confirmDelete = (data: any, name: string, matriculaId?: number) => {
    modal.confirm({
      title: `Remover ${data.esAsignatura ? "asignatura" : "servicio"}`,
      icon: <Icon name="" color="#FFC53D" />,
      content: `¿Es seguro que desea remover de la factura ${
        data.esAsignatura ? "la asignatura" : "el servicio"
      } ${name}?`,
      okText: "Aceptar",
      onOk: () => {
        deleteItem(data);
        setMateriasCuotas(materiasCuotas?.filter((mat) => mat.id !== matriculaId));
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };
  const getTimeLeft = () => {
    if (
      currentStep !== 2 &&
      timeLeft !== 0 &&
      (cartInfo?.data?.asignaturas.length > 0 || previous == "selection")
    ) {
      return (
        <RegisterSteps
          currentStep={currentStep + 2}
          steps={[{ title: "Inicio" }, { title: "Selección de materias" }].concat(
            steps.map((st) => (st.id !== 2 ? { title: st.title } : null)!),
          )}
          getExternalTimer={() => getTimer()}
        />
      );
    }
    return <></>;
  };

  const displayWarning = () => {
    toast(
      <div>
        <h3 style={toastWarningStyle.style}>
          NO CIERRE EL NAVEGADOR HASTA QUE SE TRAMITE SU TRANSACCIÓN CON EL BANCO.
        </h3>
        {cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.dolares?.deuda?.saldoVencido > 0 ? (
          <p style={{ ...toastWarningStyle.style, width: "auto" }}>
            Si tiene alguna duda respecto a su saldo vencido, comuníquese con el departamento
            financiero.
          </p>
        ) : null}
        <p>
          Una vez procesado el pago recibirá un correo con los detalles de su recibo. En caso de no
          haber recibido el correo, comuníquese con el departamento financiero.
        </p>
      </div>,
      {
        duration: 5000,
        style: {
          padding: "30px",
          fontSize: 17,
          width: "fit-content",
          height: "100%",
          fontWeight: 700,
          boxShadow: "0 0 0 50vmax rgba(0,0,0,.5)",
        },
      },
    );
  };

  const getPresentialPaymentVisible = () => {
    if (totalAmountCol > 0.1 || totalAmountDol > 0.1) return true;
  };

  const obtenerValidaciones = async (currency: number) => {
    const validacionPagoEnProceso = await getPaymentInProcess().then((res: any) => {
      if (res?.data?.data === false) {
        return true;
      } else {
        return false;
      }
    });

    const total = currency === 188 ? totalAmountCol : totalAmountDol;
    const pendiente = currency === 188 ? totalPendiente.colones : totalPendiente.dolares;
    const saldoAFavorAUsar =
      currency === 188 ? form.saldoAFavorAUsarColones : form.saldoAFavorAUsarDolar;

    let error = null;
    if (!aceptaTerminos) error = "Debe aceptar los términos para poder efectuar el pago.";

    if (error === null) {
      if (!validacionPagoEnProceso) {
        modal.confirm({
          title: `Usted cuenta con un pago que aún está en proceso. Por favor, espere a que termine de ser procesado.`,
          icon: <Icon name="" color="#FFC53D" />,
          content: `¿Está seguro(a) de que desea continuar y realizar un nuevo pago?`,
          okText: "Continuar",
          onOk: () => {
            realizarPago(total, pendiente, saldoAFavorAUsar, currency);
          },
          cancelText: "Cancelar",
          style: { top: 200 },
        });
      } else {
        realizarPago(total, pendiente, saldoAFavorAUsar, currency);
      }
    } else {
      toast(error, toastErrorStyle);
    }
  };

  const realizarPago = (
    total: number,
    pendiente: number,
    saldoAFavorAUsar: number,
    monedaId: number,
  ) => {
    displayWarning();

    saveCart({
      saldoAFavorAUsar: saldoAFavorAUsar,
      cantidadCuotas:
        cartInfo?.data?.numeroCuotas >= 1 && materiasCuotas.length > 0
          ? cartInfo?.data?.numeroCuotas
          : 0,
      saldoNoVencidoAPagar: total - pendiente > 0 ? total - pendiente : 0,
      totalPago: total.toFixed(2),
      totalPagoTransferencia: form.montoTransferencia.toFixed(2),
      monedaId,
      monedaTransferencia: form.monedaTransferencia,
      matriculasEnCuotasInt: materiasCuotas.map((mat) => mat.id),
    }).then((res: any) => {
      if (res?.data?.responseStatus == "Success") {
        if (totalAmountCol < 0.1 && totalAmountDol < 0.1) {
          toast.success("Pago realizado correctamente", toastSuccessStyle);
          navigate(`/last-receipt/${cartInfo?.data?.pagoIdActual}/${null}`);
        } else {
          getPaymentInfo({
            monto: `${total}`,
            moneda: monedaId,
            people: userInfo.id,
          });
        }
      }
    });
  };

  const getExtendButton = () => {
    return !isonHours &&
      timeLeft !== 0 &&
      cartInfo?.data?.asignaturas.length > 0 &&
      hoursToExtend !== 0 ? (
      <Button
        style={{ display: "flex", alignItems: "flex-start" }}
        onClick={extend}
        size="large"
        icon={<Icon name="" color={themeColors.colorAccent} />}
      >
        Reservar Matrícula por {hoursToExtend} horas
      </Button>
    ) : null;
  };

  const marcarParaCuotas = (matriculaId: number, costo: number) => {
    if (materiasCuotas?.find((mat) => mat.id === matriculaId)) {
      setMateriasCuotas(materiasCuotas?.filter((mat) => mat.id !== matriculaId));
    } else {
      setMateriasCuotas(materiasCuotas?.concat([{ id: matriculaId, costo }]));
    }
  };

  const changePastFeesGettingPaid = (monto: number, moneda: number) => {
    if (cuotasPasadas?.length > 0) {
      let resto = monto;
      const cuotasTemp: IFees[] = [];
      const saldosNoVencidos = getCuotasPasadas(cartInfo?.data?.saldosNoVencidos);

      saldosNoVencidos?.forEach((fee: IFees) => {
        let saldo = fee.saldo;
        if (resto > 0 && fee.moneda === moneda) {
          saldo = saldo - resto;
          if (saldo < 0) {
            resto = saldo * -1;
            saldo = 0;
          } else {
            resto = 0;
          }
        }
        cuotasTemp.push({ ...fee, saldo });
      });

      setCuotasPasadas(cuotasTemp);
    }
  };

  const invoiceHeader = (
    <>
      <CardsGroup
        extraContent={
          <>
            <CardUserInvoiceName direction="vertical">
              <CardTitle style={{ fontSize: "18px" }}>Factura a nombre de</CardTitle>
              <Flex>
                <CardText>
                  {isLoading ? "...Cargando" : cartInfo?.data?.estudianteTercero?.terceroNombre}
                </CardText>{" "}
                <Button
                  disabled={isLoading}
                  size="small"
                  type="link"
                  onClick={() => setModalInvoiceData(true)}
                >
                  Cambiar
                </Button>
              </Flex>
            </CardUserInvoiceName>
            <CartMoneyInfo
              cargosMonedas={cargosMonedas}
              totalCuotas={totalCuotas}
              totalPendiente={totalPendiente}
              reglaEstimacion={cartInfo?.data?.regla?.reglaEstimada}
            />

            <ThirdPersonForm
              initialValues={cartInfo?.data?.estudianteTercero}
              open={modalInvoiceData}
              savePerson={savePerson}
              isLoading={isLoadingSavingPerson}
              onCancel={() => {
                setModalInvoiceData(false);
              }}
            />
          </>
        }
      />
    </>
  );

  const steps: IRegisterStepProps[] = [
    {
      id: 0,
      title: "Carrito",
      component: (
        <>
          {invoiceHeader}

          <FlexStyled>
            <CartResumeContainerStyled>
              {isLoading || isLoadingPayment || isLoadingDeleting ? (
                <>
                  <ResumeCardStyled>
                    <ResumeFlexStyled>
                      <Skeleton.Image active />
                      <Skeleton loading={true} active round title={false} />
                    </ResumeFlexStyled>
                  </ResumeCardStyled>
                  <br />
                  <ResumeCardStyled>
                    <ResumeFlexStyled>
                      <Skeleton.Image active />
                      <Skeleton loading={true} active round title={false} />
                    </ResumeFlexStyled>
                  </ResumeCardStyled>
                </>
              ) : (!cartInfo?.data?.asignaturas && !cartInfo?.data?.servicios) ||
                (cartInfo?.data?.asignaturas?.length == 0 &&
                  cartInfo?.data?.servicios?.length == 0) ? (
                <>
                  <SpinnerContainerStyled>
                    <LargeHeadingStyled>Sin cargos o servicios por pagar</LargeHeadingStyled>
                  </SpinnerContainerStyled>
                </>
              ) : null}
              {cartInfo?.data?.asignaturas?.length > 0 && (
                <ResumeList
                  programas={cartInfo?.data?.carreras}
                  isLoading={isLoading}
                  marcarParaCuotas={marcarParaCuotas}
                  viewHeight="40vh"
                  title="Materia(s) seleccionada(s):"
                  listType="subject"
                  listItems={
                    cartInfo?.data?.asignaturas.map((a: ICartItem & { carreraId: number }) => ({
                      ...a,
                      reglaTipoId: cartInfo?.data?.regla?.reglaTipoId,
                      programaId: a.carreraId,
                      pagaCuotas:
                        materiasCuotas.find((mat) => mat.id == a.matriculaId) !== undefined,
                    })) as ICartItem[]
                  }
                  items={itemsSubjects}
                />
              )}
              <br />
              {cartInfo?.data?.servicios?.length > 0 && (
                <ResumeList
                  isLoading={isLoading}
                  viewHeight="30vh"
                  title="Servicios:"
                  listType="service"
                  listItems={cartInfo?.data?.servicios as ICartItem[]}
                  items={itemsServices}
                />
              )}
              <br />

              {getExtendButton()}
            </CartResumeContainerStyled>
            <ImageButtonContainerStyled>
              <Image src={PaymentSVG} width={300} preview={false} style={{ margin: "50px 0px" }} />
              <br />
              <br />
              <ButtonsContainer>
                {previous !== "general" && (
                  <ButtonAlternativeStyled
                    onClick={() =>
                      navigate(
                        previous === "selection"
                          ? `/subjects-registration/${
                              cartInfo?.data?.carreras?.length == 1
                                ? cartInfo?.data?.carreras[0].carreraId
                                : "initial"
                            }`
                          : "/requests",
                      )
                    }
                    type="default"
                    size="large"
                  >
                    {" "}
                    Atrás
                  </ButtonAlternativeStyled>
                )}
                {(cartInfo?.data?.asignaturas?.length !== 0 ||
                  cartInfo?.data?.servicios?.length !== 0 ||
                  cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 ||
                  cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
                  cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 ||
                  cartInfo?.data?.dolares?.deuda?.saldoVencido > 0) && (
                  <ButtonPrimaryStyled
                    type="primary"
                    disabled={isLoading}
                    size="large"
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    Siguiente
                  </ButtonPrimaryStyled>
                )}
              </ButtonsContainer>
            </ImageButtonContainerStyled>
          </FlexStyled>
        </>
      ),
    },
    {
      id: 1,
      title: "Caja",
      component: (
        <>
          {invoiceHeader}
          <FlexStyled>
            <div>
              <Image src={PaymentSVG} width={400} preview={false} style={{ margin: "100px 0px" }} />
              {getExtendButton()}
            </div>
            <FlexStyled style={{ gap: "20px" }}>
              {cuotasPasadas?.length > 0 && (
                <ScrollView
                  height={"710px"}
                  style={{ width: "100%", minWidth: "120px", textAlign: "center" }}
                >
                  <>
                    <SmallHeadingStyled style={{ textAlign: "center" }}>
                      Cuotas pendientes
                    </SmallHeadingStyled>
                    {cuotasPasadas.map((fee) => (
                      <FeesContainerStyled key={fee.id}>
                        <h5>{fee.descripcion}</h5>
                        <span>
                          {fee.moneda === 2 ? "₡" : "$"}
                          {FormatterUtils.number(fee.saldo.toFixed(2))}
                        </span>
                        <br />
                        <br />
                        {new Date(fee.fechaVencimiento).toLocaleDateString() !== "1/1/1" ? (
                          <span style={{ color: "#00479B", fontSize: "12px" }}>
                            Vence: {new Date(fee.fechaVencimiento).toLocaleDateString()}
                          </span>
                        ) : null}
                      </FeesContainerStyled>
                    ))}
                  </>
                </ScrollView>
              )}
              <BillBoxStyled>
                <Bill
                  hayMateriasOServicios={
                    cartInfo?.data?.asignaturas?.length > 0 || cartInfo?.data?.servicios?.length > 0
                  }
                  conversion={cartInfo?.data?.conversion}
                  limiteCuotas={cartInfo?.data?.numeroCuotas}
                  actualizarPagosCuotas={changePastFeesGettingPaid}
                  hayCargosEnColones={cargosMonedas.colones}
                  hayCargosEnDolares={cargosMonedas.dolares}
                  dataTransferencia={
                    cartInfo?.data?.colones?.descuento?.transferencia ??
                    cartInfo?.data?.dolares?.descuento?.transferencia
                  }
                  setTotalAmountColones={(num: number) => setTotalAmountCol(num)}
                  setTotalAmountDolares={(num: number) => setTotalAmountDol(num)}
                  totalPendienteColones={totalPendiente.colones}
                  totalPendienteDolares={totalPendiente.dolares}
                  setTotalPendienteColones={(num: number) =>
                    setTotalPendiente({ ...totalPendiente, colones: num })
                  }
                  setTotalPendienteDolares={(num: number) =>
                    setTotalPendiente({ ...totalPendiente, dolares: num })
                  }
                  totalCuotas={totalCuotas}
                  pagoCuotas={materiasCuotas.length > 0}
                  setValue={(obj) => setForm({ ...form, ...obj })}
                  billColones={{
                    ...cartInfo?.data?.colones.deuda,
                    ...cartInfo?.data?.colones.descuento,
                    ...cartInfo?.data?.colones.pago,
                  }}
                  billDolares={{
                    ...cartInfo?.data?.dolares.deuda,
                    ...cartInfo?.data?.dolares.descuento,
                    ...cartInfo?.data?.dolares.pago,
                  }}
                />
                <br />
                <Card>
                  <Checkbox
                    onChange={(e) => setAceptaTerminos(e.target.checked)}
                    defaultChecked={aceptaTerminos}
                  >
                    <Text style={{ fontWeight: "bold" }}>Acepto los </Text>

                    <Button type="link" onClick={() => mostrarTerminos()} style={{ padding: 0 }}>
                      términos y condiciones.
                    </Button>
                  </Checkbox>
                </Card>
                <form ref={formRef} method="POST" className="alignet-form-vpos2">
                  <input
                    name="acquirerId"
                    value={paymentData?.data?.acquirerId}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="idCommerce"
                    value={paymentData?.data?.idCommerce}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="purchaseOperationNumber"
                    value={paymentData?.data?.purchaseOperationNumber}
                    hidden
                    onChange={() => null}
                  />
                  <input name="purchaseAmount" value={paymentData?.data?.purchaseAmount} hidden />
                  <input
                    name="purchaseCurrencyCode"
                    value={paymentData?.data?.purchaseCurrencyCode}
                    hidden
                    onChange={() => null}
                  />
                  <input name="language" value="SP" hidden />
                  <input
                    name="shippingFirstName"
                    value={paymentData?.data?.shippingFirstName}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="shippingLastName"
                    value={paymentData?.data?.shippingLastName}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="shippingEmail"
                    value={paymentData?.data?.shippingEmail}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="shippingAddress"
                    value="Direccion ABC"
                    hidden
                    onChange={() => null}
                  />
                  <input name="shippingZIP" value="ZIP 123" hidden onChange={() => null} />
                  <input name="shippingCity" value="CITY ABC" hidden onChange={() => null} />
                  <input name="shippingState" value="STATE ABC" hidden onChange={() => null} />
                  <input name="shippingCountry" value="PE" hidden onChange={() => null} />
                  <input
                    name="userCommerce"
                    value={paymentData?.data?.userCommerce}
                    hidden
                    onChange={() => null}
                  />
                  <input name="userCodePayme" value="8--580--4390" hidden onChange={() => null} />
                  <input
                    name="descriptionProducts"
                    value="Producto ABC"
                    hidden
                    onChange={() => null}
                  />
                  <input name="programmingLanguage" value="C# .NET" hidden onChange={() => null} />
                  <input name="reserved1" value="Prueba Reservado" hidden onChange={() => null} />
                  <input name="reserved2" value="autoservicioNew" hidden onChange={() => null} />
                  <input
                    name="reserved3"
                    value={paymentData?.data?.reserved3}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="reserved4"
                    value={materiasCuotas.map((mat) => mat.id).toString()}
                    hidden
                    onChange={() => null}
                  />
                  <input
                    name="purchaseVerification"
                    value={paymentData?.data?.purchaseVerification}
                    hidden
                    onChange={() => null}
                  />

                  <ButtonsContainer>
                    <ButtonAlternativeStyled
                      onClick={() => setCurrentStep(currentStep - 1)}
                      type="default"
                      size="large"
                    >
                      {" "}
                      Atrás
                    </ButtonAlternativeStyled>
                    {!isUserImpersonated && (
                      <>
                        {cargosMonedas.colones && (
                          <>
                            {totalAmountCol < 0.1 &&
                            (cartInfo?.data?.asignaturas?.length > 0 ||
                              cartInfo?.data?.servicios?.length > 0 ||
                              (cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 &&
                                (form.saldoAFavorAUsarColones ?? 0) > 0) ||
                              (cartInfo?.data?.colones?.deuda?.saldoVencido > 0 &&
                                (form.saldoAFavorAUsarColones ?? 0) > 0)) ? (
                              <ButtonPrimaryStyled
                                type="primary"
                                disabled={isLoading}
                                size="large"
                                onClick={() => obtenerValidaciones(188)}
                                loading={
                                  isLoadingPayment ||
                                  isLoadingGettingPaymentInProcess ||
                                  isLoadingPaymentInfo
                                }
                              >
                                Terminar ₡
                              </ButtonPrimaryStyled>
                            ) : totalAmountCol > 0.1 ? (
                              <ButtonPrimaryStyled
                                type="primary"
                                disabled={isLoading}
                                size="large"
                                onClick={() => obtenerValidaciones(188)}
                                loading={
                                  isLoadingPayment ||
                                  isLoadingGettingPaymentInProcess ||
                                  isLoadingPaymentInfo
                                }
                              >
                                {cartInfo?.data?.hayCargosEnDolares
                                  ? "Pagar cargos en colones"
                                  : "Pago con tarjeta de débito/crédito"}
                              </ButtonPrimaryStyled>
                            ) : null}
                          </>
                        )}

                        {cargosMonedas.dolares && (
                          <>
                            {totalAmountDol < 0.1 &&
                            (cartInfo?.data?.asignaturas?.length > 0 ||
                              cartInfo?.data?.servicios?.length > 0 ||
                              (cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 &&
                                (form.saldoAFavorAUsarDolar ?? 0) > 0) ||
                              (cartInfo?.data?.dolares?.deuda?.saldoVencido > 0 &&
                                (form.saldoAFavorAUsarDolar ?? 0) > 0)) ? (
                              <ButtonPrimaryStyled
                                type="primary"
                                size="large"
                                loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                                onClick={() => obtenerValidaciones(840)}
                              >
                                Terminar $
                              </ButtonPrimaryStyled>
                            ) : totalAmountDol > 0.1 ? (
                              <ButtonPrimaryStyled
                                type="primary"
                                size="large"
                                loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                                onClick={() => obtenerValidaciones(840)}
                              >
                                {cargosMonedas.colones
                                  ? "Pagar cargos en dólares"
                                  : "Pago con tarjeta de débito/crédito"}
                              </ButtonPrimaryStyled>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </ButtonsContainer>
                  {getPresentialPaymentVisible() && !isUserImpersonated && (
                    <ButtonsContainer>
                      <ButtonAlternativeStyled
                        style={{ width: "100%" }}
                        onClick={() => {
                          if (aceptaTerminos) {
                            AssignCartToCashier(materiasCuotas?.map((ma) => ma.id));
                          } else {
                            toast(
                              "Debe aceptar los términos para procesar el pago.",
                              toastErrorStyle,
                            );
                          }
                        }}
                        loading={isLoadingCartToCashier}
                      >
                        Realizaré el pago presencialmente en caja
                      </ButtonAlternativeStyled>
                    </ButtonsContainer>
                  )}
                </form>
              </BillBoxStyled>
            </FlexStyled>
          </FlexStyled>
          {contextHolderTerminos}
        </>
      ),
    },
  ];

  return (
    <Box style={{ maxWidth: 1440 }}>
      <SectionHeaderStyled style={{ height: isMobile ? "75px" : "35px", padding: 0 }}>
        <CartHeadingStyled>{currentStep == 0 ? "Carrito" : "Caja"}</CartHeadingStyled>
      </SectionHeaderStyled>
      {getTimeLeft()}
      {steps.find((st) => st.id === currentStep)?.component}
      {contextHolder}
    </Box>
  );
};

export default Payments;
