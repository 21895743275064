import styled from "styled-components";
import { CardStyled } from "../../custom/cards/card.styled";
import { Button, Constants } from "@nubeteck/components";
import pattern from "src/assets/FramePattern.png";
import { Tabs } from "antd";

const { device, maxDevice } = Constants;

export const CardCalendarSubjectsStyled = styled(CardStyled)`
  background-color: #f9fbff;

  & > .ant-card-body {
    padding: 0px 24px 0px 0px;
    & > div {
      padding: 0px;
      display: flex;
      gap: 35px;
      justify-content: left;

      max-width: 100%;
      overflow: auto;
    }
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    background: none;
    & > .ant-card-body {
      padding: 0px 0px 0px;
      & > div {
        flex-direction: column;
      }
    }
  }
`;
export const SubjectsListStyled = styled(CardStyled)`
  background-image: url(${pattern});
  border-radius: 20px;
  max-width: 280px;

  & > .ant-card-body {
    display: flex;
    justify-content: end;
    padding: 0px;
    max-height: 100%;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    filter: none;
    background-image: none;
    max-width: 100%;
    background: none;
  }
`;
export const SubjectsContainerStyled = styled(CardStyled)`
  background: white;
  width: 90%;

  .ant-card-body {
    padding: 15px 5px 15px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 140px;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    width: 100%;
    background: none;
    filter: none;
    .ant-card-body {
      overflow-x: auto;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`;
export const SubjectButtonStyled = styled(Button)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  white-space: normal;
  padding: 5px;
  text-align: left;

  &.is-selected {
    background: #dcecff;
  }

  span.cost-container {
    font-size: 12px;
    position: absolute;
    bottom: 3px;
    right: -5px;
  }

  hr {
    width: 100%;
    position: relative;
    bottom: -12px;
    border: 0.01px solid #c4c4c7;
  }

  @media ${device.mobileS} and ${maxDevice.laptop} {
    min-width: 90%;
    max-width: 90%;
    border-radius: 10px;
    box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.18);
    background-color: white;

    &.is-selected {
      border: 1.5px solid ${({ theme }) => theme.colors.primary500};
    }
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    max-width: 30%;
    min-width: 30%;
  }
`;

export const TabContainerStyled = styled(Tabs)`
  margin: 20px 0px;
  .ant-tabs-content-holder {
    postion: relative;
    min-height: 400px;
    max-height: 400px;
    overflow: auto;
    @media ${device.laptop} {
      min-width: 470px;
    }
  }
`;

export const ErrorTableMessage = styled.div`
  color: red;
  font-size: 11px;
  position: relative;
  margin-left: -22px;
  left: 23px;
`;
// Opcion boton grande
export const AddSubjectButtonStyled = styled(Button)`
  height: 100%;
  min-height: 100px;
  // width: 100%;
  width: 90px;
  border-width: 2px;
  border-radius: 15px;
  text-align: center;
  padding: 0;

  & > span > div {
    white-space: normal;
  }

  @media ${device.mobileS} and ${maxDevice.tablet} {
    min-height: auto;
    width: 30px;
    border: none;
    border-radius: 7px;
    text-align: center;
    padding: 0;
  }
`;
export const AddSubjectButtonIconStyled = styled.span`
  color: ${({ theme }) => theme.colors.primary600};
  position: absolute;
  bottom: 7px;
  right: 7px;
  width: 20px;
  height: 20px;
  @media ${device.mobileS} and ${maxDevice.tablet} {
    color: white;
    bottom: 40%;
  }
`;
export const SubjectButtonStatusStyled = styled.span`
  font-size: 11px;
`;
