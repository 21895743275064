import { Box } from "@nubeteck/components";
import React from "react";

interface IScrollView {
  children: JSX.Element;
  height: string;
  style?: React.CSSProperties | undefined;
}
const ScrollView = ({ children, height, style }: IScrollView) => {
  return (
    <Box
      className="scroll-view"
      style={{
        maxHeight: `${height}`,
        overflowY: "auto",
        width: "fit-content",
        padding: "0 10px 10px 0",
        boxSizing: "border-box",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default ScrollView;
