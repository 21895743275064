import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Button, Flex } from "@nubeteck/components";
import { Card, DatePicker, Form, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";

function CoursesProjectionsImport({
  isLoading,
  getData,
  data,
  periodosOptions,
  getExcel,
  isLoadingExcel,
}: any) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [periodoId, setPeriodoId] = React.useState<number>(0);
  const [anio, setAnio] = React.useState<number>();

  useEffect(() => {
    getData({
      rowsCount,
      page: pageNumber,
      periodoId,
      procesoId: 0,
      anio,
    });
  }, [rowsCount, pageNumber, getData, periodoId, anio]);

  const columns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        title: "Año",
        dataIndex: "anio",
      },
      {
        title: "Periodo",
        dataIndex: "periodoNombre",
      },
      {
        title: "Cantidad de estudiantes",
        dataIndex: "cantidadEstudiantes",
      },
    ],
    [],
  );

  return (
    <>
      <MediumLargeHeadingStyled>Proyecciones de cursos importadas</MediumLargeHeadingStyled>
      <Flex style={{ gap: "15px", justifyContent: "space-between" }}>
        <Flex style={{ gap: "15px" }}>
          <Form.Item label="Filtro por periodo">
            <Select
              options={periodosOptions}
              placeholder="Seleccione un periodo"
              allowClear
              onChange={(e) => {
                setPeriodoId(e);
              }}
            />
          </Form.Item>
          <Form.Item label="Filtro por año">
            <DatePicker
              picker="year"
              onChange={(e) => {
                setAnio(e?.toDate().getFullYear());
              }}
              placeholder="Año"
            />
          </Form.Item>
        </Flex>
        <Button
          type="primary"
          onClick={() => getExcel(anio ?? 0, periodoId ?? 0)}
          loading={isLoadingExcel}
        >{`Exportar a excel ${
          anio && periodoId > 0
            ? "(año y periodo seleccionado)"
            : anio
            ? "(año seleccionado)"
            : periodoId > 0
            ? "(periodo seleccionado)"
            : ""
        }`}</Button>
      </Flex>
      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<any>
          loading={isLoading}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.asignaturaId}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            current: pageNumber,
            total: data?.data?.pageCount * rowsCount,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
}

export default CoursesProjectionsImport;
