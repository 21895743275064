import React from "react";
import { UserInfoCard } from "src/components//reports/cards";
import { CardStyled, CardsGroupContainer } from "./card.styled";
import { Descriptions, DescriptionsProps } from "antd";
import { CardTitle, CardText } from "../../../components/reports/cards/report-cards.styled";
import { useMediaQuery } from "src/hooks";
import { useSelector } from "react-redux";
import { RootState } from "src/core/store/store";

const CardsGroup = ({
  extraContent,
  userInfoCustom,
}: {
  extraContent: React.ReactNode;
  userInfoCustom?: any;
}) => {
  const windowWidth = useMediaQuery("(min-width: 480px)");
  const { userInfo } = useSelector((state: RootState) => state.user);

  const mobileItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: <CardTitle>ID</CardTitle>,
      children: <CardText>{userInfoCustom ? userInfoCustom?.id : userInfo?.id}</CardText>,
    },
    {
      key: "2",
      label: <CardTitle>Nombre</CardTitle>,
      children: (
        <CardText>{userInfoCustom ? userInfoCustom?.fullName : userInfo?.fullName}</CardText>
      ),
    },
  ];

  return (
    <>
      {windowWidth ? (
        <CardsGroupContainer>
          <UserInfoCard
            nombre={`${userInfoCustom ? userInfoCustom?.fullName : userInfo?.fullName}`}
            id={userInfoCustom ? userInfoCustom?.id : userInfo?.id}
            extraContent={extraContent}
          />
        </CardsGroupContainer>
      ) : (
        <>
          {extraContent}
          <CardStyled>
            <Descriptions layout="vertical" size="middle" items={mobileItems} />
          </CardStyled>
        </>
      )}
    </>
  );
};

export default CardsGroup;
