import { COLORS } from "src/constants";

export interface ColorScheme {
  background: string;
  foreground: string;
}

export interface ColorPalette {
  [key: string]: ColorScheme;
}

export interface Colors {
  calendar: ColorPalette;
  event: ColorPalette;
}

export type ColorType = keyof Colors;

export const getCalendarBackgroundHexCode = (id: string, type: ColorType): string | undefined => {
  const colorType = COLORS[type] as ColorPalette;

  if (colorType && Object.prototype.hasOwnProperty.call(colorType, id)) {
    return colorType[id].background;
  }

  return "#C9E1FF";
};

export default getCalendarBackgroundHexCode;
