import { getCalendarBackgroundHexCode } from "src/utils";
import { IGoogleCalendarCurrent, IGoogleCalendarIncomming } from "src/Interfaces/google-calendar";
import dayjs from "dayjs";

interface Model<I, C> {
  to: (data: C) => I | null;
  from?: (data: I) => C | null | Promise<C>;
}

const ModelGoogleCalendar: Model<IGoogleCalendarIncomming, IGoogleCalendarCurrent> = {
  from: (data) => {
    if (!data) return null;

    return {
      title: data.summary,
      key: data.id,
      start: dayjs(data.start.dateTimeRaw).toDate(),
      end: dayjs(data.end.dateTimeRaw).toDate(),
      isGoogleCalendar: true,
      backgroundColor: `${getCalendarBackgroundHexCode(`${data.colorId}`, "event")}`,
      color:
        getCalendarBackgroundHexCode(`${data.colorId}`, "event") !== "#C9E1FF" ? "#fff" : "#00479B",
    };
  },
  to: (data) => {
    if (!data) return null;

    return null;
  },
};

export default ModelGoogleCalendar;
