// import axios from "axios";

// const REACT_APP_BASEURL: string = process.env.REACT_APP_BASEURL as string;
// if (!REACT_APP_BASEURL) {
//   throw new Error("Please add a React App BaseUrl to .env");
// }

// export const verifyTokenExpiration: (token: string | null) => boolean | undefined = (token) => {
//   const refreshTokenNotNull = localStorage.getItem("refreshToken");

//   if (!refreshTokenNotNull || !token) {
//     return false;
//   }

//   const refreshToken = JSON.parse(refreshTokenNotNull);
//   const todayDate = Math.floor(new Date().getTime() / 1000.0);
//   const decodedToken = JSON.parse(decodeJwt(token));
//   const expTime = decodedToken.exp;

//   if (expTime - todayDate < 60) {
//     const data = { refreshToken: refreshToken.Token };
//     axios
//       .post(`${REACT_APP_BASEURL}/Refresh`, data, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           localStorage.setItem("token", res.data.token);
//           return true;
//         } else {
//           // log out
//           return false;
//         }
//       })
//       .catch(() => {
//         return false;
//       });
//   } else {
//     return true;
//   }
// };

export const decodeJwt: (token: string) => string = (token) => {
  if (!token) return "";
  return decodeURIComponent(
    window
      .atob(token.split(".")[1].replace("-", "+").replace("_", "/"))
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );
};

export const getRolesByToken: (token: string) => string[] = (token) => {
  if (!token) return [];
  const decode = token ? JSON.parse(decodeJwt(token)) : "";
  const roles: string[] = decode
    ? Array.isArray(decode.roles)
      ? decode.roles
      : [decode.roles]
    : [];
  return roles;
};

export const getRolesByTokenImpersonated: (token: string) => string[] = (token) => {
  const adminCredentials = getIsUserImpersonated(token);
  const roles = getRolesByToken(adminCredentials);
  return roles;
};
export const getIsUserImpersonated: (token: string) => any = (token) => {
  if (!token) return null;
  else {
    const decode = JSON.parse(decodeJwt(token));
    return decode?.adminCredentials;
  }
};
export const getUserNameByToken: (token: string) => string = (token) => {
  if (!token) return "";
  const decode = token ? JSON.parse(decodeJwt(token)) : "";
  const username: string = decode ? decode.username : "";
  return username;
};
