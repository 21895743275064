import styled from "styled-components";
import { Flex } from "@nubeteck/components";
import { device } from "@nubeteck/components/build/utils/constansts";

export const RegistrationContainer = styled(Flex)`
  flex-direction: column;
  max-width: 95%;
  @media ${device.laptop} {
    max-width: 1440px;
  }
`;
export const BlockingContainer = styled(Flex)`
  flex-direction: column;
  gap: 3px;
  p {
    margin: 0;
  }
  margin: 10px 0px;
`;
