import {
  LargeHeadingStyled,
  MediumLargeHeadingStyled,
} from "src/components/custom/texts/texts.styled";
import { ErrorSVG } from "@nubeteck/components";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
function NotFound() {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
        gap: "15px",
      }}
    >
      <ErrorSVG width={250} />
      <LargeHeadingStyled>Ha habido un error procesando el pago</LargeHeadingStyled>
      <MediumLargeHeadingStyled style={{ maxWidth: 750, textAlign: "center", color: "#da0000" }}>
        <strong>{params.error?.replaceAll("-", " ")}</strong>
      </MediumLargeHeadingStyled>
      <Button onClick={() => navigate(-1)}>Volver atrás</Button>
    </div>
  );
}

export default NotFound;
