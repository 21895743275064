import styled from "styled-components";
import { Box, Constants } from "@nubeteck/components";

const { device, maxDevice } = Constants;

export const BoxStyled = styled(Box)`
  max-width: 25vw;
  width: 25vw;
  ul {
    list-style: none;
    padding: 0;
    height: 100%;
  }
  @media ${device.mobileS} and ${maxDevice.laptop} {
    max-width: 100%;
    width: 100%;
    height: 50%;
    background: white;
    overflow-y: auto;
    padding: 17px;
    box-sizing: border-box;
  }
`;
