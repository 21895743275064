import { RcFile } from "antd/es/upload";

export function getFormData(object: any) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
}

export const getBase64 = async (file: RcFile, callback: (url: string) => void) => {
  await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => callback(resolve(reader.result) as unknown as string);
    reader.readAsDataURL(file);
  });
};
