import styled from "styled-components";
import { Constants } from "@nubeteck/components";
// import { devices } from "src/constants";
const { device, maxDevice } = Constants;

export const SubjectDescriptionStyled = styled.p`
  max-width: 70%;
`;
export const TableContainerStyled = styled.div`
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
  width: 820px;

  & .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.colors.colorTableHeader};
  }

  table tbody.ant-table-tbody {
    background-color: white;
  }
`;
export const CalendarContainerStyled = styled.div`
  .rbc-calendar {
    width: 710px;
    background-color: white;
    .rbc-header {
      background-color: ${({ theme }) => theme.colors.colorTableHeader};
    }

    .rbc-day-slot {
      min-width: 110px;
      background: white;
    }

    .rbc-event-label {
      display: none;
    }

    .rbc-time-header.rbc-overflowing,
    .rbc-time-content,
    .rbc-time-view {
      width: 1030px;
    }

    .rbc-time-header-gutter {
      background-color: ${({ theme }) => theme.colors.colorTableHeader};
    }
    .rbc-time-column.rbc-time-gutter {
      background-color: ${({ theme }) => theme.colors.colorTableHeader};
    }
    .rbc-events-container .rbc-event {
      width: 120px;
      //background: white;
      background: transparent;
      border: none;
    }
  }

  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  table tbody.ant-table-tbody {
    background-color: white;
  }

  & table {
    background-color: white;
    thead {
      background-color: ${({ theme }) => theme.colors.colorTableHeader};
      height: 40px;
      th {
        outline: 1px solid lightgrey;
      }
    }
    td:nth-child(1) {
      background-color: ${({ theme }) => theme.colors.colorTableHeader};
      min-width: 85px;
    }
    & td {
      min-width: 108px;
      max-width: 108px;
      outline: 1px solid lightgrey;
      padding: 7px;
      box-sizing: border-box;
      height: 40px;
      background-color: white;
      text-align: center;
    }
  }

  & .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.colors.colorTableHeader};
  }

  @media ${device.mobileS} and ${maxDevice.tablet} {
    & table {
      td:nth-child(1) {
        background-color: ${({ theme }) => theme.colors.colorTableHeader};
        min-width: 55px;
      }
      & td {
        min-width: 35px;
        max-width: 35px;
      }
    }
  }
  @media ${device.tablet} and ${maxDevice.laptop} {
    & table {
      td:nth-child(1) {
        min-width: 100%;
      }
      & td {
        min-width: 89px;
        max-width: 100%;
      }
    }
  }
`;
