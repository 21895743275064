import { api } from "./apiBaseQuery";

export const cartServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getCart: builder.mutation({
      query: (params) => {
        return {
          url: `Caja?esPagoCuotas=${
            params.materiasCuotas !== null && params.materiasCuotas?.length > 0
          }`,
          method: "POST",
          body: { matriculasParaCuotas: params.materiasCuotas },
        };
      },
    }),
    getCartTerms: builder.mutation({
      query: () => {
        return {
          url: `Caja/TerminosYCondiciones`,
          method: "GET",
          responseHandler: (res: { text: () => any }) => res.text(),
        };
      },
    }),
    saveBill: builder.mutation({
      query: (body) => {
        return {
          url: `Caja/RealizarPago`,
          method: "POST",
          body,
        };
      },
    }),
    validateInvoicePersonData: builder.mutation({
      query: (cedula) => {
        return {
          url: `Caja/Validar/EstudianteTercero/${cedula}`,
          method: "GET",
        };
      },
    }),
    saveInvoicePersonData: builder.mutation({
      query: (body) => {
        return {
          url: `Caja/EstudianteTercero`,
          method: "POST",
          body,
        };
      },
    }),
    getBillPdf: builder.mutation({
      query: (params) => {
        let url;
        if (params.people == "null" || params.people == 0) {
          url = `Caja/PagoPDF?pagoId=${params.id}`;
        } else {
          url = `Caja/PagoPDF?pagoId=${params.id}&peopleId=${params.people}`;
        }
        return {
          url,
          method: "GET",
          responseHandler: "text",
        };
      },
    }),
    deleteCartItem: builder.mutation({
      query: (body) => {
        return {
          url: `/Caja/EliminarItem`,
          method: "POST",
          body,
        };
      },
    }),
    getPaymentInfo: builder.mutation({
      query: (body) => {
        return {
          url: `/Caja/VPOS2/?people=${body.people}&moneda=${body.moneda}&monto=${body.monto}`,
          method: "POST",
        };
      },
    }),
    getIsThereAPaymentInProcess: builder.mutation({
      query: (id: number | undefined) => {
        let url = "";
        if (id) url = `/Caja/TienePagoPendiente?id=${id}`;
        else url = `/Caja/TienePagoPendiente`;
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useSaveBillMutation,
  useGetCartMutation,
  useGetCartTermsMutation,
  useDeleteCartItemMutation,
  useGetPaymentInfoMutation,
  useGetBillPdfMutation,
  useSaveInvoicePersonDataMutation,
  useValidateInvoicePersonDataMutation,
  useGetIsThereAPaymentInProcessMutation,
} = cartServices;
