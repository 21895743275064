import { Card, Image } from "antd";
import React from "react";
import { IntroductionContainer } from "../register-introduction/register-introduction.styled";
import { Box } from "@nubeteck/components";
import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { RegisterSVG } from "src/assets";

function NoSubjectsText() {
  return (
    <Card>
      <IntroductionContainer>
        <Box>
          <LargeHeadingStyled>
            Usted no tiene materias pendientes para matricular.
          </LargeHeadingStyled>
        </Box>
        <Image src={RegisterSVG} width={285} alt="Ilustración registro" preview={false} />
      </IntroductionContainer>
    </Card>
  );
}

export default NoSubjectsText;
