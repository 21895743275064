import styled from "styled-components";
import { Constants } from "@nubeteck/components";

const { device, maxDevice } = Constants;

export const MainContainer = styled.main`
  background: ${({ theme }) => theme.colors.colorBgBase};
  display: flex;
  justify-content: center;
  padding: 115px 85px 35px;
  min-height: 95vh;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.primary300};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary500};
    border-radius: 10px;
  }

  @media ${maxDevice.mobileL} {
    padding: 90px 20px 30px;
  }

  @media ${device.mobileL && maxDevice.laptop} {
    padding: 145px 7% 40px;
  }

  @media ${device.laptop} {
    padding-left: 115px;
    padding-right: 115px;
  }
`;
