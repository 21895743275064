import { Flex } from "@nubeteck/components";
import React, { useEffect } from "react";
import Transfers from "./transfers";
import {
  useGetTransferEndorsmentsMutation,
  useUpdateTransferEndorsmentMutation,
} from "src/services/reviewsServices";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import useDownloadFile from "src/hooks/useDownloadFile";

const Reviews: React.FC = () => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [getTransfers, { isLoading: isLoadingTransfers, data: dataTransfers }] =
    useGetTransferEndorsmentsMutation();
  const [
    updateTransfer,
    {
      isLoading: isLoadingUpdateTransfer,
      error: errorUpdateTransfer,
      isSuccess: isSuccessUpdateTransfer,
    },
  ] = useUpdateTransferEndorsmentMutation();
  const { downloadFile, loadingDownload } = useDownloadFile();

  const getFile = (name: string, fileId: number) => {
    downloadFile(`${process.env.REACT_APP_BASEURL}/Files/${fileId}`, name, "GET");
  };

  useEffect(() => {
    if (errorUpdateTransfer) {
      toast.error(errorUpdateTransfer.data?.message, toastErrorStyle);
    }
    if (isSuccessUpdateTransfer) {
      toast.success("Estado de transferencia cambiado", toastSuccessStyle);
      getTransfers({ rowsCount, pageCount: pageNumber, nombre: "" });
    }
  }, [isSuccessUpdateTransfer, errorUpdateTransfer, pageNumber, rowsCount, getTransfers]);

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Transfers
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setRowsCount={setRowsCount}
        rowsCount={rowsCount}
        isLoading={isLoadingTransfers || isLoadingUpdateTransfer}
        getData={getTransfers}
        data={dataTransfers}
        getFile={getFile}
        isLoadingFile={loadingDownload}
        changeState={updateTransfer}
      />
    </Flex>
  );
};

export default Reviews;
