import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Button, Flex, Input } from "@nubeteck/components";
import { Card, DatePicker, Form, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";

function ProjectionsImport({
  isLoading,
  getData,
  data,
  periodosOptions,
  getExcel,
  isLoadingExcel,
}: any) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [studentName, setStudentName] = React.useState("");
  const [periodoId, setPeriodoId] = React.useState<number>(0);
  const [anio, setAnio] = React.useState<number>();

  useEffect(() => {
    getData({
      rowsCount,
      page: pageNumber,
      estudianteNombre: studentName,
      periodoId,
      procesoId: 0,
      anio,
    });
  }, [studentName, rowsCount, pageNumber, getData, periodoId, anio]);

  const columns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
      },
      {
        title: "Periodo",
        dataIndex: "periodoNombre",
      },
    ],
    [],
  );

  return (
    <>
      <MediumLargeHeadingStyled>Proyecciones de estudiantes importadas</MediumLargeHeadingStyled>
      <Flex style={{ gap: "15px", alignItems: "center" }}>
        <Form.Item label="Filtro por año">
          <DatePicker
            picker="year"
            onChange={(e) => {
              setAnio(e?.toDate().getFullYear());
            }}
            placeholder="Año"
          />
        </Form.Item>
        <Form.Item label="Filtro por periodo">
          <Select
            options={periodosOptions}
            placeholder="Seleccione un periodo"
            onChange={(e) => {
              setPeriodoId(e);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Filtro por nombre">
          <Input
            type="search"
            onChange={(e) => setStudentName(e.target.value)}
            placeholder="Búsqueda por nombre de estudiante"
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={() => getExcel(anio ?? 0, periodoId ?? 0)}
          loading={isLoadingExcel}
        >{`Exportar a excel ${
          anio && periodoId > 0
            ? "(año y periodo seleccionado)"
            : anio
            ? "(año seleccionado)"
            : periodoId > 0
            ? "(periodo seleccionado)"
            : ""
        }`}</Button>
      </Flex>
      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<any>
          loading={isLoading}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.estudianteId}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            current: pageNumber,
            total: data?.data?.pageCount * rowsCount,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
}

export default ProjectionsImport;
