import { Segmented } from "antd";
import styled from "styled-components";
import { Flex, Constants, InputSearch } from "@nubeteck/components";

const maxMobileL = Constants.maxDevice.mobileL;

export const FiltersContainer = styled(Flex)`
  flex: 1;
  justify-content: space-between;

  @media only screen and (${maxMobileL}) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const SegmentedStyled = styled(Segmented)`
  width: 50%;
  overflow: "auto";
  @media only screen and (${maxMobileL}) {
    width: 100%;
  }
`;

export const InputSearchStyled = styled(InputSearch)`
  width: 40%;

  @media only screen and (${maxMobileL}) {
    width: 100%;
  }
`;
