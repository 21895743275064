import React from "react";
import { Steps } from "@nubeteck/components";
import { StepsContainerStyled, StepsTimerContainerStyled } from "./steps.styled";

interface IStepProps {
  steps: { title: string }[];
  currentStep: number;
  getExternalTimer: () => JSX.Element;
}

const RegisterSteps = ({ steps, currentStep, getExternalTimer }: IStepProps) => {
  return (
    <StepsTimerContainerStyled>
      <StepsContainerStyled>
        <Steps size="small" current={currentStep} items={steps} />
      </StepsContainerStyled>
      {getExternalTimer()}
    </StepsTimerContainerStyled>
  );
};

export default RegisterSteps;
