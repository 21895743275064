import React, { useEffect } from "react";
import { Image } from "@nubeteck/components";
import LogoCenfotec from "src/assets/LogoCenfotec.png";
import LoginImg from "src/assets/LoginImg.svg";
import {
  FlexContainerStyled,
  ImageContainerStyled,
  FormSection,
  SubHeadingStyled,
  Frame,
  LoginButtonContainer,
} from "./login.styled";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useLoginUserMutation } from "src/services";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { toastErrorStyle } from "src/constants";
import { Button } from "@nubeteck/components";

const Login = () => {
  const [loginUser, { data, isLoading, isSuccess, error }] = useLoginUserMutation();

  const navigate = useNavigate();
  const handleCallbackResponse = async (response: CredentialResponse) => {
    loginUser({ credential: response.credential });
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", JSON.stringify(data.data.refreshToken));
      navigate("/");
    }
    if (error) {
      if ("status" in error) {
        const errStatus = "error" in error ? error.status : error.status;
        if (errStatus == 401) toast.error("Usuario no autorizado.", toastErrorStyle);
        else
          toast.error("Ha ocurrido un error iniciando sesión, Intente de nuevo.", toastErrorStyle);
      }
    }
  }, [isSuccess, data, navigate, error]);

  return (
    <Spin spinning={isLoading} size="large">
      <FlexContainerStyled style={{ justifyContent: "space-between" }}>
        <Frame>
          <FormSection>
            <Image src={LogoCenfotec} width={145} preview={false} />
            <SubHeadingStyled>Autoservicio estudiantes</SubHeadingStyled>
            <LoginButtonContainer>
              <SubHeadingStyled>Inicio de sesión</SubHeadingStyled>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleCallbackResponse(credentialResponse);
                }}
                onError={() => {
                  toast.error("Ha ocurrido un error", toastErrorStyle);
                }}
              />
              <Button type="link" onClick={() => navigate("/loginAdm")}>
                Iniciar sesión como administrador
              </Button>
            </LoginButtonContainer>
          </FormSection>
        </Frame>

        <ImageContainerStyled>
          <Image src={LoginImg} width={250} preview={false} />
        </ImageContainerStyled>
      </FlexContainerStyled>
    </Spin>
  );
};

export default Login;
