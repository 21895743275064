import { useGetSponsorshipDetailMutation } from "src/services/sponsorshipsServices";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Card, Flex } from "@nubeteck/components";
import { Layout, Table, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { IPatrociniosCambio } from "src/Interfaces/sponsorship";
import { useNavigate, useParams } from "react-router-dom";

const { Content } = Layout;

const SponsorshipDetail: React.FC = () => {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);

  const [getSponsorshipDetail, { data, isLoading }] = useGetSponsorshipDetailMutation();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getSponsorshipDetail(id);
  }, [getSponsorshipDetail, id]);

  const columns = React.useMemo<ColumnsType<IPatrociniosCambio>>(
    () => [
      {
        title: "Monto original",
        dataIndex: "montoOriginal",
        width: 150,
      },
      {
        title: "Fecha de uso",
        dataIndex: "fechaUso",
        width: 150,
      },
      {
        title: "Monto utilizado",
        dataIndex: "montoUsado",
        width: 150,
      },
      {
        title: "Monto total utilizado",
        dataIndex: "montoTotalUtilizado",
        width: 120,
      },
      {
        title: "Monto restante",
        dataIndex: "montoRestanteTotal",
        width: 120,
      },
    ],
    [],
  );
  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>
          Detalle de patrocinio {data?.data?.patrocinioNombre} para el estudiante{" "}
          {data?.data?.estudianteNombre}
        </LargeHeadingStyled>
        <Button title="" type="default" onClick={() => navigate("/admin/sponsorships")}>
          Volver atrás
        </Button>
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IPatrociniosCambio>
            loading={isLoading}
            columns={columns}
            dataSource={data?.data?.detalle}
            rowKey={(v) => `${v.estudiantePatrocinioId} ${v.patrocinioNombre}`}
            pagination={{
              onChange(page, pageSize) {
                setPageNumber(page);
                setRowsCount(pageSize);
              },
              pageSize: rowsCount,
              current: pageNumber,
              total: data?.data?.pageCount * rowsCount,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 300 }}
          />
        </Card>
      </Content>
    </Flex>
  );
};

export default SponsorshipDetail;
