import styled from "styled-components";
import { Layout } from "antd";
import { Button, Image, Text, Constants, Box } from "@nubeteck/components";

const { maxDevice } = Constants;
const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  height: 60px;
  position: fixed;
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.colorAccent};
  font-weight: 600;
  top: 0;
  right: 0;
  left: 0;
  z-index: 15;

  @media ${maxDevice.tablet} {
    height: 80px;
    color: ${({ theme }) => theme.colors.colorAccent};
    justify-content: space-between;
  }

  @media (max-width: 1180px) {
    height: 80px;
    padding-inline: 24px;
    justify-content: space-between;
  }
`;

export const HeaderText = styled(Text)`
  color: #fff;
  white-space: nowrap;
  @media (max-width: 1180px) {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
`;

export const HomeLogo = styled(Image)`
  margin-bottom: 5px;

  @media ${maxDevice.tablet} {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1180px) {
    min-width: 75px !important;
    display: flex;
    align-items: center;
  }
`;

export const MenuStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40%;
  left: 30.5%;
  margin: 0;
  top: 0;
  font-size: 14px;
  gap: 6.5px;
  padding: 0;
  li {
    list-style-type: none;
    min-width: fit-content;
    a {
      padding: 3px;
      color: #fff;
      &.is-active {
        border-bottom: 1px solid #fff;
      }
    }
  }

  @media ${maxDevice.tablet} {
    display: none;
  }

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const UserSectionStyled = styled(Box)`
  position: absolute;
  right: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 60px;
  padding-right: 50px;

  @media (max-width: 1180px) {
    display: none;
  }
`;

export const MobileMenuButtonStyled = styled(Button)`
  height: 100%;
  display: none;
  background: none;
  color: white;
  padding: 13px 40px;
  height: 50px;
  gap: 12px;
  border: none;
  span {
    color: #fff;
    font-size: 20px;
  }
  .ant-btn-icon span {
    font-size: 25px;
  }

  @media (max-width: 1180px) {
    display: flex;
    align-items: center;
  }
`;
