import React from "react";
import { Icon, Image, ProgressBar } from "@nubeteck/components";
import { SmallCardsGroup } from "../../custom/cards";
import { ProgramSVG } from "../../../assets";
// import { useAppSelector } from "src/core";
import { FormatterUtils } from "@nubeteck/utils";

interface IUserProgram {
  programaNombre: string;
  porcentajeProgreso: number;
  periodo: string;
  materiasSeleccionadas: number;
  totalFacturaMaterias: number;
  monedaSimbolo: string;
}

const UserProgramCards = ({
  userInfo,
  setSelectedSubjectsVisible,
  isLoading,
}: {
  userInfo: IUserProgram;
  setSelectedSubjectsVisible: () => void;
  isLoading: boolean;
}) => {
  // const { quantity, total } = useAppSelector((state) => state.subjectSelection);
  const userData = [
    {
      title: "Programa",
      icon: <Image src={ProgramSVG} alt="ícono de programa" width={45} preview={false} />,
      information: isLoading ? "...cargando" : userInfo?.programaNombre ?? "Sin datos",
    },
    {
      title: "Avance",
      information: isLoading ? (
        "...cargando"
      ) : (
        <ProgressBar percent={userInfo?.porcentajeProgreso} size="small" />
      ),
    },
    {
      title: "Cant. seleccionadas",
      icon: <Icon name="" color="#EBEBEB" />,
      information: isLoading ? (
        "...cargando"
      ) : userInfo?.materiasSeleccionadas !== undefined ? (
        <span>
          <span
            style={{
              fontSize: "11.5px",
              whiteSpace: "nowrap",
              position: "relative",
              top: "-3px",
              left: "-5px",
              color: "gray",
              cursor: "pointer",
            }}
          >
            Ver materias
          </span>
          {userInfo?.materiasSeleccionadas.toString()}
        </span>
      ) : (
        "Sin datos"
      ),
      onClick: setSelectedSubjectsVisible,
    },
    {
      title: "Total",
      icon: <Icon name="" color="#EBEBEB" />,
      information: `${
        userInfo?.totalFacturaMaterias !== undefined
          ? `${userInfo?.monedaSimbolo}  ${FormatterUtils.number(
              userInfo?.totalFacturaMaterias.toFixed(2),
            )}`
          : isLoading
          ? "...cargando"
          : "Sin datos"
      }`,
    },
    {
      title: "Periodo",
      icon: <Icon name="" color="#EBEBEB" />,
      information: isLoading ? "...cargando" : userInfo?.periodo ?? "Sin datos",
    },
  ];

  return <SmallCardsGroup cards={userData} />;
};

export default UserProgramCards;
