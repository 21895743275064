import { Icon } from "@nubeteck/components";
import { Modal } from "antd";
import { valueType } from "antd/es/statistic/utils";
import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";
import { Timer } from "src/components";
import {
  useActivateSelectionTimeLeftMutation,
  useExtendSelectionTimeLeftMutation,
  useGetExtraSelectionTimeMutation,
  useExtendSelectionTimeLeftALittleMutation,
  useGetSelectionTimeLeftMutation,
  useGetSelectionTimeSettingsMutation,
} from "src/services";

function useManageTimer(isOff?: boolean, isNew?: boolean, recall?: boolean) {
  const [
    startTimeLeft,
    { isLoading: isSavingTimer, isSuccess: isTimeSaved, isError: isErrorTimeSaved },
  ] = useActivateSelectionTimeLeftMutation();
  const [
    extendTimeLeft,
    { isSuccess: isTimeExtended, isError: isErrorTimeExtended, reset: resetLargeExtend },
  ] = useExtendSelectionTimeLeftMutation();
  const [
    extendALittle,
    { isSuccess: isExtendedALittle, isError: isErrorExtendALittle, reset: resetShortExtend },
  ] = useExtendSelectionTimeLeftALittleMutation();

  const [timeLeft, setTimeLeft] = React.useState(0);
  const [isEnding, setIsEnding] = React.useState(false);
  const [isonHours, setIsOnHours] = React.useState(false);

  const [getTimeLeft, { data: timer, isError: isErrorTimer, isSuccess: isSuccessTimer }] =
    useGetSelectionTimeLeftMutation();
  const [getTimeSettings, { data: timeSettings }] = useGetSelectionTimeSettingsMutation();

  React.useEffect(() => {
    getTimeSettings();
  }, [getTimeSettings]);

  React.useEffect(() => {
    if (isTimeSaved) getTimeLeft();
  }, [getTimeLeft, isTimeSaved, isExtendedALittle, isTimeExtended]);

  const [getExtra, { data: extraTimer, isError: isErrorExtraTimer }] =
    useGetExtraSelectionTimeMutation(isTimeExtended, {
      refetchOnMountOrArgChange: true,
      skip: isOff,
    });

  const [modal, contextHolder] = Modal.useModal();

  React.useEffect(() => {
    if (!isOff) {
      if (extraTimer?.data?.fechaFinExpiracyTimer) {
        setTimeLeft(extraTimer.data.fechaFinExpiracyTimer); //siempre esta llegando con valor
      } else if (timer?.data?.timerExpiracy) {
        setTimeLeft(timer?.data?.timerExpiracy);
      }
    }
  }, [timer?.data?.timerExpiracy, isOff, extraTimer?.data?.fechaFinExpiracyTimer, isSuccessTimer]);

  React.useEffect(() => {
    if ((isNew && !isTimeSaved) || isErrorTimeSaved || recall) {
      startTimeLeft();
    }
  }, [isErrorTimeSaved, timer, isNew, startTimeLeft, isTimeSaved, recall]);

  React.useEffect(() => {
    if (isEnding && !extraTimer?.data?.fechaFinExpiracyTimer && !timer?.data.fueExtendido) {
      modal.confirm({
        title: `Tiempo próximo a vencer`,
        icon: <Icon name="" color="#FFC53D" />,
        content: `El tiempo de selección está próximo a vencer, ¿Desea extenderlo a ${timeSettings?.data?.tiempoMinutosRelojNormalExtension} minutos?`,
        okText: "Aceptar",
        onOk: () =>
          extendALittle().then((res: any) => {
            if (res.data?.responseStatus === "Success") getTimeLeft();
          }),
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnding, extraTimer]);

  React.useEffect(() => {
    getExtra();
  }, [isTimeExtended, getExtra, recall]);

  React.useEffect(() => {
    setIsOnHours(
      extraTimer?.data?.fechaFinExpiracyTimer || dayjs(timeLeft).diff(new Date()) / 60000 > 60,
    );
  }, [timeLeft, extraTimer]);

  const extend = () => {
    extendTimeLeft();
  };

  React.useEffect(() => {
    if (isErrorTimeExtended || isErrorExtendALittle) {
      toast.error("Ha habido un error extendiendo el tiempo de selección");
      resetLargeExtend();
      resetShortExtend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorTimeExtended, isErrorExtendALittle]);

  React.useEffect(() => {
    const timerIndefinido =
      isSuccessTimer &&
      !extraTimer?.data?.fechaFinExpiracyTimer &&
      !timer?.data?.timerExpiracy &&
      timeLeft === 0;

    if (isErrorTimer || isErrorExtraTimer || timerIndefinido) {
      toast.error(
        "Ha habido un error obteniendo el tiempo de selección. Por favor recargue la página",
      );
    }
  }, [isErrorTimer, isErrorExtraTimer, extraTimer, timer, isSuccessTimer, timeLeft]);

  const getTimer = (style?: React.CSSProperties) => {
    return (
      <>
        <Timer
          style={style}
          minutes={timeLeft}
          hoursFormat={isonHours}
          verifyEnding={(seconds: valueType | undefined) => {
            setIsEnding(seconds !== undefined && +seconds / 1000 < 200);
          }}
        />
        {contextHolder}
      </>
    );
  };

  return {
    timeLeft,
    getTimer,
    startTimeLeft,
    isSavingTimer,
    isTimeSaved,
    extend,
    isonHours,
    hoursToExtend: timeSettings?.data?.tiempoHorasRelojExtension,
    selectionMinutes: timeSettings?.data?.tiempoMinutosRelojNormal,
    hoursForPresential: timeSettings?.data?.tiempoHorasPagoPresencial,
  };
}

export default useManageTimer;
