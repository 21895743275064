import { api } from "./apiBaseQuery";

export const reviewsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getIsTransferActive: builder.mutation({
      query: (body) => {
        return {
          url: `/Estudiantes/EstadoTransferencia`,
          method: "GET",
          body,
        };
      },
    }),
    saveTransferEndorsment: builder.mutation({
      query: (body) => {
        return {
          url: `/PagosTransferencias`,
          method: "POST",
          body,
        };
      },
    }),
    updateTransferStudent: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/PagosTransferencias/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    getTransferEndorsments: builder.mutation({
      query: (body) => {
        return {
          url: `/PagosTransferencias/Obtener`,
          method: "POST",
          body,
        };
      },
    }),
    getTransfersStatus: builder.query({
      query: () => {
        return {
          url: `/PagosTransferencias/Estados`,
        };
      },
    }),
    updateTransferEndorsment: builder.mutation({
      query: (body) => {
        return {
          url: `/PagosTransferencias/PagosTransferencias/Update/`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetTransferEndorsmentsMutation,
  useGetIsTransferActiveMutation,
  useGetTransfersStatusQuery,
  useSaveTransferEndorsmentMutation,
  useGetSponsorshipEndorsmentsMutation,
  useUpdateTransferEndorsmentMutation,
  useUpdateSponsorshipEndorsmentMutation,
  useUpdateTransferStudentMutation,
} = reviewsServices;
