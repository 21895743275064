import React, { useState } from "react";
import { Box, Icon } from "@nubeteck/components";
import {
  AddSubjectButtonIconStyled,
  AddSubjectButtonStyled,
  SubjectButtonStatusStyled,
} from "./register-selection.styled";
import { useMediaQuery } from "src/hooks/useMediaQuery";

interface IButtonSubject {
  handleChange: () => void;
  section?: string;
  status?: string;
  type: "selected" | "unavaliable" | "avaliable" | "collision" | "registered";
  randomColor?: string;
}

const ButtonSubject = ({
  handleChange,
  section,
  status,
  type,
  randomColor = "",
}: IButtonSubject) => {
  const matches = useMediaQuery("(max-width: 480px)");
  const [isOnHover, setIsOnHover] = useState(false);

  const getStyleByType: () => React.CSSProperties = () => {
    if ((status === "lleno" || status == "0" || status === "Indefinido") && type !== "selected")
      return { borderColor: "gray", color: "#000000d9" };
    else if (type === "selected") {
      return {
        border: "none",
        color: `${randomColor})`,
        backgroundColor: `${randomColor},0.20)`,
      };
    } else if (type === "collision") {
      return {
        border: "solid 2.5px #CE0000",
        color: `${randomColor})`,
      };
    } else if (type === "registered") {
      return {
        cursor: "default",
        borderColor: "gray",
        color: "#556b85",
        backgroundColor: `#F4F6FA`,
      };
    } else return {};
  };

  return (
    <AddSubjectButtonStyled
      onMouseOver={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
      onMouseOut={() => setIsOnHover(false)}
      type="primary"
      ghost={!matches}
      onClick={handleChange}
      style={getStyleByType()}
      disabled={
        (status === "lleno" || status == "0" || status === "Indefinido") && type !== "selected"
      }
    >
      {matches ? (
        type === "selected" ? (
          <Icon name="delete" color="red" />
        ) : (
          <Icon name="" />
        )
      ) : type === "selected" && isOnHover ? (
        <Icon name="delete" color="red" />
      ) : (
        <>
          <Box>{section}</Box>
          <SubjectButtonStatusStyled>{status}</SubjectButtonStatusStyled>
          {status !== "lleno" &&
          status != "0" &&
          status !== "Indefinido" &&
          (type === "avaliable" || type === "collision") ? (
            <AddSubjectButtonIconStyled>
              <Icon name="" color={type === "collision" ? "#CE0000" : ""} />
            </AddSubjectButtonIconStyled>
          ) : null}
        </>
      )}
    </AddSubjectButtonStyled>
  );
};

export default ButtonSubject;
