import {
  useCreateSponsorshipMutation,
  useDeleteSponsorshipMutation,
  useDeleteUnactiveSponsorshipsMutation,
  useDownloadSponsorshipsMutation,
  useDownloadSponsorshipsTemplateMutation,
  useGetStudentsSponsorshipsMutation,
  useUpdateSponsorshipsMutation,
  useUploadSponsorshipsMutation,
} from "src/services/sponsorshipsServices";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Flex,
  Form,
  IUploadProps,
  Icon,
  Select,
} from "@nubeteck/components";
import { DatePicker, Input, InputNumber, Layout, Modal, Table, Upload } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useGetAdminPeriodsWithYearQuery,
  useGetCurrenciesQuery,
  useGetSponsorshipsQuery,
  useGetStudentsMutation,
} from "src/services";
import { ICurrency, ISponsorship } from "@/Interfaces/options";
import { ArrayUtils, FormatterUtils } from "@nubeteck/utils";
import { FlexStyled } from "src/components/registration/register-introduction/register-introduction.styled";
import { getFormData } from "src/utils/files-util";
import { IPatrocinio } from "src/Interfaces/sponsorship";
import { useGetCareersWithCodeByStudentIdMutation } from "src/services/importsService";
import { useMediaQuery } from "src/hooks";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const Sponsorships: React.FC = () => {
  const [form] = Form.useForm<IPatrocinio>();

  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [studentName, setStudentName] = React.useState("");
  const [studentNameForm, setStudentNameForm] = React.useState("");
  const [sponsorshipName, setSponsorshipName] = React.useState("");
  const [filtroUsado, setFiltroUsado] = React.useState(null);
  const [startDate, setStartDate] = React.useState<string | null>(null);
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const [patrocinioId, setPatrocinioId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 1180px)");
  const [modal, contextHolder] = Modal.useModal();

  const [getCareersByStudentId, { isLoading: isLoadingCareers, data: dataCareers }] =
    useGetCareersWithCodeByStudentIdMutation();
  const { data: periodos } = useGetAdminPeriodsWithYearQuery("", {
    refetchOnMountOrArgChange: true,
  });
  const [getSponsorships, { data, isLoading }] = useGetStudentsSponsorshipsMutation();
  const [
    uploadSponsorships,
    { isSuccess: isSuccessUpload, isLoading: isLoadingUpload, error: errorUpload },
  ] = useUploadSponsorshipsMutation();
  const [
    downloadSponsorshipsTemplate,
    { isLoading: isLoadingDownloadTemplate, data: downloadTemplate },
  ] = useDownloadSponsorshipsTemplateMutation();
  const [downloadSponsorships, { isLoading: isLoadingDownload, data: download }] =
    useDownloadSponsorshipsMutation();
  const [
    updateSponsorship,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateSponsorshipsMutation();
  const [
    createSponsorship,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate },
  ] = useCreateSponsorshipMutation();
  const [
    deleteSponsorship,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, error: errorDelete },
  ] = useDeleteSponsorshipMutation();
  const [
    deleteUnactiveSponsorships,
    {
      isLoading: isLoadingDeleteUnactive,
      isSuccess: isSuccessDeleteUnactive,
      error: errorDeleteUnactive,
    },
  ] = useDeleteUnactiveSponsorshipsMutation();

  const { data: sponsorships } = useGetSponsorshipsQuery("");
  const [getStudents, { data: students, isLoading: isLoadingStudents }] = useGetStudentsMutation();
  const { data: currencies } = useGetCurrenciesQuery("");

  const periodosOptions = ArrayUtils.selectLabelValue(periodos?.data ?? [], "periodo", "periodoId");

  const carrerasOptions = ArrayUtils.selectLabelValue(dataCareers?.data ?? [], "opcion", "opcion");

  const navigate = useNavigate();
  useEffect(() => {
    getSponsorships({
      rowsCount,
      page: pageNumber,
      estudianteNombre: studentName,
      patrocinioNombre: sponsorshipName,
      usado: filtroUsado,
      fechaInicio: startDate,
      fechaFin: endDate,
    });
  }, [
    getSponsorships,
    pageNumber,
    rowsCount,
    studentName,
    endDate,
    startDate,
    sponsorshipName,
    isSuccessCreate,
    isSuccessUpload,
    isSuccessUpdate,
    isSuccessDeleteUnactive,
    isSuccessDelete,
    filtroUsado,
  ]);

  useEffect(() => {
    getStudents({
      rowsCount: 20,
      page: 1,
      estudianteNombre: studentNameForm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentNameForm]);

  useEffect(() => {
    if (errorCreate) toast.error(errorCreate?.data?.message, toastErrorStyle);
    if (isSuccessCreate) {
      toast.success("Patrocinio actualizado correctamente", toastSuccessStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate, errorCreate]);
  useEffect(() => {
    if (errorUpdate) toast.error(errorUpdate?.data?.message, toastErrorStyle);
    if (isSuccessUpdate) {
      toast.success("Patrocinio actualizado correctamente", toastSuccessStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate, errorUpdate]);
  useEffect(() => {
    if (errorDelete) toast.error(errorDelete?.data?.message, toastErrorStyle);
    if (isSuccessDelete) {
      toast.success("Patrocinio actualizado correctamente", toastSuccessStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete, errorDelete]);

  useEffect(() => {
    if (errorDeleteUnactive) toast.error(errorDeleteUnactive?.data?.message, toastErrorStyle);
    if (isSuccessDeleteUnactive) {
      toast.success("Patrocinios removidos correctamente", toastSuccessStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleteUnactive, errorDeleteUnactive]);

  useEffect(() => {
    if (errorUpload) {
      toast.error(JSON.parse(errorUpload?.data).message, toastErrorStyle);
    }
    if (isSuccessUpload) {
      toast.success("Patrocinios subidos correctamente", toastSuccessStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpload, errorUpload]);

  const descargarExcel: (fileName: string, data: string) => void = (fileName, data) => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", fileName);
    const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
    aElement.href = href;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(href);
  };

  useEffect(() => {
    if (downloadTemplate?.data) {
      descargarExcel("Plantilla patrocinios", downloadTemplate.data);
    }
  }, [downloadTemplate]);
  useEffect(() => {
    if (download?.data) {
      descargarExcel("Patrocinios registrados", download.data);
    }
  }, [download]);

  // useEffect(() => {
  //   if (isSuccessCareersByStudent) {
  //     const recordSeleccionado = data?.data?.result?.find(
  //       (sp: IPatrocinio) => sp.patrocinioId === patrocinioId,
  //     );
  //     form.setFieldValue("carreraCodigo", recordSeleccionado);
  //   }
  // }, [isSuccessCareersByStudent, patrocinioId, data?.data?.result, form]);

  const deleteSponsorships = () => {
    modal.confirm({
      title: `¿Esta seguro(a) de que desea remover los vouchers inactivos, vencidos o totalmente usados?`,
      icon: <Icon name="" color="#FFC53D" />,
      okText: "Remover vouchers",
      onOk: () => {
        deleteUnactiveSponsorships();
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const onClickEditarPatrocinio = React.useCallback(
    (record: IPatrocinio) => {
      getCareersByStudentId(record.matricula).then(() => {
        setPatrocinioId(record.estudiantePatrocinioId);
        setDrawerOpen(true);
        setStudentNameForm(record.estudianteNombre);
        form.setFieldsValue({
          monto: record.monto,
          estudianteId: record.estudianteId,
          acuerdo: record.acuerdo,
          estudiantePatrocinioId: record.estudiantePatrocinioId,
          porcentaje: record.porcentaje,
          patrocinioId: record.patrocinioId,
          monedaId: record.monedaId,
          periodoId: record.periodoId,
          carreraCodigo: record.carreraCodigo,
        });
      });
    },
    [form, getCareersByStudentId],
  );
  const onClickEstadoPatrocinio = React.useCallback(
    (id: number, estado: boolean) => {
      deleteSponsorship({ id, estado });
    },
    [deleteSponsorship],
  );

  const submit = (values: any) => {
    if (patrocinioId !== 0) {
      delete values.estudianteId;
      updateSponsorship({ ...values, id: patrocinioId });
    } else {
      createSponsorship(values);
    }
  };

  const studentsOptions = useMemo(
    () => ArrayUtils.selectLabelValue(students?.data?.result, "estudianteNombre", "estudianteId"),
    [students?.data?.result],
  );

  const columns = React.useMemo<ColumnsType<IPatrocinio>>(
    () => [
      {
        title: "ID",
        dataIndex: "matricula",
        width: 120,
        fixed: isMobile ? undefined : "left",
      },
      {
        title: "Estudiante",
        dataIndex: "estudianteNombre",
        width: 150,
      },
      {
        title: "Patrocinio",
        dataIndex: "patrocinioNombre",
        width: 150,
      },
      {
        title: "Carrera",
        dataIndex: "carreraCodigo",
        width: 150,
      },
      {
        title: "Año",
        dataIndex: "anio",
        width: 80,
      },
      {
        title: "Periodo",
        dataIndex: "periodo",
        width: 90,
      },
      {
        title: "No. de acuerdo",
        dataIndex: "acuerdo",
        width: 120,
      },
      {
        title: "Referencia",
        dataIndex: "referencia",
        width: 150,
      },
      {
        title: "Monto límite",
        dataIndex: "monto",
        render: (value) => FormatterUtils.number(value.toFixed(2)),
        width: 120,
      },
      {
        title: "Porcentaje",
        dataIndex: "porcentaje",
        render: (value) => `${value}%`,
        width: 110,
      },
      {
        title: "Moneda",
        dataIndex: "monedaNombre",
        width: 150,
      },
      {
        title: "Fecha elegibilidad",
        dataIndex: "fechaElegibilidad",
        width: 120,
      },
      {
        title: "Estado",
        dataIndex: "estaActivo",
        render: (value) => (value ? "Activo" : "Inactivo"),
        width: 100,
      },
      {
        title: "Ha sido utilizado",
        dataIndex: "usado",
        render: (value) => (value ? "Si" : "No"),
        width: 100,
      },
      {
        title: "Fecha uso",
        dataIndex: "fechaUso",
        width: 120,
      },
      {
        title: "Monto utilizado",
        dataIndex: "montoUtilizado",
        render: (value) => (value > 0 ? FormatterUtils.number(value) : 0),
        width: 100,
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            className="cursor-pointer"
            key={record.patrocinioId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                record.estaActivo && !record.usado
                  ? {
                      key: "1",
                      label: "Editar",
                      icon: <Icon name="" />,
                      onClick: () => onClickEditarPatrocinio(record ?? 0),
                    }
                  : null,
                {
                  key: "2",
                  label: record.estaActivo ? "Inactivar" : "Activar",
                  icon: record.estaActivo ? <Icon name="" /> : <Icon name="" />,
                  onClick: () =>
                    onClickEstadoPatrocinio(
                      record.estudiantePatrocinioId,
                      record.estaActivo ? false : true,
                    ),
                },
                {
                  key: "2",
                  label: "Ver detalle",
                  icon: <Icon name="" outlined />,
                  onClick: () => navigate(`/admin/sponsorships/${record.estudiantePatrocinioId}`),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEstadoPatrocinio, onClickEditarPatrocinio, isMobile, navigate],
  );

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const props: IUploadProps = {
    name: "model",
    multiple: false,
    showUploadList: false,
    maxCount: 1,
    beforeUpload: () => false,
    accept: "*",
  };

  const onChangeExcel = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result !== null)
        fetch(reader.result as string).then((res) =>
          res.blob().then((blob) => {
            uploadSponsorships(getFormData({ file: blob }));
          }),
        );
    };
    reader.readAsDataURL(file);
  };

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <LargeHeadingStyled>Patrocinios</LargeHeadingStyled>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled>
          <FlexStyled
            style={{
              flexWrap: isMobile ? "wrap" : undefined,
              // flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Button
              title=""
              type="default"
              onClick={downloadSponsorshipsTemplate}
              loading={isLoadingDownloadTemplate}
            >
              Descargar plantilla de excel
            </Button>

            <Upload
              {...props}
              disabled={isLoadingUpload}
              onChange={(info) => {
                const { status } = info.file;
                if (status !== "uploading") {
                  onChangeExcel(info.file);
                }
              }}
            >
              <Button icon={<Icon name="" size={13} color="#00479B" />}>Subir excel</Button>
            </Upload>

            <Button
              title=""
              type="default"
              onClick={deleteSponsorships}
              loading={isLoadingDeleteUnactive}
            >
              Remover vouchers vencidos/inactivos
            </Button>
            <Button
              title=""
              type="default"
              onClick={() => navigate("changes")}
              loading={isLoadingDeleteUnactive}
            >
              Ver historial de cambios
            </Button>
          </FlexStyled>
        </SectionHeaderStyled>
        <SectionHeaderStyled style={{ alignContent: "end", gap: "5px" }}>
          <Form.Item label="Búsqueda por nombre o id">
            <Input
              type="text"
              onChange={(e) => setStudentName(e.target.value)}
              placeholder="Búsqueda por nombre o id"
            />
          </Form.Item>{" "}
          <Form.Item label="Búsqueda por patrocinio">
            <Input
              type="text"
              onChange={(e) => setSponsorshipName(e.target.value)}
              placeholder="Búsqueda por patrocinio"
            />
          </Form.Item>
          <Form.Item label="Filtro por uso">
            <Select
              allowClear
              defaultValue={null}
              options={[
                {
                  label: "Mostrar todos",
                  value: null,
                },
                {
                  label: "Mostrar utilizados",
                  value: true,
                },
                {
                  label: "Mostrar no utilizados",
                  value: false,
                },
              ]}
              placeholder="Seleccione un  filtro"
              onChange={(value) => {
                setFiltroUsado(value);
              }}
            />
          </Form.Item>
          <Form.Item name="fechaInicio" label="Fecha de inicio">
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={(e) => {
                setStartDate(e ? e.toDate().toISOString() : null);
              }}
            />
          </Form.Item>
          <Form.Item name="fechaFin" label="Fecha de fin">
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={(e) => {
                setEndDate(e ? e.toDate().toISOString() : null);
                console.log(e, "e");
              }}
            />
          </Form.Item>
        </SectionHeaderStyled>
        <br />
        <SectionHeaderStyled>
          <Button
            title=""
            type="primary"
            onClick={() =>
              downloadSponsorships({
                usado: filtroUsado,
                estudianteNombre: studentName,
                patrocinioNombre: sponsorshipName,
                fechaInicio: startDate,
                fechaFin: endDate,
              })
            }
            loading={isLoadingDownload}
          >
            Exportar a excel
          </Button>
        </SectionHeaderStyled>

        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IPatrocinio>
            loading={
              isLoading ||
              isLoadingCreate ||
              isLoadingUpdate ||
              isLoadingDelete ||
              isLoadingUpload ||
              isLoadingDeleteUnactive
            }
            columns={columns}
            dataSource={data?.data?.result}
            rowKey={(v) => `${v.estudiantePatrocinioId} ${v.patrocinioNombre}`}
            pagination={{
              onChange(page, pageSize) {
                setPageNumber(page);
                setRowsCount(pageSize);
              },
              pageSize: rowsCount,
              current: pageNumber,
              total: data?.data?.pageCount * rowsCount,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 300 }}
          />
          <Drawer
            title={patrocinioId !== 0 ? "Cambiar patrocino a estudiante" : "Asignar patrocinio"}
            placement="right"
            open={drawerOpen}
            onClose={() => {
              setPatrocinioId(0);
              setDrawerOpen(false);
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              onFinish={(data) => submit(data)}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
            >
              <Form.Item
                name="estudianteId"
                label="Estudiante"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Select
                  filterOption={filterOption}
                  disabled={patrocinioId !== 0}
                  placeholder="Seleccionar estudiante"
                  optionFilterProp="children"
                  size="large"
                  showSearch
                  loading={isLoadingStudents}
                  autoClearSearchValue={false}
                  allowClear={false}
                  onSearch={(e) => setStudentNameForm(e)}
                  options={studentsOptions}
                />
              </Form.Item>
              <Form.Item
                name="carreraCodigo"
                label="Carrera"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  size="large"
                  allowClear
                  options={carrerasOptions}
                  loading={isLoadingCareers}
                  placeholder="Carrera"
                />
              </Form.Item>
              <Form.Item
                name="periodoId"
                label="Periodo"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select
                  options={periodosOptions}
                  filterOption={filterOption}
                  placeholder="Seleccione un periodo"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="patrocinioId"
                label="Patrocinio"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Select
                  allowClear
                  placeholder="Seleccionar patrocinio"
                  size="large"
                  options={sponsorships?.data?.map((pat: ISponsorship) => ({
                    label: pat.patrocinioNombre,
                    value: pat.patrocinioId,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="monedaId"
                label="Moneda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <Select
                  allowClear
                  placeholder="Seleccionar moneda"
                  size="large"
                  options={currencies?.data?.map((ent: ICurrency) => ({
                    label: ent.monedaNombre,
                    value: ent.monedaId,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="acuerdo"
                label="No. de acuerdo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                style={{ marginBottom: 4 }}
              >
                <InputNumber size="large" placeholder="No. de acuerdo" min="0" controls={false} />
              </Form.Item>
              <FlexStyled>
                <Form.Item
                  name="monto"
                  label="Monto"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  style={{ marginBottom: 4 }}
                >
                  <InputNumber
                    size="large"
                    placeholder="Monto"
                    min="0"
                    controls={false}
                    formatter={(value) => FormatterUtils.number(value)}
                  />
                </Form.Item>
                <Form.Item
                  name="porcentaje"
                  label="Porcentaje"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  style={{ marginBottom: 4 }}
                >
                  <InputNumber
                    size="large"
                    placeholder="Porcentaje"
                    min="0"
                    max="100"
                    controls={false}
                    formatter={(value) => FormatterUtils.percentage(value)}
                  />
                </Form.Item>
              </FlexStyled>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                onClick={() => setDrawerOpen(false)}
              >
                Aceptar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
      {contextHolder}
    </Flex>
  );
};

export default Sponsorships;
