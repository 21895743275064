import { themeColors } from "./core";

const breakpoints = {
  xs: "320px",
  sm: "480px",
  md: "600px",
  lg: "801px",
  xl: "1025px",
  "2xl": "1281px",
};

export const devices = {
  xs: `(max-width: ${breakpoints.sm})`,
  sm: `(min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.xl})`,
  md: `(max-width: ${breakpoints.lg})`,
  lg: `(max-width: ${breakpoints.xl})`,
  xl: `(min-width: ${breakpoints.xl})`,
  "2xl": `(min-width: ${breakpoints["2xl"]})`,
};

export const horas = [
  {
    twentyFour: "08:00",
    twelve: "08:00 AM",
  },
  {
    twentyFour: "09:00",
    twelve: "09:00 AM",
  },
  {
    twentyFour: "10:00",
    twelve: "10:00 AM",
  },
  {
    twentyFour: "11:00",
    twelve: "11:00 AM",
  },
  {
    twentyFour: "12:00",
    twelve: "12:00 PM",
  },
  {
    twentyFour: "13:00",
    twelve: "01:00 PM",
  },
  {
    twentyFour: "14:00",
    twelve: "02:00 PM",
  },
  {
    twentyFour: "15:00",
    twelve: "03:00 PM",
  },
  {
    twentyFour: "16:00",
    twelve: "04:00 PM",
  },
  {
    twentyFour: "17:00",
    twelve: "05:00 PM",
  },
  {
    twentyFour: "18:00",
    twelve: "06:00 PM",
  },
  {
    twentyFour: "19:00",
    twelve: "07:00 PM",
  },
  {
    twentyFour: "20:00",
    twelve: "08:00 PM",
  },
  {
    twentyFour: "21:00",
    twelve: "09:00 PM",
  },
  {
    twentyFour: "22:00",
    twelve: "10:00 PM",
  },
];

export const weekDays = [
  { diaId: 7, acr: "Do", name: "Domingo" },
  { diaId: 1, acr: "Lun", name: "Lunes" },
  { diaId: 2, acr: "Mar", name: "Martes" },
  { diaId: 3, acr: "Mie", name: "Miercoles" }, //falta acento en miercoles en backend
  { diaId: 4, acr: "Jue", name: "Jueves" },
  { diaId: 5, acr: "Vie", name: "Viernes" },
  { diaId: 6, acr: "Sa", name: "Sábado" },
];
// CALENDAR

export const toastSuccessStyle = {
  style: {
    padding: "16px",
    color: `${themeColors.primary500}`,
    fontSize: 15,
    fontWeight: 700,
  },
  iconTheme: {
    primary: `${themeColors.primary500}`,
    secondary: "#FFF",
  },
};

export const toastErrorStyle = {
  style: {
    padding: "16px",
    color: "#e74c3c",
    width: "500px",
    fontSize: 15,
    fontWeight: 700,
  },
  iconTheme: {
    primary: "#e74c3c",
    secondary: "#FFF",
  },
};
export const toastWarningStyle = {
  style: {
    padding: "16px",
    color: "#e0ae00",
    fontSize: 17,
    fontWeight: 700,
  },
  iconTheme: {
    primary: "#e0ae00",
    secondary: "#FFF",
  },
};

export const NUMBER = "Numero";
export const SHORT_TEXT = "Texto Corto";
export const LONG_TEXT = "Texto Largo";
export const BOOLEAN = "Booleano";
export const EMAIL = "Email";
export const OPTIONS = "Opciones";
export const MULTI_OPTIONS = "MultiOpciones";
export const EDITOR = "Editor";
export const CODE_EDITOR = "CodeEditor";
export const FECHA = "Fecha";
export const RADIO = "Radio";
export const CLAVE = "Clave";

export const COLORS = {
  calendar: {
    "1": {
      background: "#ac725e",
      foreground: "#1d1d1d",
    },
    "2": {
      background: "#d06b64",
      foreground: "#1d1d1d",
    },
    "3": {
      background: "#f83a22",
      foreground: "#1d1d1d",
    },
    "4": {
      background: "#fa573c",
      foreground: "#1d1d1d",
    },
    "5": {
      background: "#ff7537",
      foreground: "#1d1d1d",
    },
    "6": {
      background: "#ffad46",
      foreground: "#1d1d1d",
    },
    "7": {
      background: "#42d692",
      foreground: "#1d1d1d",
    },
    "8": {
      background: "#16a765",
      foreground: "#1d1d1d",
    },
    "9": {
      background: "#7bd148",
      foreground: "#1d1d1d",
    },
    "10": {
      background: "#b3dc6c",
      foreground: "#1d1d1d",
    },
    "11": {
      background: "#fbe983",
      foreground: "#1d1d1d",
    },
    "12": {
      background: "#fad165",
      foreground: "#1d1d1d",
    },
    "13": {
      background: "#92e1c0",
      foreground: "#1d1d1d",
    },
    "14": {
      background: "#9fe1e7",
      foreground: "#1d1d1d",
    },
    "15": {
      background: "#9fc6e7",
      foreground: "#1d1d1d",
    },
    "16": {
      background: "#4986e7",
      foreground: "#1d1d1d",
    },
    "17": {
      background: "#9a9cff",
      foreground: "#1d1d1d",
    },
    "18": {
      background: "#b99aff",
      foreground: "#1d1d1d",
    },
    "19": {
      background: "#c2c2c2",
      foreground: "#1d1d1d",
    },
    "20": {
      background: "#cabdbf",
      foreground: "#1d1d1d",
    },
    "21": {
      background: "#cca6ac",
      foreground: "#1d1d1d",
    },
    "22": {
      background: "#f691b2",
      foreground: "#1d1d1d",
    },
    "23": {
      background: "#cd74e6",
      foreground: "#1d1d1d",
    },
    "24": {
      background: "#a47ae2",
      foreground: "#1d1d1d",
    },
  },
  event: {
    "1": {
      background: "#a4bdfc",
      foreground: "#1d1d1d",
    },
    "2": {
      background: "#7ae7bf",
      foreground: "#1d1d1d",
    },
    "3": {
      background: "#dbadff",
      foreground: "#1d1d1d",
    },
    "4": {
      background: "#ff887c",
      foreground: "#1d1d1d",
    },
    "5": {
      background: "#fbd75b",
      foreground: "#1d1d1d",
    },
    "6": {
      background: "#ffb878",
      foreground: "#1d1d1d",
    },
    "7": {
      background: "#46d6db",
      foreground: "#1d1d1d",
    },
    "8": {
      background: "#e1e1e1",
      foreground: "#1d1d1d",
    },
    "9": {
      background: "#5484ed",
      foreground: "#1d1d1d",
    },
    "10": {
      background: "#51b749",
      foreground: "#1d1d1d",
    },
    "11": {
      background: "#dc2127",
      foreground: "#1d1d1d",
    },
  },
};
