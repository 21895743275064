import { useValidateInvoicePersonDataMutation } from "src/services/cartServices";
import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import toast from "react-hot-toast";
import { toastWarningStyle } from "src/constants";
import { Flex } from "@nubeteck/components";

interface IInvoicePersonData {
  terceroNombre: string;
  terceroCorreo: string;
  terceroCedula: string;
}

function ThirdPersonForm({
  savePerson,
  isLoading,
  open,
  onCancel,
  initialValues,
}: {
  savePerson: (values: IInvoicePersonData) => void;
  isLoading: boolean;
  open: boolean;
  onCancel: () => void;
  initialValues: IInvoicePersonData;
}) {
  const [formInvoiceData] = Form.useForm<IInvoicePersonData>();
  const { validateFields, setFieldsValue, getFieldValue } = formInvoiceData;
  const [
    validatePerson,
    {
      isLoading: isLoadingValidatePerson,
      data: personValidation,
      error: errorValidatePerson,
      isSuccess: isSuccessPersonValidation,
    },
  ] = useValidateInvoicePersonDataMutation();

  useEffect(() => {
    setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (errorValidatePerson) {
      toast.error(errorValidatePerson?.data?.message, toastWarningStyle);
      setFieldsValue({ terceroNombre: "", terceroCorreo: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorValidatePerson]);

  useEffect(() => {
    if (isSuccessPersonValidation) {
      if (personValidation?.data) {
        setFieldsValue({ terceroNombre: personValidation?.data?.legalName, terceroCorreo: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personValidation, isSuccessPersonValidation]);

  const handleOkInvoicePerson = () => {
    validateFields(["terceroNombre", "terceroCorreo", "terceroCedula"])
      .then((values) => {
        if (values) {
          savePerson(values);
        }
      })
      .catch(() => {
        toast.error(`Algunos campos necesitan ser revisados.`);
      });
  };

  return (
    <Modal
      closable={false}
      open={open}
      onOk={handleOkInvoicePerson}
      confirmLoading={isLoading}
      cancelText="Cancelar"
      okText="Guardar"
      onCancel={() => {
        onCancel();
        setFieldsValue(initialValues);
      }}
      style={{ maxWidth: 450 }}
    >
      <Form layout="vertical" form={formInvoiceData}>
        <Flex style={{ alignItems: "center", gap: 15 }}>
          <Form.Item
            name="terceroCedula"
            required
            label="Cédula"
            initialValue={initialValues?.terceroCedula}
            rules={[
              {
                required: true,
                message: "la cédula del responsable del pago es requerida.",
              },
            ]}
          >
            <Input placeholder="Cédula" size="large" />
          </Form.Item>
          <Button
            onClick={() => validatePerson(getFieldValue("terceroCedula"))}
            loading={isLoadingValidatePerson}
          >
            Validar
          </Button>
        </Flex>

        <Form.Item
          name="terceroNombre"
          label="Nombre"
          initialValue={initialValues?.terceroNombre}
          rules={[
            {
              required: true,
              message: "El nombre al que estarán registradas las facturas es requerido.",
            },
          ]}
          required
        >
          <Input placeholder="Nombre" size="large" />
        </Form.Item>

        <Form.Item
          name="terceroCorreo"
          required
          label="Correo Electrónico"
          initialValue={initialValues?.terceroCorreo}
          rules={[
            {
              required: true,
              message: "Este campo es requerido.",
            },
          ]}
        >
          <Input placeholder="Correo Electrónico" size="large" />
        </Form.Item>
        {/* <ButtonAlternativeStyled
          disabled={isLoadingSavingPerson || isLoadingValidatePerson}
          size="small"
          type="default"
          onClick={() => onCancel()}
        >
          Reestablecer a valores por defecto
        </ButtonAlternativeStyled> */}
      </Form>
    </Modal>
  );
}

export default ThirdPersonForm;
