import { useRegisterSubjectMutation, useRemoveRegisteredSubjectMutation } from "src/services";
import {
  HubConnection,
  HubConnectionBuilder,
  HttpTransportType,
  HubConnectionState,
} from "@microsoft/signalr";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { decodeJwt } from "src/utils";

function useManageSlots(cb?: any) {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sectionsSlots, setSectionsSlots] = useState<any[]>([]);
  const [isLoadingSomething, setIsLoadingSomething] = useState(false);
  const [invocationId, setInvocationId] = useState<any>();
  const [
    seleccionar,
    { isLoading: isLoadingSeleccion, isError: isErrorSeleccion, error: errorRem },
  ] = useRegisterSubjectMutation();
  const [
    deseleccionar,
    { isLoading: isLoadingDeseleccion, isError: isErrorDeseleccion, error: errorSel },
  ] = useRemoveRegisteredSubjectMutation();

  //const socket = new WebSocket(process.env.REACT_APP_HUBADDRESS ?? "");

  const getSlotsBySection = useCallback(
    (sectionId: number) => {
      const slots = sectionsSlots?.find((sec) => sec.seccionId === sectionId)?.cupoString;
      return slots ?? 0;
    },
    [sectionsSlots],
  );

  useEffect(() => {
    if ((isErrorSeleccion && errorRem?.error) || errorRem?.data) {
      if (errorRem?.data) {
        toast.error(JSON.parse(errorRem.data).message);
      } else {
        toast.error(errorRem.error);
      }
    }
  }, [isErrorSeleccion, errorRem]);

  useEffect(() => {
    if ((isErrorDeseleccion && errorSel?.error) || errorSel?.data) {
      if (errorSel?.data) {
        toast.error(JSON.parse(errorSel.data).message);
      } else {
        toast.error(errorSel.error);
      }
    }
  }, [isErrorDeseleccion, errorSel]);

  useEffect(() => {
    setIsLoadingSomething(isLoadingDeseleccion || isLoadingSeleccion);
  }, [isLoadingDeseleccion, isLoadingSeleccion, setIsLoadingSomething]);

  useEffect(() => {
    const url = process.env.REACT_APP_HUBADDRESS;
    if (!connection) {
      const connect = new HubConnectionBuilder()
        .withUrl(url ?? "", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }
  }, [connection]);

  const disconnect = () => {
    connection?.stop().then(() => setConnection(null));
  };

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          connection.on("receiveAvailableSlots", (res: any[], invocation) => {
            console.log(invocation, "inv");
            // if (invocation === invocationId) {
            //   if (cb) cb(res);
            //   setSectionsSlots(res);
            setIsLoadingSomething(false);
            // }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection, cb, getSlotsBySection, invocationId]);

  // socket.onopen = function () {
  //   console.log("[open] Connection established");
  //   const endChar = String.fromCharCode(30);
  //   // send the protocol & version
  //   socket.send(`{"protocol":"json","version":1}${endChar}`);
  // };

  // socket.onmessage = function (event) {
  //   const endChar = String.fromCharCode(30);
  //   if (typeof event.data === "string") {
  //     const result = JSON.parse(event.data?.replaceAll(endChar, ""));
  //     console.log(result);
  //     if (result?.invocationId === invocationId) {
  //       if (cb) cb(result?.arguments);
  //       setSectionsSlots(result.arguments);
  //       setIsLoadingSomething(false);
  //     }
  //   }
  //   // handle server messages
  // };
  const getAvailableSlots = async (subjectId: number, carreraId: number) => {
    if (connection) {
      const studentId = JSON.parse(decodeJwt(localStorage.getItem("token") ?? "")).studentId;
      try {
        setInvocationId(studentId);
        console.log(subjectId, carreraId);
        // const endChar = String.fromCharCode(30);

        // socket.send(
        //   `{"arguments":[${subjectId}, ${carreraId}, ${+studentId}],"invocationId":"${studentId}","target":"AvailableSlots","type":1}${endChar}`,
        // );
        // setInvocationId(
        //   await connection.invoke("AvailableSlots", subjectId, carreraId, +studentId),
        // );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addSlot = async (
    seccionId: number,
    carreraId: number,
    periodoId: number,
    cb: () => void,
  ) => {
    deseleccionar({ carreraId, seccionId, periodoId }).then(() => {
      cb();
    });
  };
  const removeSlot = async (
    seccionId: number,
    carreraId: number,
    periodoId: number,
    cb: () => void,
  ) => {
    seleccionar({ carreraId, seccionId, periodoId }).then(() => {
      cb();
    });
  };

  return {
    addSlot,
    removeSlot,
    getAvailableSlots,
    disconnect,
    isLoadingSomething,
  };
}

export default useManageSlots;
