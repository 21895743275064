import React from "react";
import { MediumHeadingStyled } from "src/components/custom/texts/texts.styled";
import ScrollView from "src/components/custom/scroll-view/scroll-view";
import ResumeCard from "../resume-card/resume-card";
import { ICartItem } from "src/Interfaces/cart";
import { Box } from "@nubeteck/components";
import { Collapse, CollapseProps, MenuProps } from "antd";

interface IResumeList {
  title: string;
  listItems: ICartItem[];
  listType: "subject" | "service" | "balance";
  items: (data: any) => MenuProps["items"];
  viewHeight: string;
  isLoading?: boolean;
  marcarParaCuotas?: (matriculaId: number, costo: number) => void;
  programas?: Array<{ carreraId: number; carreraNombre: string }>;
}

const ResumeList = ({
  title,
  listItems,
  listType,
  items,
  viewHeight,
  marcarParaCuotas,
  programas,
}: IResumeList) => {
  const itemsCollapse: CollapseProps["items"] = programas?.map((prog) => ({
    key: prog.carreraId,
    label: prog.carreraNombre,
    children: listItems
      .filter((subject) => subject.programaId === prog.carreraId)
      .map((item) => (
        <Box key={item.asignaturaId}>
          <ResumeCard
            marcarParaCuotas={marcarParaCuotas}
            type={listType}
            title={item.asignaturaNombre ?? item.servicioNombre ?? item.descripcion ?? ""}
            codigo={item.asignaturaCodigo ?? ""}
            items={items}
            {...item}
          />
          <br />
        </Box>
      )),
  }));
  return (
    <>
      <MediumHeadingStyled>{title}</MediumHeadingStyled>
      <ScrollView height={viewHeight}>
        <Box>
          {itemsCollapse && itemsCollapse.length > 0 ? (
            <Collapse
              items={itemsCollapse}
              defaultActiveKey={itemsCollapse.map((it) => it.key ?? 1)}
              ghost
              style={{ background: "transparent" }}
            />
          ) : (
            <Collapse
              items={[
                {
                  key: listType === "service" ? 2 : 1,
                  label: title,
                  children: listItems.map((item) => (
                    <Box key={item.asignaturaId ?? item.servicioId}>
                      <ResumeCard
                        marcarParaCuotas={marcarParaCuotas}
                        type={listType}
                        title={
                          item.asignaturaNombre ?? item.servicioNombre ?? item.descripcion ?? ""
                        }
                        codigo={item.asignaturaCodigo ?? ""}
                        items={items}
                        {...item}
                      />
                      <br />
                    </Box>
                  )),
                },
              ]}
              defaultActiveKey={listType === "service" ? 2 : 1}
              ghost
              style={{ background: "transparent" }}
            />
          )}
        </Box>
      </ScrollView>
    </>
  );
};

export default ResumeList;
