/* eslint-disable @typescript-eslint/no-explicit-any */

import { api } from "./apiBaseQuery";

export const subjectsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubjectsByStudent: builder.mutation({
      query: (data) => {
        return {
          url: `RetirarAsignaturas`,
          method: "GET",
          params: {
            estudianteId: data.estudianteId,
            periodoId: data.periodoId,
            anio: data.anio,
          },
        };
      },
    }),
    withdrawSubjectByStudent: builder.mutation({
      query: (data) => {
        return {
          url: `RetirarAsignaturas`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSubjectsByCareerPeriod: builder.mutation({
      query: (data) => {
        return {
          url: `Estudiantes/Proyeccion/${data.careerId}?periodoId=${data.periodId}&esPreMatricula=${data.esPrematricula}`,
          method: "GET",
        };
      },
    }),
    getSelectionDataByCareerPeriod: builder.mutation({
      query: (data) => {
        return {
          url: `Estudiantes/Proyeccion/Datos/${data.careerId}?periodoId=${data.periodId}&esPreMatricula=${data.esPrematricula}`,

          method: "GET",
        };
      },
    }),
    registerSubject: builder.mutation({
      query: (body) => {
        return {
          url: `Estudiantes/Matricula`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    removeRegisteredSubject: builder.mutation({
      query: (params) => {
        return {
          url: `Estudiantes/Matricula/Retirar/${params.seccionId}?carreraId=${params.carreraId}&periodoId=${params.periodoId}`,
          method: "PUT",
          responseHandler: "text",
        };
      },
    }),
    getSelectedSubjects: builder.mutation({
      query: (programaId: number) => {
        return {
          url: `Estudiantes/AsignaturasSeleccionadas/${programaId}`,
          method: "GET",
        };
      },
    }),
    getSufficiencySubjects: builder.mutation({
      query: (params) => {
        return {
          url: `Estudiantes/AsignaturasPendientes?carreraId=${params.careerId}&periodoId=${params.periodId}`,
          method: "GET",
        };
      },
    }),
    registerSufficiencySubject: builder.mutation({
      query: (body) => {
        return {
          url: `Estudiantes/AgregarSuficiencia`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
    getSubjectsCalendar: builder.query({
      query: () => {
        return {
          url: `Asignaturas/ListaEventos/Obtener`,
        };
      },
    }),
    getCurrentSubjectsDetail: builder.mutation({
      query: () => {
        return {
          url: `Asignaturas/AsignaturasHorarios/ObtenerAsignaturas`,
          method: "POST",
        };
      },
    }),
    getWithdrawMessage: builder.mutation({
      query: (id: number) => {
        return {
          url: `RetirarAsignaturas/Mensaje?seccionId=${id}`,
          method: "GET",
        };
      },
    }),
    withdrawSubject: builder.mutation({
      query: (id: number) => {
        return {
          url: `/Estudiantes/Asignaturas/Retirar/${id}`,
          method: "POST",
          responseHandler: "text",
        };
      },
    }),
    getSelectionTimeSettings: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/RelojConfiguraciones`,
          method: "GET",
        };
      },
    }),
    getSelectionTimeLeft: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/TiempoSeleccion`,
          method: "GET",
        };
      },
    }),
    getExtraSelectionTime: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/RelojExtension`,
          method: "GET",
        };
      },
    }),
    extendSelectionTimeLeft: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/RelojExtension`,
          method: "POST",
        };
      },
    }),
    extendSelectionTimeLeftALittle: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/TiempoSeleccionExtension`,
          method: "POST",
        };
      },
    }),
    activateSelectionTimeLeft: builder.mutation({
      query: () => {
        return {
          url: `Estudiantes/TiempoSeleccion`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetCurrentSubjectsDetailMutation,
  useWithdrawSubjectMutation,
  useGetWithdrawMessageMutation,
  useWithdrawSubjectByStudentMutation,
  useGetSubjectsByCareerPeriodMutation,
  useGetSubjectsByStudentMutation,
  useGetSelectionDataByCareerPeriodMutation,
  useRegisterSubjectMutation,
  useGetSubjectsCalendarQuery,
  useGetSelectionTimeLeftMutation,
  useActivateSelectionTimeLeftMutation,
  useRemoveRegisteredSubjectMutation,
  useGetSelectedSubjectsMutation,
  useExtendSelectionTimeLeftMutation,
  useGetExtraSelectionTimeMutation,
  useExtendSelectionTimeLeftALittleMutation,
  useGetSelectionTimeSettingsMutation,
  useGetSufficiencySubjectsMutation,
  useRegisterSufficiencySubjectMutation,
} = subjectsServices;
