import React from "react";
import { CardStyled as HeadCard } from "../../custom/cards/card.styled";
import {
  CardText,
  CardTitle,
  CardUserFullName,
  CardUserId,
  CustomDivider,
  ImageContainer,
  UserCardBody,
} from "./report-cards.styled";
import { Image, Space } from "@nubeteck/components";
import { UserReportIconSVG } from "../../../assets";
import { useMediaQuery } from "src/hooks";

interface IUserInfoCardProps {
  nombre: string;
  id: string;
  extraContent: React.ReactNode;
}

const UserInfoCard = ({ nombre, id, extraContent }: IUserInfoCardProps) => {
  const smallScreen = useMediaQuery("(max-width: 1024px)");
  const tabletMode = useMediaQuery("(max-width: 768px)");
  return (
    <HeadCard
      bodyStyle={{
        padding: 7,
        display: "flex",
        alignItems: "center",
        minWidth: !smallScreen ? "870px" : !tabletMode ? "765px" : "auto",
      }}
      style={{ minWidth: 300, flex: "1" }}
    >
      <UserCardBody>
        <CardUserFullName>
          <ImageContainer>
            <Image preview={false} src={UserReportIconSVG} width={32} />
          </ImageContainer>
          <Space direction="vertical" style={{ paddingTop: 10 }}>
            <CardTitle>Nombre</CardTitle>
            <CardText>{nombre}</CardText>
          </Space>
        </CardUserFullName>
        <CustomDivider />
        <CardUserId direction="vertical">
          <CardTitle>ID</CardTitle>
          <CardText>{id}</CardText>
        </CardUserId>
        {extraContent ? (
          <>
            <CustomDivider /> {extraContent}
          </>
        ) : null}
      </UserCardBody>
    </HeadCard>
  );
};

export default UserInfoCard;
