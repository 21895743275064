import { styled } from "styled-components";
import { Flex, Constants } from "@nubeteck/components";

const { device } = Constants;

export const AcademicRecordContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  overflow: auto;
`;

export const AcademicRecordHead = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const AcademicRecordContent = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 32px;

  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptop} {
    gap: 64px;
  }
`;

export const RegisteredCareersContainer = styled(Flex)`
  flex: 2;
  flex-direction: column;
  gap: 24px;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
  }
`;

export const RegisteredCareersBody = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  overflow: auto;
`;

export const CardAndButtonContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${device.tablet} {
    align-items: flex-end;
  }
`;
