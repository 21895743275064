import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Button, Flex, Input, InputNumber } from "@nubeteck/components";
import { Card, Form, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { useUpdateSubjectsWeightMutation } from "src/services/importsService";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import toast from "react-hot-toast";

function SubjectsImport({ isLoading, getData, data }: any) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [subjectName, setSubjectName] = React.useState("");
  const [subjectsWeight, setSubjectsWeight] = React.useState<any[]>([
    { asignaturaId: data?.data?.result[0].asignaturaId, peso: data?.data?.result[0].peso },
  ]);

  const [updateSubjects, { isSccess: isSuccessSubjectsUpdated, error: errorCareersUpdated }] =
    useUpdateSubjectsWeightMutation();

  useEffect(() => {
    if (errorCareersUpdated) {
      toast.error(JSON.parse(errorCareersUpdated?.data)?.message, toastErrorStyle);
    }
    if (isSuccessSubjectsUpdated) {
      toast.success(
        "Los pesos de las asignaturas se actualizaron correctamente",
        toastSuccessStyle,
      );
    }
  }, [errorCareersUpdated, isSuccessSubjectsUpdated]);

  useEffect(() => {
    getData({ rowsCount, page: pageNumber, asignaturaNombre: subjectName, modalidadId: null });
  }, [subjectName, rowsCount, pageNumber, getData, isSuccessSubjectsUpdated]);

  const changeSubjectsWeight = (peso: number, asignaturaId: number) => {
    const arr = subjectsWeight;
    const indexFound = subjectsWeight.indexOf(
      subjectsWeight.find((sub) => sub.asignaturaId === asignaturaId),
    );
    if (indexFound === -1) {
      arr.push({ asignaturaId, peso });
    } else {
      arr[indexFound] = { asignaturaId, peso };
    }
    setSubjectsWeight(arr);
  };

  const actualizarMaterias = () => {
    const arr = subjectsWeight;
    arr.shift();
    updateSubjects(arr);
  };

  const columns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Código",
        dataIndex: "asignaturaCodigo",
      },
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        title: "Costo",
        dataIndex: "costo",
      },
      {
        title: "Descripción",
        dataIndex: "descripcion",
      },
      {
        title: "Créditos",
        dataIndex: "creditos",
      },
      {
        title: "Peso",
        dataIndex: "asignaturaId",
        render: (value) => (
          <InputNumber
            size="small"
            placeholder="Peso"
            type="number"
            min={0}
            max={100}
            controls={false}
            defaultValue={data?.data?.result?.find((c: any) => c.asignaturaId === value)?.peso}
            onChange={(e) => changeSubjectsWeight(e ? +e : 0, value)}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <>
      <MediumLargeHeadingStyled>Asignaturas importadas</MediumLargeHeadingStyled>
      <Flex style={{ gap: "15px" }}>
        <Form.Item label="Búsqueda por nombre de asignatura">
          <Input
            type="text"
            onChange={(e) => setSubjectName(e.target.value)}
            placeholder="Búsqueda por nombre de asignatura"
          />
        </Form.Item>
        {subjectsWeight.length > 0 && !isLoading ? (
          <Button
            type="primary"
            onClick={() => {
              actualizarMaterias();
            }}
          >
            Guardar pesos
          </Button>
        ) : null}
      </Flex>

      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<any>
          loading={isLoading}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.asignaturaId}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            total: data?.data?.pageCount * rowsCount,
            current: pageNumber,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
}

export default SubjectsImport;
