import styled from "styled-components";
import { CardStyled } from "../../custom/cards/card.styled";
import { Button, Flex, Constants, Box, Icon } from "@nubeteck/components";
//import { devices } from "src/constants";

const { device, maxDevice } = Constants;

export const ResumeCardStyled = styled(CardStyled)`
  &.is-expanded {
    .ant-card-body {
      min-height: 315px;
      max-height: 315px;
      .img-container.is-expanded {
        min-height: 240px;
        max-height: 240px;
      }
    }
  }
  &.is-service-expanded {
    .ant-card-body {
      min-height: 235px;
      max-height: 235px;
      .img-container.is-expanded {
        min-height: 180px;
        max-height: 180px;
      }
    }
  }
  .ant-card-body {
    transition: all ease 0.5s;
    max-height: 140px;
    min-height: 140px;
    max-width: 890px;
    min-width: 405px;
    width: 37vw;
    padding: 10px 15px;
    color: ${({ theme }) => theme.colors.colorText};

    h4 {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      margin: 5px 0px;
    }

    h5 {
      font-size: 12px;
      margin: 5px 0px;
    }
    div > span {
      font-size: 13px; // Afecta el dropdown icon xd
    }

    ul {
      margin: 7px 0px;
      font-size: 13px;
      padding: 0px;
      li {
        list-style-type: none;
      }
    }

    .img-container {
      padding: 7px;
      transition: all ease 0.4s;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.colors.primary500};
      align-items: center;
      border-radius: 10px;
      display: flex;
      max-height: 90px;
      min-height: 90px;
    }
  }

  @media ${device.mobileS} and ${maxDevice.tablet} {
    .ant-card-body {
      min-width: 84vw;
      max-width: 84vw;
      min-height: 120px;
      max-height: 120px;
      padding: 6px 20px;

      .img-container {
        display: none;
      }
    }
  }
`;
export const ResumeFlexStyled = styled(Flex)`
  justify-content: left;
  align-items: center;
  gap: 5%;
`;

export const ActivePriceStyled = styled.span`
  color: ${({ theme }) => theme.colors.primary600};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const InactivePriceStyled = styled.span`
  color: ${({ theme }) => theme.colors.colorGray};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: line-through;
`;
export const MoreDetailsButtonStyled = styled(Button)`
  padding: 0px;
`;

export const ResumeCardIconContainer = styled(Box)`
  position: absolute;
  cursor-pointer;
  top: 12px;
  right: 7px;
  cursor: pointer;
`;
export const ResumeCardDuesCheckContainer = styled(Box)`
  position: absolute;
  display:flex;
  align-items:end;
  cursor-pointer;
  bottom: 5px;
  right: 0px;
  div{
    display:flex;
    flex-direction:column;

  }

  .ant-checkbox-inner{
    border: 1px solid grey;
  }
`;

export const DropdownIcon = styled(Icon)`
  font-size: 20px !important; // Aquí lo arreglo
`;

export const DropdownSubIcon = styled(Icon)`
  font-size: 24px !important; // Aquí lo arreglo
`;
