import React, { useEffect } from "react";
import { useLoginFakeMutation } from "src/services";
import { useNavigate } from "react-router-dom";
import { Flex } from "@nubeteck/components";
import { Content } from "antd/es/layout/layout";
import { LargeHeadingStyled, SectionHeaderStyled } from "src/components/custom/texts/texts.styled";
import { Button, Form, Input } from "antd";
import toast from "react-hot-toast";

const FakeLogin = () => {
  const [form] = Form.useForm<any>();
  const { validateFields } = form;
  const [loginFake, { data, isSuccess, isLoading }] = useLoginFakeMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("matriculaAdministrativa", "true");
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", JSON.stringify(data.data.refreshToken));
      navigate("/subjects-registration/initial");
      window.location.reload();
    }
  }, [data, isSuccess, navigate]);

  const submit = () => {
    validateFields(["matricula"])
      .then((values) => {
        if (values) {
          loginFake(values);
        }
      })
      .catch(() => {
        toast.error(`El ID del estudiante es necesario.`);
      });
  };

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <SectionHeaderStyled>
          <LargeHeadingStyled>Matricular como usuario estudiante</LargeHeadingStyled>
        </SectionHeaderStyled>
        <Flex style={{ gap: "15px" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            style={{ display: "flex", flexDirection: "column", gap: 16 }}
            initialValues={{
              id: 1,
            }}
          >
            <Form.Item
              name="matricula"
              label="ID del estudiante"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Este campo es requerido",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
              Ingresar
            </Button>
          </Form>
        </Flex>
      </Content>
    </Flex>
  );
};

export default FakeLogin;
