import React from "react";
import { CardStyled } from "../../custom/cards/card.styled";
import {
  LargeHeadingStyled,
  MediumHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { SubjectDescriptionStyled } from "./subject-data.styled";
import { Box, Image } from "@nubeteck/components";
import NoResults from "src/assets/NoResults.svg";
import { ISubject } from "src/Interfaces/subject";

export const SubjectDescriptionDisplay = ({
  asignaturaNombre,
  descripcion,
  contenido /*codigo*/,
}: ISubject) => {
  return (
    <CardStyled>
      <Box>
        <LargeHeadingStyled>{asignaturaNombre}</LargeHeadingStyled>
        <SubjectDescriptionStyled>{descripcion}</SubjectDescriptionStyled>
        {contenido && contenido.length > 0 ? (
          <>
            <MediumHeadingStyled>contenido:</MediumHeadingStyled>
            <ul>{contenido?.map((content) => <li key={content}>{content}</li>)}</ul>
          </>
        ) : (
          <Box>
            <Image src={NoResults} preview={false} alt="Ilustración sin resultados" width={200} />
          </Box>
        )}
      </Box>
    </CardStyled>
  );
};
