import { api } from "./apiBaseQuery";

export const reportsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: () => {
        return {
          url: `Reportes`,
        };
      },
    }),
    getReportById: builder.mutation({
      query: (params) => {
        return {
          url: `Reportes/${params.reporteId}`,
          method: "POST",
          body: params.values,
        };
      },
    }),
  }),
});

export const { useGetReportsQuery, useGetReportByIdMutation } = reportsServices;
