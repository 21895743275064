import React from "react";
import {
  ButtonAlternativeStyled,
  ButtonPrimaryStyled,
} from "src/components/custom/buttons/buttons.styled";
import { LargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import {
  DownloadContainerStyled,
  FlexPDFButtonsStyled,
  SpinnerContainerStyled,
} from "src/pages/student-pages/cart/cart.styled";
import { Card, Spin } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { useGetBillPdfMutation } from "src/services/cartServices";
import { useNavigate, useParams } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PaymentReceipt() {
  const [getInvoiceFile, { data: dataPdf, isLoading: isLoadingFile }] = useGetBillPdfMutation();
  const { invoiceId, peopleId } = useParams();
  const navigate = useNavigate();
  const [PDFPages, setPDFPages] = React.useState<number>(0);

  React.useEffect(() => {
    getInvoiceFile({ id: invoiceId, people: peopleId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, peopleId]);

  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPDFPages(numPages);
  };

  const getPdfPages = () => {
    const pages: JSX.Element[] = [];
    for (let index = 1; index <= PDFPages; index++) {
      pages.push(
        <Page
          pageNumber={index}
          width={window.innerWidth / 1.4}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode="canvas"
        />,
      );
    }
    return pages;
  };

  return (
    <DownloadContainerStyled>
      <FlexPDFButtonsStyled>
        <ButtonPrimaryStyled
          type="link"
          href={`data:application/pdf;base64,${dataPdf}`}
          download="Recibo de pago.pdf"
        >
          Descargar PDF
        </ButtonPrimaryStyled>
        <ButtonAlternativeStyled onClick={() => navigate(-1)}>Volver atrás</ButtonAlternativeStyled>
      </FlexPDFButtonsStyled>
      <Card>
        <LargeHeadingStyled>Pago efectuado recientemente:</LargeHeadingStyled>
        {isLoadingFile && (
          <SpinnerContainerStyled>
            <Spin spinning={isLoadingFile} size="large" />
          </SpinnerContainerStyled>
        )}
        <Document file={`data:application/pdf;base64,${dataPdf}`} onLoadSuccess={onDocLoadSuccess}>
          {getPdfPages()}
        </Document>
      </Card>
    </DownloadContainerStyled>
  );
}

export default PaymentReceipt;
