import { api } from "./apiBaseQuery";

export const bankServices = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.mutation({
      query: () => {
        return {
          url: `Bancos`,
          method: "GET",
        };
      },
    }),
    createBank: builder.mutation({
      query: (body) => {
        return {
          url: "Bancos",
          method: "POST",
          body,
        };
      },
    }),
    updateBank: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `Bancos/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    setBankStateActive: builder.mutation({
      query: (id: number) => {
        return {
          url: `Bancos/Activar/${id}`,
          method: "PUT",
        };
      },
    }),
    setBankStateInactive: builder.mutation({
      query: (id) => {
        return {
          url: `Bancos/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetBanksMutation,
  useCreateBankMutation,
  useUpdateBankMutation,
  useSetBankStateActiveMutation,
  useSetBankStateInactiveMutation,
} = bankServices;
