import React from "react";
import { ButtonPrimaryStyled, ButtonsContainer } from "../../custom/buttons/buttons.styled";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { BackNextButton } from "src/components/custom";
import { toast } from "react-hot-toast";
import { ICareer } from "src/Interfaces/career";

interface IForm {
  formCurrentItem?: JSX.Element;
  readyForSubmit: boolean;
  goBack: () => void;
  goToNext: () => void;
  stepIndex: number;
  careers: ICareer[];
  callbackIfPreviousRoute: (program: number, period: number) => void;
}
const RegisterForm = ({
  formCurrentItem,
  readyForSubmit,
  goBack,
  goToNext,
  stepIndex,
  careers,
  callbackIfPreviousRoute,
}: IForm) => {
  const navigate = useNavigate();
  const methods = useForm();

  const { handleSubmit, setValue, register } = methods;

  const { program, period } = useParams();

  React.useEffect(() => {
    register("programa", {
      required: true,
    });
    register("aceptaTerminos", {
      required: true,
    });
    register("materiasSeleccionadas");
  }, [register]);

  React.useEffect(() => {
    if (program && program !== "initial" && period) {
      setValue("programa", {
        carreraId: +program,
        seleccionSinTerminar: careers?.find((c) => c.tieneSeleccionSinTerminar === true),
      });
      setValue("aceptaTerminos", true);
      callbackIfPreviousRoute(+program, +period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: unknown) => {
    if (stepIndex === 1 && methods.getValues("materiasSeleccionadas")?.length === 0)
      toast.error("Debe realizar la selección de materias para continuar.", {
        style: {
          padding: "16px",
          color: "#e74c3c",
          fontSize: 18,
          fontWeight: 700,
        },
        iconTheme: {
          primary: "#e74c3c",
          secondary: "#FFF",
        },
      });
    else {
      if (data == undefined) {
        console.log(data, "data");
      }
      navigate("/cart/selection");
    }
  };

  const goToNextStep = () => {
    let error = null;
    if (stepIndex === 0) {
      const programa = methods.getValues("programa");
      const aceptaTerminos = methods.getValues("aceptaTerminos");
      if (!programa) {
        error = "Debe seleccionar un programa para continuar.";
      } else if (
        programa.seleccionSinTerminar &&
        programa.seleccionSinTerminar.carreraId !== programa.carreraId
      ) {
        error = `Debe completar la selección pendiente en el programa ${programa.seleccionSinTerminar.carreraNombre} antes de matricular en otro programa.`;
      } else if (!aceptaTerminos) {
        error = "Debe aceptar los términos para continuar.";
      }
    } else if (stepIndex === 1 && methods.getValues("materiasSeleccionadas")?.length === 0)
      error = "Debe realizar la selección de materias para continuar.";

    if (error)
      toast.error(error, {
        style: {
          padding: "16px",
          color: "#e74c3c",
          fontSize: 18,
          fontWeight: 700,
        },
        iconTheme: {
          primary: "#e74c3c",
          secondary: "#FFF",
        },
      });
    else goToNext();
  };

  const renderNextBackButtons = () => (
    <BackNextButton
      goBack={goBack}
      goToNext={goToNextStep}
      omitBack={stepIndex === 0}
      omitNext={readyForSubmit}
    />
  );

  return (
    <FormProvider {...methods}>
      <form>
        {formCurrentItem}
        <ButtonsContainer>
          {renderNextBackButtons()}
          {readyForSubmit && (
            <ButtonPrimaryStyled type="primary" size="large" onClick={handleSubmit(onSubmit)}>
              Ir al carrito
            </ButtonPrimaryStyled>
          )}
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
