import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Image, Text } from "@nubeteck/components";
import { RegisterSVG as IntroductionImg } from "../../../assets";
import {
  LargeHeadingStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { CardStyled as RegisterCard } from "../../../components/custom/cards/card.styled";
import { WarningCard } from "../../custom/cards";
import {
  CareersOptionsContainer,
  FlexStyled,
  IntroductionContainer,
  OptionsContainer,
  RadioGroupStyled,
} from "./register-introduction.styled";
import { ICareer } from "@/Interfaces/career";
import { Checkbox, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "src/core/store/store";
import { FormatterUtils } from "@nubeteck/utils";

const RegisterIntroduction = ({
  careers,
  setCareer,
  setPeriod,
  isLoading,
  timeValue,
}: {
  careers: ICareer[];
  setCareer: (id: number) => void;
  setPeriod: (id: number) => void;
  isLoading: boolean;
  goNext: () => void;
  timeValue: number;
}) => {
  const { userInfo } = useSelector((state: RootState) => state.user);

  const { setValue, getValues } = useFormContext();
  const [terminos, setTerminos] = useState<string>("");
  const [careerValue, setCareerValue] = useState<{
    carreraId: number;
    seleccionSinTerminar: ICareer | undefined;
    periodoId: number;
  }>();

  useEffect(() => {
    if (careers?.length > 0)
      setCareerValue({
        carreraId: careers?.filter((career) => career.periodo !== null)[0]?.carreraId,
        seleccionSinTerminar: careers?.find((c) => c.tieneSeleccionSinTerminar === true),
        periodoId: careers?.filter((career) => career.periodo !== null)[0]?.periodoId,
      });
  }, [careers]);

  useEffect(() => {
    if (careerValue?.carreraId) {
      setValue("programa", careerValue);
      setCareer(careerValue.carreraId);
      setPeriod(careerValue.periodoId);
      setTerminos(careers.find((c) => c.carreraId === careerValue.carreraId)!.terminosYCondiciones);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerValue]);

  return (
    <RegisterCard>
      <IntroductionContainer>
        <Box>
          <Skeleton loading={isLoading} active round />
          {!isLoading && (
            <>
              <LargeHeadingStyled>Estimado(a) estudiante:</LargeHeadingStyled>
              <SmallHeadingStyled>Bienvenido(a) al proceso de matrícula</SmallHeadingStyled>
              <Text style={{ fontWeight: "bold" }}>
                Antes de iniciar, es muy importante que tenga presente lo siguiente:
                <p>
                  1. Tendrá un tiempo máximo de {timeValue} minutos para completar el proceso de
                  matrícula.
                </p>
                <p>
                  2. Si el tiempo se agota o sale del sistema antes de completar el proceso de
                  matrícula, todas las operaciones realizadas serán reversadas por lo que usted no
                  quedará inscrito en ninguno de los cursos que haya seleccionado.
                </p>
                <p>3. El sistema sólo permite el pago por medio de tarjetas de crédito / débito.</p>
              </Text>

              <SmallHeadingStyled style={{ marginBottom: 0 }}>
                {" "}
                {careers?.filter((career) => career.periodo !== null).length > 0
                  ? "A continuación elige el programa a matricular:"
                  : "No tiene programas con asignaturas disponibles para seleccionar."}
              </SmallHeadingStyled>
              <FlexStyled>
                <OptionsContainer>
                  <RadioGroupStyled
                    style={{ flexDirection: "column", alignItems: "flex-start" }}
                    onChange={(e: any) => {
                      setCareerValue({
                        carreraId: e.target.value,
                        periodoId:
                          careers.find((c) => c.carreraId === e.target.value)?.periodoId ?? 0,
                        seleccionSinTerminar: careers.find(
                          (c) => c.tieneSeleccionSinTerminar === true,
                        ),
                      });
                    }}
                    value={careerValue?.carreraId}
                    options={careers
                      ?.filter((career) => career.periodo !== null)
                      ?.map((c) => ({
                        label: (
                          <CareersOptionsContainer>
                            <span>
                              <strong>{c.carreraNombre}</strong>
                            </span>
                            <span>
                              {`Periodo ${c.periodo}, desde ${
                                new Date(c.periodoFechaInicio).toLocaleString("es-CR").split(",")[0]
                              } hasta ${
                                new Date(c.periodoFechaFin).toLocaleString("es-CR").split(",")[0]
                              }`}
                            </span>
                          </CareersOptionsContainer>
                        ),
                        value: c.carreraId,
                      }))}
                  />
                </OptionsContainer>
              </FlexStyled>

              <div dangerouslySetInnerHTML={{ __html: terminos }}></div>

              {careers?.filter((career) => career.periodo !== null).length > 0 && (
                <Checkbox
                  onChange={(e) => setValue("aceptaTerminos", e.target.checked)}
                  defaultChecked={getValues().aceptaTerminos}
                >
                  <Text style={{ fontWeight: "bold" }}>Acepto los términos</Text>
                </Checkbox>
              )}
              <br />
              <br />

              {userInfo?.saldoVencidoColones > 0.1 || userInfo?.saldoVencidoDolares > 0.1 ? (
                <WarningCard
                  size="small"
                  message={`Posee un saldo vencido de: ${
                    userInfo?.saldoVencidoColones > 0
                      ? `${FormatterUtils.number(userInfo.saldoVencidoColones?.toFixed(2))} colones`
                      : ""
                  }${
                    userInfo?.saldoVencidoColones > 0 && userInfo?.saldoVencidoDolares > 0
                      ? "y"
                      : ""
                  }${
                    userInfo?.saldoVencidoDolares > 0
                      ? `${FormatterUtils.number(userInfo.saldoVencidoDolares?.toFixed(2))} dólares`
                      : ""
                  }; al finalizar el proceso, deberá pagar este monto junto con los cargos asociados al mismo.`}
                />
              ) : null}
            </>
          )}
        </Box>
        <Image src={IntroductionImg} width={285} alt="Ilustración registro" preview={false} />
      </IntroductionContainer>
    </RegisterCard>
  );
};

export default RegisterIntroduction;
