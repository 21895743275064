import { api } from "./apiBaseQuery";

export const emailTemplateServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplates: builder.mutation({
      query: () => {
        return {
          url: `Plantillas`,
          method: "GET",
        };
      },
    }),
    getTemplateTypes: builder.query({
      query: () => {
        return {
          url: `Plantillas/Tipos`,
        };
      },
    }),
    createEmailTemplate: builder.mutation({
      query: (body) => {
        return {
          url: "Plantillas",
          method: "POST",
          body,
        };
      },
    }),
    updateEmailTemplate: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `Plantillas/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    changeEmailTemplateState: builder.mutation({
      query: (id) => {
        return {
          url: `Plantillas/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetEmailTemplatesMutation,
  useGetTemplateTypesQuery,
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useChangeEmailTemplateStateMutation,
} = emailTemplateServices;
