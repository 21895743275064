import { decodeJwt } from "src/utils";
import { BaseQueryApi, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import eventBus from "./EventBus";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASEURL,
  prepareHeaders: (headers) => {
    const token = localStorage?.getItem("token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

function verifyTokenExpiration() {
  const token = localStorage.getItem("token");
  const refrToken =
    localStorage.getItem("refreshToken") !== "undefined"
      ? JSON.parse(localStorage.getItem("refreshToken") ?? "")
      : {};

  if (token) {
    const fechaHoy = Math.floor(new Date().getTime() / 1000.0);
    const decodedToken = token !== undefined ? JSON.parse(decodeJwt(token)) : { exp: 0 };
    const tiempoExpiracion = decodedToken.exp;

    if (tiempoExpiracion - fechaHoy < 180) {
      if (tiempoExpiracion - fechaHoy < -1800 || !refrToken?.token) {
        //Se ha perdido el refreshToken
        eventBus.dispatch("logout", null);
      } else {
        eventBus.dispatch("refresh", { token: refrToken.token });
      }
    }
  } else {
    eventBus.dispatch("logout", null);
  }
}

const baseQueryWithReauth = async (args: any, api: BaseQueryApi, extraOptions: any) => {
  verifyTokenExpiration();
  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
