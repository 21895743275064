// import { objectify } from "radash";
import { setupListeners } from "@reduxjs/toolkit/query/react";
// import type { Reducer } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../../services/authService";
import { authAdmApi } from "../../services/authAdminService";
import { userServices } from "../../services/userServices";
import { genericServices } from "src/services/genericServices";
import { subjectsServices } from "src/services/subjectsServices";
import { reportsServices } from "src/services/reportsServices";
import subjectSelection from "./slices/subjectSelectionSlice";
import user from "./slices/userSlice";
import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { sponsorshipsServices } from "src/services/sponsorshipsServices";
import { importsServices } from "src/services/importsService";
import { requestsServices } from "src/services/requestsServices";
import { reviewsServices } from "src/services/reviewsServices";
import { calendarServices } from "src/services/calendarServices";

// import container from "../../inversify.config";

// interface SlicesModules {
//   moduleName: string;
//   slices: Array<{
//     name: string;
//     slice: Reducer<unknown>;
//   }>;
// }

// const slices = objectify(
//   container.getAll<SlicesModules>("Slices").flatMap((value) => value.slices) || [],
//   (f) => f.name,
//   (f) => f.slice,
// );

export const store = configureStore({
  reducer: {
    subjectSelection,
    user,
    [authApi.reducerPath]: authApi.reducer,
    [authAdmApi.reducerPath]: authAdmApi.reducer,
    [userServices.reducerPath]: userServices.reducer,
    [calendarServices.reducerPath]: calendarServices.reducer,
    [importsServices.reducerPath]: importsServices.reducer,
    [requestsServices.reducerPath]: requestsServices.reducer,
    [reviewsServices.reducerPath]: reviewsServices.reducer,
    [genericServices.reducerPath]: genericServices.reducer,
    [reportsServices.reducerPath]: reportsServices.reducer,
    [subjectsServices.reducerPath]: subjectsServices.reducer,
    [sponsorshipsServices.reducerPath]: sponsorshipsServices.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      authAdmApi.middleware,
      userServices.middleware,
      calendarServices.middleware,
      importsServices.middleware,
      requestsServices.middleware,
      reviewsServices.middleware,
      genericServices.middleware,
      reportsServices.middleware,
      subjectsServices.middleware,
      sponsorshipsServices.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
setupListeners(store.dispatch);
