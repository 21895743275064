import { api } from "./apiBaseQuery";

export const servicesServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.mutation({
      query: () => {
        return {
          url: `/Admins/Servicios`,
          method: "GET",
        };
      },
    }),
    updateServices: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Servicios/Actualizar`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
  }),
});

export const { useGetServicesMutation, useUpdateServicesMutation } = servicesServices;
