import React from "react";
import { ISchedule, type ISection } from "@/Interfaces/subject";
import { CalendarContainerStyled } from "./subject-data.styled";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import { weekDays } from "src/constants";
import { Spin } from "antd";
import { convertHourToNumber } from "src/utils";

interface ScheduleForCalendar {
  hora: string;
  secciones: (ISection & { button: JSX.Element })[] | [];
}

interface ISubjectScheduleCalendar {
  isLoading: boolean;
  secciones: ScheduleForCalendar[];
}

interface IRowSpanZero {
  overSpan: number;
  day: number;
}

export const SubjectCalendarSchedule = ({ secciones, isLoading }: ISubjectScheduleCalendar) => {
  const matches = useMediaQuery("(max-width: 480px)");

  const obtenerSeccionesEnMismoRango = (
    diaId: number,
    historial: Array<ISchedule & { seccionId: number }>,
    seccionBusqueda?: ISection & { button: JSX.Element },
  ) => {
    let seccionesEnRango: (ISection & { button: JSX.Element })[] = [];

    secciones.forEach((s) => {
      seccionesEnRango = seccionesEnRango.concat(
        s.secciones.filter(
          (sec) =>
            sec.horarios[0].diaId === diaId &&
            !historial.find(
              (s) =>
                s.diaId === sec.horarios[0].diaId &&
                s.horaInicio === sec.horarios[0].horaInicio &&
                s.horaFin === sec.horarios[0].horaFin,
            ) &&
            ((convertHourToNumber(sec.horarios[0].horaInicio) <
              convertHourToNumber(seccionBusqueda?.horarios[0].horaFin ?? "") &&
              convertHourToNumber(sec.horarios[0].horaFin) >
                convertHourToNumber(seccionBusqueda?.horarios[0].horaInicio ?? "")) ||
              convertHourToNumber(sec.horarios[0].horaInicio) ===
                convertHourToNumber(seccionBusqueda?.horarios[0].horaInicio ?? "") ||
              convertHourToNumber(sec.horarios[0].horaFin) ===
                convertHourToNumber(seccionBusqueda?.horarios[0].horaFin ?? "")),
        ),
      );
    });

    return seccionesEnRango;
  };

  const obtenerSeccionMayorDuracionPorDiaYHora = (hr: ScheduleForCalendar, diaId: number) => {
    const seccionesDelDia = hr.secciones.filter(
      (sec: ISection) => sec?.horarios[0].diaId === diaId,
    );

    const mayorHorasDuracion = Math.max(
      ...seccionesDelDia.map((sec) => +sec.horarios[0].horasDuracion),
    );
    const seccionMayorDuracion = seccionesDelDia.find(
      (sec) => +sec.horarios[0].horasDuracion === mayorHorasDuracion,
    );

    return seccionMayorDuracion;
  };

  const getWeekDayColSpan = (dayId: number) => {
    const maxOnEachHourPerDay: Array<{ hora: string; cantidad: number }> = [];

    let seccionesEnRangoHistorial: Array<ISchedule & { seccionId: number }> = [];
    secciones.forEach((hr) => {
      let quantityPerHour = 0;

      const seccionMayorDuracion = obtenerSeccionMayorDuracionPorDiaYHora(hr, dayId);

      const seccionesEnRango = obtenerSeccionesEnMismoRango(
        dayId,
        seccionesEnRangoHistorial,
        seccionMayorDuracion,
      );

      //historial para que no se repitan las secciones tomadas en cuenta para el colspan
      seccionesEnRangoHistorial = seccionesEnRangoHistorial.concat(
        seccionesEnRango.map((sec) => ({ ...sec.horarios[0], seccionId: sec.seccionId })),
      );

      quantityPerHour += seccionesEnRango.length;
      maxOnEachHourPerDay.push({ hora: hr.hora, cantidad: quantityPerHour });
    });
    const span = maxOnEachHourPerDay.reduce((previous, current) => {
      return current.cantidad > previous.cantidad ? current : previous;
    }).cantidad;
    return span > 0 ? span : 1;
  };

  const getRowByGroups = () => {
    let rowSpanZero: IRowSpanZero[] = [];
    let seccionesEnRangoHistorial: Array<ISchedule & { seccionId: number }> = [];
    const seccionesRenderizadas: Array<ISchedule & { seccionId: number }> = [];

    const result = secciones?.map((item) => {
      return (
        <tr key={`${item.hora}${Math.random()}`}>
          <td key={`${item.hora}${Math.random()}`}>{item.hora}</td>
          {weekDays.map((day) => {
            const seccionesDelDia = item.secciones.filter(
              (sec: ISection) => sec?.horarios[0].diaId === day.diaId,
            );

            const seccionMayorDuracion = obtenerSeccionMayorDuracionPorDiaYHora(item, day.diaId);

            const seccionesEnRango = obtenerSeccionesEnMismoRango(
              day.diaId,
              seccionesEnRangoHistorial,
              seccionMayorDuracion,
            );

            seccionesEnRangoHistorial = seccionesEnRangoHistorial.concat(
              seccionesEnRango.map((sec) => ({ ...sec.horarios[0], seccionId: sec.seccionId })),
            );

            if (seccionesDelDia.length > 0) {
              return seccionesDelDia.map((sectionOfDay, index) => {
                if (
                  !seccionesRenderizadas.some(
                    (s) =>
                      s.seccionId === sectionOfDay.seccionId &&
                      s.diaId === sectionOfDay.horarios[0].diaId &&
                      s.horaInicio === sectionOfDay.horarios[0].horaInicio &&
                      s.horaFin === sectionOfDay.horarios[0].horaFin,
                  )
                ) {
                  seccionesRenderizadas.push({
                    ...sectionOfDay.horarios[0],
                    seccionId: sectionOfDay.seccionId,
                  });
                  const span = sectionOfDay.horarios[0].horasDuracion.toString().split(".")[0];
                  rowSpanZero.push({ overSpan: +span - 1, day: day.diaId });
                  const colSpan = getWeekDayColSpan(day.diaId);
                  return (
                    <td
                      key={`${sectionOfDay.seccionId}${Math.random()}`}
                      rowSpan={+span}
                      colSpan={
                        index == seccionesDelDia?.length - 1 &&
                        colSpan % seccionesEnRango.length !== 0
                          ? +(colSpan / seccionesEnRango.length).toString().split(".")[0] +
                            (colSpan % seccionesEnRango.length)
                          : +(colSpan / seccionesEnRango.length).toString().split(".")[0]
                      }
                    >
                      {sectionOfDay?.button}
                    </td>
                  );
                }
              });
            } else if (rowSpanZero.some((row) => row.day === day.diaId && row.overSpan > 0)) {
              rowSpanZero = rowSpanZero.map((row) => {
                return {
                  day: row.day,
                  overSpan:
                    row.day === day.diaId && row.overSpan > 0 ? row.overSpan - 1 : row.overSpan,
                };
              });
              // return <></>;
            } else
              return (
                <td
                  key={`${day.acr}${Math.random()}`}
                  rowSpan={1}
                  colSpan={getWeekDayColSpan(day.diaId)}
                ></td>
              );
          })}
        </tr>
      );
    });

    return result;
  };

  const renderTable = () => {
    return (
      <Spin spinning={isLoading} size="large">
        <table className="table">
          <thead>
            <tr>
              <th>{matches ? "" : "Horario"}</th>
              {weekDays.map((day) => (
                <th key={day.acr} colSpan={getWeekDayColSpan(day.diaId)}>
                  {matches ? day.acr : day.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{getRowByGroups()}</tbody>
        </table>
      </Spin>
    );
  };

  return <CalendarContainerStyled>{renderTable()}</CalendarContainerStyled>;
};
