/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Flex, Icon, Modal, Text } from "@nubeteck/components";
import { Dropdown, MenuProps, Spin, Tabs, type TabsProps } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import {
  CalendarProps,
  Calendar as ReactBigCalendar,
  View,
  dayjsLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { SectionHeaderStyled } from "src/components/custom/texts/texts.styled";
import ModelGoogleCalendar from "src/models/google-calendar-model";
import { useGetCalendarMutation } from "src/services/calendarServices";
import { useGetSubjectsCalendarQuery } from "src/services/subjectsServices";
import { useMediaQuery } from "../../../hooks";
import {
  CalendarBody,
  CalendarContainer,
  CalendarText,
  ModalBody,
  ModalHead,
  ModalShowMore,
  ModalSubjectText,
  ModalText,
  TimeContainer,
} from "./calendar.styled";
import { IGoogleCalendarCurrent } from "@/Interfaces/google-calendar";
import "dayjs/locale/es";

const localizer = dayjsLocalizer(dayjs);

interface Event {
  allDay?: boolean | undefined;
  title?: React.ReactNode | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resource?: any;
  isGoogleCalendar?: boolean;
  backgroundColor?: string;
  color?: string;
}

const calendarItems: MenuProps["items"] = [
  {
    key: "1",
    label: "Horario del estudiante",
    icon: (
      <Flex>
        <Icon name="" size={24} />
      </Flex>
    ),
  },
  {
    key: "2",
    label: "Calendario académico",
    icon: (
      <Flex>
        <Icon name="" size={24} />
      </Flex>
    ),
  },
  {
    key: "3",
    label: "Todos",
    icon: (
      <Flex>
        <Icon name="" size={24} />
      </Flex>
    ),
  },
];

const Calendar = () => {
  const eventStartAccessor = (event: Event) => event?.start ?? new Date();
  const eventEndAccessor = (event: Event) => event?.end ?? new Date();
  const windowWidth = useMediaQuery("(max-width: 640px)");
  const [selectedCalendars, setSelectedCalendars] = React.useState<string[]>(["3"]);
  const [dropdownCalendarsOpen, setDropdownCalendarsOpen] = React.useState(false);
  const onClickCalendarDropdown: MenuProps["onClick"] = ({ key }) => {
    setSelectedCalendars((prevCalendars) => {
      return prevCalendars.includes(key)
        ? prevCalendars.filter((calendar) => calendar !== key)
        : [key];
    });
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentEventData, setCurrentEventData] = React.useState<Event>();

  const handleSelectEvent = (event: Event) => {
    setCurrentEventData(event);
    setIsModalOpen(true);
  };

  const [getCalendar, { data: googleCalendarInfo }] = useGetCalendarMutation();

  const googleCalendarInfoModeled = googleCalendarInfo?.map((event) => {
    return ModelGoogleCalendar.from?.(event);
  });

  const { data, isLoading } = useGetSubjectsCalendarQuery("");

  useEffect(() => {
    getCalendar("");
  }, [getCalendar]);

  const formatDate = React.useCallback((date: string) => {
    return dayjs(date).toDate();
  }, []);

  const mergedEvents: Event[] = [
    ...(selectedCalendars.includes("1") || selectedCalendars.includes("2")
      ? []
      : [
          ...(googleCalendarInfoModeled ?? []).map((e) => ({ ...e })),
          ...(data?.data?.map((e: any) => ({
            title: e.asignaturaNombre,
            key: `${e.asignaturaCodigo}${e.fechaFin}${e.fechaInicio}`,
            start: formatDate(e.fechaInicio),
            end: formatDate(e.fechaFin),
            isGoogleCalendar: false,
            backgroundColor: "#C9E1FF",
            color: "#00479B",
          })) || []),
        ]),
    ...(selectedCalendars.includes("1")
      ? data?.data?.map((e: any) => ({
          title: e.asignaturaNombre,
          key: `${e.asignaturaCodigo}${e.fechaFin}${e.fechaInicio}`,
          start: formatDate(e.fechaInicio),
          end: formatDate(e.fechaFin),
          isGoogleCalendar: false,
          backgroundColor: "#C9E1FF",
          color: "#00479B",
        })) || []
      : []),
    ...(selectedCalendars.includes("2")
      ? (googleCalendarInfoModeled ?? []).map((e) => ({ ...e }))
      : []),
  ];

  const items: TabsProps["items"] = [
    {
      key: "day",
      label: `Vista por día`,
      children: (
        <DesktopCalendar
          data={data}
          googleCalendarInfoModeled={googleCalendarInfoModeled}
          startAccessor={eventStartAccessor}
          endAccessor={eventEndAccessor}
          viewMode="day"
          localizer={localizer}
          selectedCalendars={selectedCalendars}
        />
      ),
    },
    {
      key: "week",
      label: `Vista por semana`,
      children: (
        <DesktopCalendar
          data={data}
          googleCalendarInfoModeled={googleCalendarInfoModeled}
          startAccessor={eventStartAccessor}
          endAccessor={eventEndAccessor}
          viewMode="week"
          localizer={localizer}
          selectedCalendars={selectedCalendars}
        />
      ),
    },
    {
      key: "month",
      label: `Vista por mes`,
      children: (
        <DesktopCalendar
          data={data}
          startAccessor={eventStartAccessor}
          googleCalendarInfoModeled={googleCalendarInfoModeled}
          endAccessor={eventEndAccessor}
          viewMode="month"
          localizer={localizer}
          selectedCalendars={selectedCalendars}
        />
      ),
    },
  ];

  return (
    <>
      <CalendarContainer>
        {windowWidth && (
          <SectionHeaderStyled>
            <CalendarText>Calendario</CalendarText>{" "}
          </SectionHeaderStyled>
        )}

        <Spin spinning={isLoading} size="large">
          {/* CALENDAR MOBILE */}
          {windowWidth ? (
            <>
              <ReactBigCalendar
                min={new Date(2024, 1, 0, 8, 0, 0)}
                eventPropGetter={(event) => {
                  return {
                    style: {
                      backgroundColor: `${event.backgroundColor}`,
                      color: `${event.color}`,
                    },
                  };
                }}
                culture="es"
                localizer={localizer}
                events={mergedEvents}
                style={{ height: 500, overflow: "hidden", borderRadius: 10 }}
                view={"day"}
                onSelectEvent={handleSelectEvent}
                messages={{
                  previous: "<",
                  next: ">",
                  today: "Hoy",
                  day: "Vista por día",
                  week: "Vista por semana",
                  month: "Vista por mes",
                }}
              />
              <Modal
                closable={false}
                centered
                open={isModalOpen}
                style={{ width: "100%" }}
                bodyStyle={{ paddingTop: 32 }}
                footer={
                  <>
                    <Button type="primary" onClick={() => setIsModalOpen(false)}>
                      Ok
                    </Button>
                  </>
                }
              >
                <ModalHead>
                  <Text style={{ color: "#fff", fontSize: 16, fontWeight: "bolder" }}>
                    {dayjs(currentEventData?.start).format("dddd, D MMMM, YYYY")}
                  </Text>
                </ModalHead>
                <ModalBody>
                  <TimeContainer>
                    <ModalText>{dayjs(currentEventData?.start).format("h:mm A")}</ModalText>
                    <ModalText>{dayjs(currentEventData?.end).format("h:mm A")}</ModalText>
                  </TimeContainer>
                  <ModalSubjectText style={{ maxWidth: "250px", textAlign: "right" }}>
                    {currentEventData?.title}
                  </ModalSubjectText>
                </ModalBody>
              </Modal>
            </>
          ) : (
            <Tabs
              tabBarStyle={{
                display: "flex",
                justifyContent: "space-between",
              }}
              tabBarExtraContent={{
                left: <CalendarText>Calendario</CalendarText>,
                right: (
                  <Dropdown
                    menu={{
                      items: calendarItems,
                      selectable: true,
                      multiple: false,
                      selectedKeys: selectedCalendars,
                      onClick: onClickCalendarDropdown,
                    }}
                    trigger={["click"]}
                    onOpenChange={setDropdownCalendarsOpen}
                    open={dropdownCalendarsOpen}
                  >
                    <Button
                      withIcon
                      type="primary"
                      size="large"
                      style={{ justifyContent: "space-between", maxWidth: 275 }}
                    >
                      Filtre por calendario
                      <Icon name="" />
                    </Button>
                  </Dropdown>
                ),
              }}
              defaultActiveKey="week"
              items={items}
            />
          )}
        </Spin>
      </CalendarContainer>
    </>
  );
};

interface IDesktopCalendarProps extends CalendarProps {
  viewMode: View;
  data: any;
  selectedCalendars: string[];
  googleCalendarInfoModeled:
    | (IGoogleCalendarCurrent | Promise<IGoogleCalendarCurrent> | null | undefined)[]
    | undefined;
}

const DesktopCalendar = ({
  data,
  startAccessor,
  endAccessor,
  viewMode,
  selectedCalendars,
  googleCalendarInfoModeled,
}: IDesktopCalendarProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isShowMoreModalOpen, setShowMoreModalOpen] = React.useState(false);
  const [currentEventData, setCurrentEventData] = React.useState<Event>();
  const [currentEventsData, setCurrentEventsData] = React.useState<Event[]>([]);

  const handleSelectEvent = (event: Event) => {
    setCurrentEventData(event);
    setIsModalOpen(true);
  };

  const handleShowMore = (events: Event[]) => {
    setCurrentEventsData(events);
    setShowMoreModalOpen(true);
  };

  const formatDate = React.useCallback((date: string) => {
    return dayjs(date).toDate();
  }, []);

  const mergedEvents: Event[] = [
    ...(selectedCalendars.includes("1") || selectedCalendars.includes("2")
      ? []
      : [
          ...(googleCalendarInfoModeled ?? []).map((e) => ({ ...e })),
          ...(data?.data?.map((e: any) => ({
            title: e.asignaturaNombre,
            key: `${e.asignaturaCodigo}${e.fechaFin}${e.fechaInicio}`,
            start: formatDate(e.fechaInicio),
            end: formatDate(e.fechaFin),
            isGoogleCalendar: false,
            backgroundColor: "#C9E1FF",
            color: "#00479B",
          })) || []),
        ]),
    ...(selectedCalendars.includes("1")
      ? data?.data?.map((e: any) => ({
          title: e.asignaturaNombre,
          key: `${e.asignaturaCodigo}${e.fechaFin}${e.fechaInicio}`,
          start: formatDate(e.fechaInicio),
          end: formatDate(e.fechaFin),
          isGoogleCalendar: false,
          backgroundColor: "#C9E1FF",
          color: "#00479B",
        })) || []
      : []),
    ...(selectedCalendars.includes("2")
      ? (googleCalendarInfoModeled ?? []).map((e) => ({ ...e }))
      : []),
  ];

  return (
    <CalendarBody>
      <Box style={{ background: " #fff", borderRadius: 10 }}>
        <ReactBigCalendar
          min={new Date(2024, 1, 0, 8, 0, 0)}
          eventPropGetter={(event) => {
            return {
              style: {
                backgroundColor: `${event.backgroundColor}`,
                color: `${event.color}`,
              },
            };
          }}
          culture="es"
          localizer={localizer}
          events={mergedEvents}
          startAccessor={startAccessor}
          endAccessor={endAccessor}
          style={{ height: 500, overflow: "hidden", borderRadius: 10 }}
          view={viewMode}
          onView={() => null}
          messages={{
            previous: "<",
            next: ">",
            showMore: (count) => `+${count} mostrar más`,
          }}
          onSelectEvent={handleSelectEvent}
          onShowMore={handleShowMore}
        />
      </Box>
      <Modal
        closable={false}
        centered
        open={isModalOpen}
        bodyStyle={{ paddingTop: 32 }}
        footer={
          <>
            <Button type="primary" onClick={() => setIsModalOpen(false)}>
              Ok
            </Button>
          </>
        }
      >
        <ModalHead>
          <Text style={{ color: "#fff", fontSize: 16, fontWeight: "bolder" }}>
            {dayjs(currentEventData?.start).format("dddd, D MMMM, YYYY")}
          </Text>
        </ModalHead>
        <ModalBody>
          <TimeContainer>
            <ModalText>{dayjs(currentEventData?.start).format("h:mm A")}</ModalText>
            <ModalText>{dayjs(currentEventData?.end).format("h:mm A")}</ModalText>
          </TimeContainer>
          <ModalSubjectText>{currentEventData?.title}</ModalSubjectText>
        </ModalBody>
      </Modal>
      <ModalShowMore
        closable={false}
        centered
        open={isShowMoreModalOpen}
        width="fit-content"
        bodyStyle={{ paddingTop: 32 }}
        footer={
          <>
            <Button type="primary" onClick={() => setShowMoreModalOpen(false)}>
              Ok
            </Button>
          </>
        }
      >
        <ModalHead>
          <Text style={{ color: "#fff", fontSize: 16, fontWeight: "bolder" }}>
            {currentEventsData.length &&
              dayjs(currentEventsData[0]?.start).format("dddd, D MMMM, YYYY")}
          </Text>
        </ModalHead>
        <ModalBody style={{ flexDirection: "column", alignItems: "flex-start" }}>
          {currentEventsData.length &&
            currentEventsData.map((e, i) => (
              <Flex
                key={`${e.start}.${e.end}.${i}`}
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 24,
                  gap: 32,
                }}
              >
                <TimeContainer>
                  <ModalText>{dayjs(e?.start).format("h:mm A")}</ModalText>
                  <ModalText>{dayjs(e?.end).format("h:mm A")}</ModalText>
                </TimeContainer>
                <ModalSubjectText>{e?.title}</ModalSubjectText>
              </Flex>
            ))}
        </ModalBody>
      </ModalShowMore>
    </CalendarBody>
  );
};

export default Calendar;
