import { useGetServicesMutation, useUpdateServicesMutation } from "src/services/servicesServices";
import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon, Select } from "@nubeteck/components";
import { ArrayUtils } from "@nubeteck/utils";
import { Layout, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { useGetPaymentTypesQuery } from "src/services";
import { IPaymentTypes } from "@/Interfaces/options";

const { Content } = Layout;

interface IServicio {
  servicioId: number;
  servicioNombre: string;
  plazoTipos: IPaymentTypes[];
  ids: number[];
}

const Services: React.FC = () => {
  const [form] = Form.useForm<IServicio>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [servicioId, setServicioId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [getServices, { data, isLoading }] = useGetServicesMutation();
  const [
    updateServices,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateServicesMutation();

  const { data: tiposPago } = useGetPaymentTypesQuery("");

  const onClickEditarTipoPago = React.useCallback(
    (record: IServicio) => {
      setServicioId(record.servicioId);
      form.setFieldsValue({
        ids: record.plazoTipos.map((pl: IPaymentTypes) => pl.plazoId),
      });
      setDrawerOpen(true);
    },
    [form],
  );

  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    if (errorUpdate) toast.error(errorUpdate?.data?.message, toastErrorStyle);
    if (isSuccessUpdate) {
      toast.success("Servicio actualizado correctamente", toastSuccessStyle);
      getServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate, errorUpdate]);

  const tiposPagoOptions = ArrayUtils.selectLabelValue(tiposPago?.data, "descripcion", "plazoId");

  const columns = React.useMemo<ColumnsType<IServicio>>(
    () => [
      {
        title: "Servicio",
        dataIndex: "servicioNombre",
      },
      {
        title: "¿Es obligatorio?",
        dataIndex: "esObligatorio",
        render: (esObligatorio) => {
          return esObligatorio ? "Si" : "No";
        },
      },
      {
        title: "Tipo de pago",
        dataIndex: "plazoTipos",
        render: (plazoTipos) => {
          return `${plazoTipos.map((pl: IPaymentTypes) => ` ${pl.descripcion}`)}`;
        },
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            className="cursor-pointer"
            key={record.servicioId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" />,
                  onClick: () => onClickEditarTipoPago(record ?? 0),
                },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarTipoPago],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <LargeHeadingStyled>Servicios</LargeHeadingStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IServicio>
            loading={isLoading || isLoadingUpdate}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => `${v.servicioId} ${v.servicioNombre}`}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title="Edición de servicio"
            open={drawerOpen}
            onClose={() => {
              setServicioId(0);
              setDrawerOpen(false);
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              onFinish={(data) => updateServices({ ...data, servicioId })}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
            >
              <Form.Item
                name="ids"
                label="Tipo de pago"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
              >
                <Select size="large" options={tiposPagoOptions} mode="multiple" allowClear />
              </Form.Item>

              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                onClick={() => setDrawerOpen(false)}
              >
                Aceptar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default Services;
