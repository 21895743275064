import ReactQuill from "react-quill";
import React, { RefObject, useEffect, useRef, useState } from "react";

import "react-quill/dist/quill.snow.css";
import { Button, Form, Modal, Select } from "antd";

const EditorInput = ({
  onChange,
  defaultValue,
  useVariables = false,
  variables,
}: {
  onChange: (value: string) => void;
  defaultValue: string;
  useVariables?: boolean;
  variables?: any[];
}) => {
  const [modalVariablesOpen, setModalvariablesOpen] = useState(false);
  const [variableSelected, setVariableSelected] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const quillRef: RefObject<ReactQuill> = useRef(null);

  // const varButton = document.createElement("button");
  // varButton.innerHTML = "variables";
  // varButton.id = "var-button";
  // varButton.addEventListener("click", () => setModalvariablesOpen(true));
  // varButton.style.width = "70px";
  // varButton.style.background = "#cedff3";

  // useEffect(() => {
  //   if (useVariables) {
  //     const toolbar = document.getElementsByClassName("ql-toolbar")[0];
  //     toolbar.appendChild(varButton);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [useVariables]);
  useEffect(() => {
    setValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const getOptionVariables = () => {
    const result: any[] = [];
    if (variables && variables?.length > 0)
      variables?.forEach((element) => result.push(...element.options));
    return result;
  };

  const handleOkVariables = () => {
    if (variableSelected !== "")
      quillRef.current?.editor?.insertText(
        quillRef.current?.editor.getLength() - 1,
        `{{${variableSelected}}}`,
      );

    setModalvariablesOpen(false);
    setVariableSelected("");
  };
  return (
    <>
      {useVariables && (
        <Button
          onClick={() => setModalvariablesOpen(true)}
          style={{
            width: 70,
            background: "#cedff3",
            margin: 5,
            position: "absolute",
            top: "-42px",
            right: 0,
          }}
        >
          Variables
        </Button>
      )}
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        style={{ backgroundColor: "white" }}
        onChange={(value) => {
          setValue(value);
          value !== undefined ? onChange(value) : null;
        }}
      />
      <Modal
        closable={false}
        open={modalVariablesOpen}
        onOk={handleOkVariables}
        okText="Insertar"
        onCancel={() => setModalvariablesOpen(false)}
        cancelText="Cancelar"
        style={{ maxWidth: 450 }}
      >
        <Form.Item name="variable" label="Variable a insertar">
          <Select
            onChange={(e) => setVariableSelected(e)}
            options={getOptionVariables()}
            allowClear
            value={variableSelected}
            placeholder="Variable"
          />
        </Form.Item>
      </Modal>
    </>
  );
};

export default EditorInput;
