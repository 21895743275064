import { MediumLargeHeadingStyled } from "src/components/custom/texts/texts.styled";
import { Input } from "@nubeteck/components";
import { Card, Form } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";

function StudentsImport({ isLoading, getData, data }: any) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [rowsCount, setRowsCount] = React.useState(5);
  const [studentName, setStudentName] = React.useState("");

  useEffect(() => {
    getData({ rowsCount, page: pageNumber, nombre: studentName });
  }, [studentName, rowsCount, pageNumber, getData]);

  const columns = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "No. identificación",
        dataIndex: "cedula",
      },
      {
        title: "Nombres",
        dataIndex: "nombres",
      },
      {
        title: "Apellidos",
        dataIndex: "apellidos",
      },
      {
        title: "Correo electrónico",
        dataIndex: "correoElectronico",
      },
      {
        title: "No. teléfono",
        dataIndex: "telefono",
      },
    ],
    [],
  );

  return (
    <>
      <MediumLargeHeadingStyled>Estudiantes importados</MediumLargeHeadingStyled>
      <Form.Item label="Búsqueda por nombre">
        <Input
          type="text"
          onChange={(e) => setStudentName(e.target.value)}
          placeholder="Búsqueda por nombre de estudiante"
        />
      </Form.Item>
      <Card bodyStyle={{ padding: 0, width: "100%" }}>
        <Table<any>
          loading={isLoading}
          columns={columns}
          dataSource={data?.data?.result}
          rowKey={(v) => v.cedula}
          pagination={{
            onChange(page, pageSize) {
              setPageNumber(page);
              setRowsCount(pageSize);
            },
            pageSize: rowsCount,
            current: pageNumber,
            total: data?.data?.pageCount * rowsCount,
            showSizeChanger: true,
            style: { marginRight: "30px" },
          }}
          scroll={{ x: 800 }}
        />
      </Card>
    </>
  );
}

export default StudentsImport;
