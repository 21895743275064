import { IGoogleCalendarIncomming } from "src/Interfaces/google-calendar";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const calendarServices = createApi({
  reducerPath: "calendarServices",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASEURL,

    prepareHeaders: (headers) => {
      const token = localStorage?.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCalendar: builder.mutation<IGoogleCalendarIncomming[], unknown>({
      query: () => {
        return {
          url: "GoogleCalendar/Events",
          method: "GET",
          headers: {
            "Cache-Control": "public, max-age=2628000",
          },
        };
      },
      transformResponse: (response: { data: IGoogleCalendarIncomming[] }) => response.data,
    }),
  }),
});

export const { useGetCalendarMutation } = calendarServices;
