/* eslint-disable @typescript-eslint/no-unused-vars */
import { decodeJwt } from "src/utils";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface IProtectedRoute {
  allowedRoles: string[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProtectedRoute = ({ allowedRoles }: IProtectedRoute) => {
  const [roles, setRoles] = React.useState<string[]>([]);

  function hasJWT() {
    let flag = false;
    //check user has JWT token
    const token = localStorage.getItem("token");

    if (token && token !== undefined) {
      const decoded = JSON.parse(decodeJwt(token));
      flag = true;
      if (roles?.length === 0) {
        if (typeof decoded.roles === "string") {
          const arr = [];
          arr.push(decoded.roles);
          setRoles(arr);
        } else {
          setRoles(decoded.roles);
        }
      }
    } else {
      flag = false;
    }
    return flag;
  }

  return hasJWT() ? (
    Array.isArray(roles) ? (
      roles?.find((x) => allowedRoles?.includes(x)) ? (
        <Outlet />
      ) : (
        <Navigate to="/required" state={roles} />
      )
    ) : allowedRoles.includes(roles) ? (
      <Outlet />
    ) : (
      <Navigate to="/required" state={roles} />
    )
  ) : (
    <Navigate to="/login" />
  );
  return <Outlet />;
};
export default ProtectedRoute;
