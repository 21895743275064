import React from "react";
import { Spin, Table } from "antd";
import { type ColumnsType } from "antd/es/table";
import { TableContainerStyled } from "./subject-data.styled";
import { type ISchedule, type ISection } from "@/Interfaces/subject";

interface ISubjectScheduleTable {
  secciones: ISection[] | undefined;
  isLoading: boolean;
}

export const SubjectTableSchedule = ({ secciones, isLoading }: ISubjectScheduleTable) => {
  const columns: ColumnsType<ISection> = [
    {
      title: "Sección",
      dataIndex: "asignaturaSeccionCodigo",
    },
    {
      title: "Horario",
      dataIndex: "horarios",
      render: (value) =>
        value
          ?.map((hr: ISchedule) => `${hr.diaNombre} ${hr.horaInicio} - ${hr.horaFin}`)
          .toString() ?? "-",
    },
    {
      title: "Modalidad",
      dataIndex: "modalidadNombre",
    },
    {
      title: "Precio",
      dataIndex: "seccionPrecio",
    },
    {
      title: "Profesor",
      dataIndex: "profesorNombre",
    },
    {
      title: "Sede",
      dataIndex: "sedeNombre",
    },
    {
      title: "Cupo",
      dataIndex: "cupo",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
    },
  ];

  return (
    <TableContainerStyled>
      <Spin spinning={isLoading} size="large">
        <Table
          columns={columns}
          dataSource={secciones}
          size="middle"
          bordered
          pagination={false}
          rowKey={(v) => `${v.asignaturaSeccionCodigo} ${v.seccionId}`}
        />
      </Spin>
    </TableContainerStyled>
  );
};
