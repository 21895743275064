import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Flex, Icon } from "@nubeteck/components";
import { FlexStyled } from "../../../components/registration/register-confirmation/confirmation.styled";
import {
  ButtonAlternativeStyled,
  ButtonPrimaryStyled,
  ButtonsContainer,
} from "../../../components/custom/buttons/buttons.styled";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Bill, CardsGroup, ResumeList, ScrollView } from "../../../components";
import toast from "react-hot-toast";
import { BillBoxStyled, BillFlexStyled } from "../../../components/billing/bill/bill.styled";
import NoResults from "src/assets/NoResults.svg";
import { Image, MenuProps, Modal, Skeleton, Form, Input, Select } from "antd";
import {
  DropdownSubIcon,
  ResumeCardStyled,
  ResumeFlexStyled,
} from "../../../components/billing/resume-card/resumeCard.styled";
import { toastErrorStyle, toastSuccessStyle, toastWarningStyle } from "src/constants";
import { ICartItem } from "src/Interfaces/cart";
import {
  CartHeadingStyled,
  CartResumeContainerStyled,
  FeesContainerStyled,
  ImageButtonContainerStyled,
  SpinnerContainerStyled,
} from "src/pages/student-pages/cart/cart.styled";
import {
  CardText,
  CardTitle,
  CardUserInvoiceName,
} from "src/components/reports/cards/report-cards.styled";
import { ArrayUtils, FormatterUtils } from "@nubeteck/utils";
import { PaymentSVG } from "src/assets";
import { useMediaQuery } from "src/hooks";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStudentsMutation } from "src/services";
import {
  useDeleteCartItemAdminMutation,
  useGetCartAdminMutation,
  useGetDatafonesQuery,
  useSaveBillAdminMutation,
  useSaveInvoicePersonDataAdminMutation,
} from "src/services/chargesSettingsServices";
import { useGetIsThereAPaymentInProcessMutation } from "src/services/cartServices";
import CartMoneyInfo from "src/components/billing/cart-money-info/cart-money-info";
import ThirdPersonForm from "src/pages/student-pages/cart/third-person-form";
declare global {
  interface Window {
    AlignetVPOS2: { openModal: (host: string, designID: number) => void };
  }
}

interface IRegisterStepProps {
  id: number;
  title: string;
  component: JSX.Element;
}
interface IFees {
  id: number;
  descripcion: string;
  saldo: number;
  moneda: number;
  fechaVencimiento: string;
}

export const ChargesSettings = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [totalPendiente, setTotalPendiente] = useState({ colones: 0, dolares: 0 });
  const [totalCuotas, setTotalCuotas] = useState(0);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [totalAmountCol, setTotalAmountCol] = useState(0);
  const [totalAmountDol, setTotalAmountDol] = useState(0);
  const [cargosMonedas, setCargosMonedas] = useState({ colones: false, dolares: false });
  const [cuotasPasadas, setCuotasPasadas] = useState<IFees[]>([]);
  const [modalInvoiceData, setModalInvoiceData] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({ id: 0, name: "" });
  const [paymentType, setPaymentType] = useState(0);
  const [noReferencia, setNoReferencia] = useState<string>();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const [getCart, { data: cartInfo, isLoading, errorGettingCart }] = useGetCartAdminMutation();
  const [getPaymentInProcess, { isLoading: isLoadingGettingPaymentInProcess }] =
    useGetIsThereAPaymentInProcessMutation();
  const [saveCart, { isLoading: isLoadingPayment, error: errorPayment }] =
    useSaveBillAdminMutation();
  const [
    deleteItem,
    { isLoading: isLoadingDeleting, isSuccess: isSuccessDeleting, isError: isErrorDeleting },
  ] = useDeleteCartItemAdminMutation();
  const [
    savePerson,
    { isLoading: isLoadingSavingPerson, isSuccess: isSuccesSavingPerson, error: errorSavingPerson },
  ] = useSaveInvoicePersonDataAdminMutation();

  const { isLoading: isLoadingPaymentTypes, data: paymentTypes } = useGetDatafonesQuery("");

  const [materiasCuotas, setMateriasCuotas] = useState<Array<{ id: number; costo: number }>>([]);
  const [form, setForm] = useState<any>({
    saldoAFavorAUsarColones: 0,
    saldoAFavorAUsarDolar: 0,
    cantidadCuotas: 0,
    montoTransferencia: 0,
    monedaTransferencia: 0,
  });

  const [getStudents, { data: students, isLoading: isLoadingStudents }] = useGetStudentsMutation();

  const { previous } = useParams();
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const tempMatCuotas = cartInfo?.data?.matriculasCuotas?.map((mat: any) => ({
      id: mat.matriculaId,
      costo: mat.costo,
    }));
    if (
      selectedStudent.id !== 0 &&
      JSON.stringify(tempMatCuotas ?? []) !== JSON.stringify(materiasCuotas ?? [])
    )
      getCart({ id: selectedStudent.id, materiasCuotas: materiasCuotas?.map((mat) => mat.id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materiasCuotas]);

  useEffect(() => {
    if (selectedStudent.id !== 0)
      getCart({ id: selectedStudent.id, materiasCuotas: materiasCuotas?.map((mat) => mat.id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleting, selectedStudent]);

  useEffect(() => {
    getStudents({
      rowsCount: 50,
      page: 1,
      estudianteNombre: "",
    });
  }, [getStudents]);

  useEffect(() => {
    setTotalCuotas(materiasCuotas?.map((mat) => mat.costo).reduce((prev, next) => prev + next, 0));
  }, [materiasCuotas]);

  useEffect(() => {
    if (cartInfo?.data?.matriculasCuotas)
      setMateriasCuotas(
        cartInfo?.data?.matriculasCuotas?.map((mat: any) => ({
          id: mat.matriculaId,
          costo: mat.costo,
        })),
      );
    setCuotasPasadas(cartInfo?.data?.saldosNoVencidos);
    if (
      cartInfo?.data?.asignaturas?.length === 0 &&
      cartInfo?.data?.servicios?.length === 0 &&
      (cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 ||
        cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 ||
        cartInfo?.data?.dolares?.deuda?.saldoVencido > 0)
    ) {
      setCurrentStep(1);
    }
    const infoColones = cartInfo?.data?.colones;
    const infoDolares = cartInfo?.data?.dolares;

    setTotalPendiente({
      colones:
        infoColones?.deuda?.costoTotal -
        infoColones?.descuento?.totalDescuentoBeca -
        infoColones?.descuento?.totalDescuentoPatrocinio,
      dolares:
        infoDolares?.deuda?.costoTotal -
        infoDolares?.descuento?.totalDescuentoBeca -
        infoDolares?.descuento?.totalDescuentoPatrocinio,
    });
    setCargosMonedas({
      colones:
        infoColones?.deuda?.saldoNoVencido > 0 ||
        infoColones?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.asignaturas.find((as: ICartItem) => as.monedaId === 2) !== undefined ||
        cartInfo?.data?.servicios.find((ser: ICartItem) => ser.monedaId === 2) !== undefined,
      dolares:
        infoDolares?.deuda?.saldoNoVencido > 0 ||
        infoDolares?.deuda?.saldoVencido > 0 ||
        cartInfo?.data?.asignaturas.find((as: ICartItem) => as.monedaId === 1) !== undefined ||
        cartInfo?.data?.servicios.find((ser: ICartItem) => ser.monedaId === 1) !== undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartInfo]);

  useEffect(() => {
    if (errorPayment) {
      toast.error(errorPayment?.data?.message, toastErrorStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPayment]);
  useEffect(() => {
    if (errorGettingCart) {
      toast.error(errorGettingCart?.data?.message, toastErrorStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGettingCart]);

  useEffect(() => {
    if (errorSavingPerson) {
      toast.error(errorSavingPerson?.data?.message, toastErrorStyle);
    }
    if (isSuccesSavingPerson) {
      toast.success("¡Operación realizada correctamente!", toastSuccessStyle);
      getCart({ id: selectedStudent.id, materiasCuotas });
      setModalInvoiceData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSavingPerson, isSuccesSavingPerson]);

  useEffect(() => {
    if (isErrorDeleting) {
      toast.error("Ha ocurrido un error. Intente de nuevo", toastErrorStyle);
    }
    if (isSuccessDeleting) {
      toast.success("¡Operación realizada correctamente!", toastSuccessStyle);
    }
  }, [isSuccessDeleting, isErrorDeleting]);

  const studentsOptions = ArrayUtils.selectLabelValue(
    students?.data?.result ?? [],
    "estudianteNombre",
    "estudianteId",
  );
  const paymentOptions = ArrayUtils.selectLabelValue(
    paymentTypes?.data ?? [],
    "verifoneName",
    "verifoneId",
  );

  const itemsSubjects: (data: any) => MenuProps["items"] = (data) => [
    {
      key: "1",
      label: "Eliminar",
      icon: <DropdownSubIcon name="" color="#c4c1c1" />,
      onClick: () => {
        confirmDelete(
          { itemId: data.seccionId, esAsignatura: true },
          data.nombre,
          data.matriculaId,
        );
      },
    },
  ];

  const itemsServices: (data: any) => MenuProps["items"] = (data) =>
    !data.esObligatorio
      ? [
          {
            key: "2",
            label: "Eliminar",
            icon: <DropdownSubIcon name="" color="#c4c1c1" />,
            onClick: () => {
              confirmDelete({ itemId: data.servicioId, esAsignatura: false }, data.nombre);
            },
          },
        ]
      : undefined;

  const confirmDelete = (data: any, name: string, matriculaId?: number) => {
    modal.confirm({
      title: `Remover ${data.esAsignatura ? "asignatura" : "servicio"}`,
      icon: <Icon name="" color="#FFC53D" />,
      content: `¿Es seguro que desea remover de la factura ${
        data.esAsignatura ? "la asignatura" : "el servicio"
      } ${name}?`,
      okText: "Aceptar",
      onOk: () => {
        deleteItem({ ...data, peopleId: selectedStudent.id });
        setMateriasCuotas(materiasCuotas?.filter((mat) => mat.id !== matriculaId));
      },
      cancelText: "Cancelar",
      style: { top: 200 },
    });
  };

  const displayWarning = () => {
    toast(
      <div>
        <h3 style={toastWarningStyle.style}>
          NO CIERRE EL NAVEGADOR HASTA QUE SE TRAMITE SU TRANSACCIÓN.
        </h3>
        <p>
          Una vez procesado el pago recibirá un correo con los detalles del recibo. El estudiante
          recibirá una copia por correo.
        </p>
      </div>,
      {
        duration: 5000,
        style: {
          padding: "30px",
          fontSize: 17,
          height: "100%",
          fontWeight: 700,
          boxShadow: "0 0 0 50vmax rgba(0,0,0,.5)",
        },
      },
    );
  };

  const getIsPaymentFieldsVisible = () => {
    if (totalAmountCol > 0.1 || totalAmountDol > 0.1) return true;
  };

  const obtenerValidaciones = async (currency: number) => {
    const validacionPagoEnProceso = await getPaymentInProcess(selectedStudent.id).then(
      (res: any) => {
        if (res?.data?.data === false) {
          return true;
        } else {
          return false;
        }
      },
    );

    const saldoAFavorAUsar =
      currency === 188 ? form.saldoAFavorAUsarColones : form.saldoAFavorAUsarDolar;
    const total = currency === 188 ? totalAmountCol : totalAmountDol;
    const pendiente = currency === 188 ? totalPendiente.colones : totalPendiente.dolares;

    let error = null;
    if (total > 0.1) {
      if (paymentType == 0)
        error = "Debe seleccionar un tipo de datáfono para poder efectuar el pago.";
      if (!noReferencia) error = "El número de referencia del pago es requerido.";
    }

    if (error === null) {
      if (!validacionPagoEnProceso) {
        modal.confirm({
          title: `Este estudiante tiene un pago que aún está siendo procesado.`,
          icon: <Icon name="" color="#FFC53D" />,
          content: `¿Está seguro(a) de que desea continuar y realizar un nuevo pago?`,
          okText: "Continuar",
          onOk: () => {
            realizarPago(total, pendiente, saldoAFavorAUsar, currency);
          },
          cancelText: "Cancelar",
          style: { top: 200 },
        });
      } else {
        realizarPago(total, pendiente, saldoAFavorAUsar, currency);
      }
    } else {
      toast(error, toastErrorStyle);
    }
  };

  const realizarPago = (
    total: number,
    pendiente: number,
    saldoAFavorAUsar: number,
    monedaId: number,
  ) => {
    displayWarning();
    saveCart({
      peopleId: selectedStudent.id,
      data: {
        verifoneId: paymentType,
        saldoAFavorAUsar: saldoAFavorAUsar,
        cantidadCuotas:
          cartInfo?.data?.numeroCuotas >= 1 && materiasCuotas.length > 0
            ? cartInfo?.data?.numeroCuotas
            : 0,
        saldoNoVencidoAPagar: total - pendiente > 0 ? total - pendiente : 0,
        totalPago: total.toFixed(2),
        totalPagoTransferencia: form.montoTransferencia,
        monedaId,
        monedaTransferencia: form.monedaTransferencia,
        matriculasEnCuotasInt: materiasCuotas.map((mat) => mat.id),
        referencia: noReferencia,
      },
    }).then((res: any) => {
      if (res?.data?.responseStatus == "Success") {
        toast.success("Pago realizado correctamente", toastSuccessStyle);
        navigate(`/last-receipt/${cartInfo?.data?.pagoIdActual}/${selectedStudent.id}`);
      }
    });
  };

  const marcarParaCuotas = (matriculaId: number, costo: number) => {
    if (materiasCuotas?.find((mat) => mat.id === matriculaId)) {
      setMateriasCuotas(materiasCuotas?.filter((mat) => mat.id !== matriculaId));
    } else {
      setMateriasCuotas(materiasCuotas?.concat([{ id: matriculaId, costo }]));
    }
  };

  const changePastFeesGettingPaid = (monto: number, moneda: number) => {
    if (cuotasPasadas?.length > 0) {
      let resto = monto;
      const cuotasTemp: IFees[] = [];

      cartInfo?.data?.saldosNoVencidos?.forEach((fee: IFees) => {
        let saldo = fee.saldo;
        if (resto > 0 && fee.moneda === moneda) {
          saldo = saldo - resto;
          if (saldo < 0) {
            resto = saldo * -1;
            saldo = 0;
          } else {
            resto = 0;
          }
        }
        cuotasTemp.push({ ...fee, saldo });
      });

      setCuotasPasadas(cuotasTemp);
    }
  };

  const invoiceHeader = (
    <>
      <CardsGroup
        userInfoCustom={{ fullName: selectedStudent?.name, id: cartInfo?.data?.matriculaId }}
        extraContent={
          <>
            <CardUserInvoiceName direction="vertical">
              <CardTitle style={{ fontSize: "18px" }}>Factura a nombre de</CardTitle>
              <Flex>
                <CardText>
                  {isLoading ? "...Cargando" : cartInfo?.data?.estudianteTercero?.terceroNombre}
                </CardText>{" "}
                <Button
                  disabled={isLoading}
                  size="small"
                  type="link"
                  onClick={() => {
                    setModalInvoiceData(true);
                  }}
                >
                  Cambiar
                </Button>
              </Flex>
            </CardUserInvoiceName>
            <CartMoneyInfo
              cargosMonedas={cargosMonedas}
              totalCuotas={totalCuotas}
              totalPendiente={totalPendiente}
              reglaEstimacion={cartInfo?.data?.regla?.reglaEstimada}
            />

            <ThirdPersonForm
              initialValues={cartInfo?.data?.estudianteTercero}
              open={modalInvoiceData}
              savePerson={(values: any) => savePerson({ ...values, peopleId: selectedStudent.id })}
              isLoading={isLoadingSavingPerson}
              onCancel={() => {
                setModalInvoiceData(false);
              }}
            />
          </>
        }
      />
    </>
  );

  const steps: IRegisterStepProps[] = [
    {
      id: 0,
      title: `Carrito del estudiante ${selectedStudent?.name?.split("-")[0]}`,
      component: (
        <>
          {invoiceHeader}

          <FlexStyled>
            <CartResumeContainerStyled>
              {isLoading || isLoadingPayment || isLoadingDeleting ? (
                <>
                  <ResumeCardStyled>
                    <ResumeFlexStyled>
                      <Skeleton.Image active />
                      <Skeleton loading={true} active round title={false} />
                    </ResumeFlexStyled>
                  </ResumeCardStyled>
                  <br />
                  <ResumeCardStyled>
                    <ResumeFlexStyled>
                      <Skeleton.Image active />
                      <Skeleton loading={true} active round title={false} />
                    </ResumeFlexStyled>
                  </ResumeCardStyled>
                </>
              ) : (!cartInfo?.data?.asignaturas && !cartInfo?.data?.servicios) ||
                (cartInfo?.data?.asignaturas?.length == 0 &&
                  cartInfo?.data?.servicios?.length == 0) ? (
                <>
                  <SpinnerContainerStyled>
                    <LargeHeadingStyled>Sin cargos o servicios por pagar</LargeHeadingStyled>
                  </SpinnerContainerStyled>
                </>
              ) : null}
              {cartInfo?.data?.asignaturas?.length > 0 && (
                <ResumeList
                  programas={cartInfo?.data?.carreras}
                  isLoading={isLoading}
                  marcarParaCuotas={marcarParaCuotas}
                  viewHeight="40vh"
                  title="Materia(s) seleccionada(s):"
                  listType="subject"
                  listItems={
                    cartInfo?.data?.asignaturas.map((a: ICartItem & { carreraId: number }) => ({
                      ...a,
                      reglaTipoId: cartInfo?.data?.regla?.reglaTipoId,
                      programaId: a.carreraId,
                      pagaCuotas:
                        materiasCuotas.find((mat) => mat.id == a.matriculaId) !== undefined,
                    })) as ICartItem[]
                  }
                  items={itemsSubjects}
                />
              )}
              <br />
              {cartInfo?.data?.servicios?.length > 0 && (
                <ResumeList
                  isLoading={isLoading}
                  viewHeight="30vh"
                  title="Servicios:"
                  listType="service"
                  listItems={cartInfo?.data?.servicios as ICartItem[]}
                  items={itemsServices}
                />
              )}
              <br />
            </CartResumeContainerStyled>
            <ImageButtonContainerStyled>
              <Image src={PaymentSVG} width={300} preview={false} style={{ margin: "50px 0px" }} />
              <br />
              <br />
              <ButtonsContainer>
                {previous !== "general" && (
                  <ButtonAlternativeStyled
                    onClick={() =>
                      navigate(
                        previous === "selection"
                          ? `/subjects-registration/${
                              cartInfo?.data?.carreras?.length == 1
                                ? cartInfo?.data?.carreras[0].carreraId
                                : "initial"
                            }`
                          : "/requests",
                      )
                    }
                    type="default"
                    size="large"
                  >
                    {" "}
                    Atrás
                  </ButtonAlternativeStyled>
                )}
                {(cartInfo?.data?.asignaturas?.length !== 0 ||
                  cartInfo?.data?.servicios?.length !== 0 ||
                  cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 ||
                  cartInfo?.data?.colones?.deuda?.saldoVencido > 0 ||
                  cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 ||
                  cartInfo?.data?.dolares?.deuda?.saldoVencido > 0) && (
                  <ButtonPrimaryStyled
                    type="primary"
                    disabled={isLoading}
                    size="large"
                    onClick={() => setCurrentStep(currentStep + 1)}
                  >
                    Siguiente
                  </ButtonPrimaryStyled>
                )}
              </ButtonsContainer>
            </ImageButtonContainerStyled>
          </FlexStyled>
        </>
      ),
    },
    {
      id: 1,
      title: `Caja del estudiante ${selectedStudent.name}`,
      component: (
        <>
          {invoiceHeader}
          <FlexStyled>
            <div>
              <Image src={PaymentSVG} width={400} preview={false} style={{ margin: "100px 0px" }} />
            </div>
            <FlexStyled style={{ gap: "20px" }}>
              {cuotasPasadas?.length > 0 && (
                <ScrollView
                  height={"710px"}
                  style={{ width: "100%", minWidth: "120px", textAlign: "center" }}
                >
                  <>
                    <SmallHeadingStyled style={{ textAlign: "center" }}>
                      Cuotas pendientes
                    </SmallHeadingStyled>
                    {cuotasPasadas.map((fee) => (
                      <FeesContainerStyled key={fee.id}>
                        <h5>{fee.descripcion}</h5>
                        <span>
                          {fee.moneda === 2 ? "₡" : "$"}
                          {FormatterUtils.number(fee.saldo.toFixed(2))}
                        </span>
                        <br />
                        <br />
                        <span style={{ color: "#00479B", fontSize: "12px" }}>
                          Vence: {new Date(fee.fechaVencimiento).toLocaleDateString()}
                        </span>
                      </FeesContainerStyled>
                    ))}
                  </>
                </ScrollView>
              )}
              <BillBoxStyled>
                <Bill
                  hayMateriasOServicios={
                    cartInfo?.data?.asignaturas?.length > 0 || cartInfo?.data?.servicios?.length > 0
                  }
                  isOnAdmin={true}
                  conversion={cartInfo?.data?.conversion}
                  limiteCuotas={cartInfo?.data?.numeroCuotas}
                  actualizarPagosCuotas={changePastFeesGettingPaid}
                  hayCargosEnColones={cargosMonedas.colones}
                  hayCargosEnDolares={cargosMonedas.dolares}
                  dataTransferencia={
                    cartInfo?.data?.colones?.descuento?.transferencia ??
                    cartInfo?.data?.dolares?.descuento?.transferencia
                  }
                  setTotalAmountColones={(num: number) => {
                    setTotalAmountCol(num);
                  }}
                  setTotalAmountDolares={(num: number) => {
                    setTotalAmountDol(num);
                  }}
                  totalPendienteColones={totalPendiente.colones}
                  totalPendienteDolares={totalPendiente.dolares}
                  setTotalPendienteColones={(num: number) =>
                    setTotalPendiente({ ...totalPendiente, colones: num })
                  }
                  setTotalPendienteDolares={(num: number) =>
                    setTotalPendiente({ ...totalPendiente, dolares: num })
                  }
                  totalCuotas={totalCuotas}
                  pagoCuotas={materiasCuotas?.length > 0}
                  setValue={(obj) => setForm({ ...form, ...obj })}
                  billColones={{
                    ...cartInfo?.data?.colones.deuda,
                    ...cartInfo?.data?.colones.descuento,
                    ...cartInfo?.data?.colones.pago,
                  }}
                  billDolares={{
                    ...cartInfo?.data?.dolares.deuda,
                    ...cartInfo?.data?.dolares.descuento,
                    ...cartInfo?.data?.dolares.pago,
                  }}
                />
                <br />

                <form ref={formRef} method="POST" className="alignet-form-vpos2">
                  {getIsPaymentFieldsVisible() ? (
                    <>
                      <Form.Item label="No. de referencia">
                        <Input
                          placeholder="No. de referencia"
                          onChange={(e) => {
                            setNoReferencia(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label="Seleccione el método de pago">
                        <Select
                          style={{ minWidth: "240px" }}
                          options={paymentOptions}
                          placeholder="Datáfono"
                          allowClear
                          loading={isLoadingPaymentTypes}
                          onChange={(e) => {
                            setPaymentType(e);
                          }}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                  <ButtonsContainer>
                    <ButtonAlternativeStyled
                      onClick={() => setCurrentStep(currentStep - 1)}
                      type="default"
                      size="large"
                    >
                      {" "}
                      Atrás
                    </ButtonAlternativeStyled>
                    {cargosMonedas.colones && (
                      <>
                        {totalAmountCol < 0.1 &&
                        (cartInfo?.data?.asignaturas?.length > 0 ||
                          cartInfo?.data?.servicios?.length > 0 ||
                          (cartInfo?.data?.colones?.deuda?.saldoNoVencido > 0 &&
                            (form.saldoAFavorAUsarColones ?? 0) > 0) ||
                          (cartInfo?.data?.colones?.deuda?.saldoVencido > 0 &&
                            (form.saldoAFavorAUsarColones ?? 0) > 0)) ? (
                          <ButtonPrimaryStyled
                            type="primary"
                            disabled={isLoading}
                            size="large"
                            onClick={() => obtenerValidaciones(188)}
                            loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                          >
                            Terminar ₡
                          </ButtonPrimaryStyled>
                        ) : totalAmountCol > 0.1 ? (
                          <ButtonPrimaryStyled
                            type="primary"
                            disabled={isLoading}
                            size="large"
                            onClick={() => obtenerValidaciones(188)}
                            loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                          >
                            {cartInfo?.data?.hayCargosEnDolares
                              ? "Pagar cargos en colones"
                              : "Pago con tarjeta de débito/crédito"}
                          </ButtonPrimaryStyled>
                        ) : null}
                      </>
                    )}

                    {cargosMonedas.dolares && (
                      <>
                        {totalAmountDol < 0.1 &&
                        (cartInfo?.data?.asignaturas?.length > 0 ||
                          cartInfo?.data?.servicios?.length > 0 ||
                          (cartInfo?.data?.dolares?.deuda?.saldoNoVencido > 0 &&
                            (form.saldoAFavorAUsarDolar ?? 0) > 0) ||
                          (cartInfo?.data?.dolares?.deuda?.saldoVencido > 0 &&
                            (form.saldoAFavorAUsarDolar ?? 0) > 0)) ? (
                          <ButtonPrimaryStyled
                            type="primary"
                            size="large"
                            loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                            onClick={() => obtenerValidaciones(840)}
                          >
                            Terminar $
                          </ButtonPrimaryStyled>
                        ) : totalAmountDol > 0.1 ? (
                          <ButtonPrimaryStyled
                            type="primary"
                            size="large"
                            loading={isLoadingPayment || isLoadingGettingPaymentInProcess}
                            onClick={() => obtenerValidaciones(840)}
                          >
                            {cargosMonedas.colones
                              ? "Pagar cargos en dólares"
                              : "Pago con tarjeta de débito/crédito"}
                          </ButtonPrimaryStyled>
                        ) : null}
                      </>
                    )}
                  </ButtonsContainer>
                </form>
              </BillBoxStyled>
            </FlexStyled>
          </FlexStyled>
        </>
      ),
    },
  ];

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Box style={{ maxWidth: 1440 }}>
      <BillFlexStyled>
        {" "}
        <Form.Item label="Buscar estudiante por nombre o ID">
          <Select
            options={studentsOptions}
            filterOption={filterOption}
            placeholder="Búsqueda de estudiantes"
            showSearch
            onSearch={(e) =>
              getStudents({
                rowsCount: 20,
                page: 1,
                estudianteNombre: e,
              })
            }
            optionFilterProp="children"
            allowClear
            loading={isLoadingStudents}
            onChange={(e) => {
              setCurrentStep(0);
              setSelectedStudent({
                id: e,
                name: students.data?.result?.find((st: any) => st.estudianteId == e)
                  ?.estudianteNombre,
              });
            }}
          />
        </Form.Item>
      </BillFlexStyled>
      {selectedStudent.id !== 0 ? (
        <>
          <SectionHeaderStyled style={{ height: isMobile ? "75px" : "35px", padding: 0 }}>
            <CartHeadingStyled>
              {steps.find((step) => step.id === currentStep)?.title}
            </CartHeadingStyled>
          </SectionHeaderStyled>

          {steps.find((st) => st.id === currentStep)?.component}
          {contextHolder}
        </>
      ) : (
        <Flex style={{ width: "100%", alignItems: "center", flexDirection: "column" }}>
          <SmallHeadingStyled>
            Seleccione un estudiante para mostrar los cargos correspondientes
          </SmallHeadingStyled>
          <Image src={NoResults} preview={false} alt="Ilustración sin resultados" width={200} />
        </Flex>
      )}
    </Box>
  );
};

export default ChargesSettings;
