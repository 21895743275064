import { Card, Flex, Heading, Tabs } from "@nubeteck/components";
import styled from "styled-components";

export const ReportsContainer = styled(Flex)`
  width: 100%;
  max-width: 1440px;
  flex-direction: column;
`;

export const ReportsHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.colorAccent} !important;
`;
export const ReportsTabs = styled(Tabs)`
  & > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: end;
  }
`;

export const MessageCardStyled = styled(Card)`
  background-color: ${({ theme }) => theme.colors.colorAccent};
  max-width: 28%;
  .ant-card-body {
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    & > div {
      width: 70%;
    }
  }
`;
export const ReportsContainertyled = styled(Card)`
  min-height: 500px;
`;
export const ReportsHeaderStyled = styled(Card)`
  margin-bottom: 15px;
  .ant-card-body {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
  }
`;
export const ReportsFlexContainerStyled = styled(Flex)`
  gap: 40px;
  align-items: start;
`;
export const ReportsInputsContainerStyled = styled(Flex)`
  align-items: center;
  gap: 10px;
`;
