import { IItemsFacturacion } from "@/Interfaces/cart";
import { useState, useEffect } from "react";

export default function useCalculateBill(
  bill: IItemsFacturacion,
  totalPendiente: number,
  totalCuotas: number,
  saldoParaPagar: number,
  montoTransferencia: number,
  setTotalPendiente: (t: number) => void,
  setTotalAmount: (t: number) => void,
) {
  const [subtotal, setSubtotal] = useState(bill?.costoTotal);
  const [totalDescontado, setTotalDescontado] = useState(
    bill?.totalDescuentoBeca + bill?.totalDescuentoPatrocinio + saldoParaPagar,
  );
  const [totalAhora, setTotalAhora] = useState(0);
  const [totalLuego, setTotalLuego] = useState(0);

  useEffect(() => {
    if (bill?.totalDescuentoBeca > 0 || bill?.totalDescuentoPatrocinio > 0 || saldoParaPagar > 0) {
      setTotalDescontado(
        bill?.totalDescuentoBeca + bill?.totalDescuentoPatrocinio + saldoParaPagar,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill?.totalDescuentoBeca, bill?.totalDescuentoPatrocinio, saldoParaPagar]);

  useEffect(() => {
    setSubtotal(bill?.costoTotalAsignaturas + bill?.costoTotalOtrosCargos + bill?.saldoVencido);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bill?.costoTotalOtrosCargos,
    bill?.costoTotalAsignaturas,
    bill?.saldoVencido,
    bill?.limiteCuota,
  ]);

  useEffect(() => {
    setTotalPendiente(subtotal - totalDescontado > 0 ? subtotal - totalDescontado : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDescontado, subtotal]);

  useEffect(() => {
    const totalObligatorio = totalPendiente - totalCuotas;
    const totalAPagarSinObligatorio = totalAhora - totalObligatorio;

    const totalAhoraSinSaldoNoVencido = totalAPagarSinObligatorio - bill?.saldoNoVencido;

    if (totalAhoraSinSaldoNoVencido > 0) {
      if (totalAhora > totalPendiente + bill?.saldoNoVencido) {
        setTotalLuego(0);
      } else {
        setTotalLuego(Math.abs(totalAhoraSinSaldoNoVencido - totalCuotas));
      }
    } else if (totalCuotas > 0) setTotalLuego(totalCuotas);
  }, [
    totalPendiente,
    totalAhora,
    totalCuotas,
    montoTransferencia,
    saldoParaPagar,
    bill?.saldoNoVencido,
  ]);

  useEffect(() => {
    setTotalAhora(totalPendiente - totalCuotas - montoTransferencia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCuotas, totalPendiente, montoTransferencia]);

  useEffect(() => {
    setTotalAmount(totalAhora);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAhora]);

  return { totalLuego, totalDescontado, subtotal, totalAhora, setTotalAhora };
}
