import React from "react";

function useDownloadFile() {
  const [loadingDownload, setLoadingDownload] = React.useState(false);

  function downloadFile(url: string, name: string, method: "POST" | "GET", data?: any) {
    setLoadingDownload(true);
    fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    })
      .then((res) => res.blob())
      .then((res) => {
        const aElement = document.createElement("a");
        const fileName = `${name} ${+new Date()}`;
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
        setLoadingDownload(false);
      });
  }

  return { downloadFile, loadingDownload };
}

export default useDownloadFile;
