import React from "react";
import { Flex, Heading, Form, Button } from "@nubeteck/components";
import {
  useGetGeneralSettingsMutation,
  useResetGeneralSettingsMutation,
  useSaveGeneralSettingsMutation,
} from "src/services/generalSettingsServices";
import ConfigurationInput from "./settingsInput";
import { Collapse, CollapseProps, Spin } from "antd";
import toast from "react-hot-toast";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import { ISettingsSection } from "src/Interfaces/generalSettings";
import { themeColors } from "src/core";
import { getFormData } from "src/utils/files-util";
// import settingsImg from "src/assets/Settings.svg";

const GeneralSettingsView = () => {
  const [getSettings, { data: configuraciones, isLoading: isLoadingSettings }] =
    useGetGeneralSettingsMutation();
  const [
    saveSettings,
    { isLoading: isLoadingSaving, isSuccess: isSuccessSaving, isError: isErrorSaving },
  ] = useSaveGeneralSettingsMutation();
  const [
    resetSettings,
    { isLoading: isLoadingDefault, isSuccess: isSuccessDefault, isError: isErrorDefault },
  ] = useResetGeneralSettingsMutation();

  const [form] = Form.useForm<any>();

  React.useEffect(() => {
    getSettings();
  }, [getSettings]);

  React.useEffect(() => {
    if (isErrorSaving) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessSaving) {
      toast.success("Configuraciones actualizadas exitósamente", toastSuccessStyle);
      getSettings();
    }
  }, [isSuccessSaving, form, isErrorSaving, getSettings]);

  React.useEffect(() => {
    if (isErrorDefault) toast.error("Ha ocurrido un error almacenando los datos.", toastErrorStyle);

    if (isSuccessDefault) {
      getSettings();
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
    }
  }, [isSuccessDefault, form, isErrorDefault, getSettings]);

  const submit = (data: any) => {
    saveSettings(getFormData(data));
  };

  const items: CollapseProps["items"] = React.useMemo(
    () =>
      configuraciones?.data?.map((item: ISettingsSection) => ({
        key: item.id,
        label: item.seccion,
        children: item?.configuraciones?.map((field) => {
          form.setFieldValue(field.nombre, field.valor);
          return (
            <div key={field.id}>
              <Form.Item
                rules={[{ required: field.requerido }]}
                label={field.label}
                name={field.nombre}
              >
                <ConfigurationInput
                  data={field}
                  onChange={(value) => form.setFieldValue(field.nombre, value)}
                />
              </Form.Item>
              <Button
                type="text"
                onClick={() => resetSettings(field.nombre)}
                style={{ color: themeColors.colorAccent, fontWeight: "bold" }}
              >
                Reestablecer a su valor por defecto
              </Button>
              <br />
              <br />
            </div>
          );
        }),
      })),
    [configuraciones?.data, form, resetSettings],
  );

  return (
    <Flex style={{ width: "100%", flexDirection: "column", maxWidth: "1440px" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={submit}
        noValidate
        style={{ display: "flex", flexDirection: "column", gap: 16 }}
      >
        <Heading level={4}>{"Configuraciones generales"}</Heading>
        <Spin spinning={isLoadingSettings || isLoadingSaving || isLoadingDefault} size="large">
          {configuraciones?.data && (
            <Collapse
              bordered={false}
              items={items}
              defaultActiveKey={["1"]}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            />
          )}
          <br />
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ alignSelf: "flex-end" }}
            loading={isLoadingSaving}
          >
            Guardar
          </Button>
        </Spin>
      </Form>
    </Flex>
  );
};

export default GeneralSettingsView;
