import React from "react";
import { useFormContext } from "react-hook-form";
import { Box, Icon, Tag } from "@nubeteck/components";
import {
  CardCalendarSubjectsStyled,
  ErrorTableMessage,
  SubjectButtonStyled,
  SubjectsContainerStyled,
  SubjectsListStyled,
  TabContainerStyled,
} from "./register-selection.styled";
import { type TabsProps, Button, Modal, Skeleton } from "antd";
import {
  SubjectCalendarSchedule,
  SubjectDescriptionDisplay,
  SubjectTableSchedule,
} from "../../registration";
import ButtonSubject from "./button-subject";
import { ISection, ISelectedSubject, ISubject } from "src/Interfaces/subject";
import { horas } from "../../../constants";
import { useAppDispatch, useAppSelector } from "src/core/store";
import { setSubjectCodeForChange } from "src/core/store/slices/subjectSelectionSlice";
import useManageSubjects from "./hooks/useManageSubjects";
import NoSubjectsText from "./noSubjectsText";
import { FormatterUtils } from "@nubeteck/utils";

const RegisterSelection = ({
  subjectsData,
  isLoading,
  updateSubjects,
  selectedData,
  registeredSubjects,
  periodId,
  setSufficiencyDrawerVisible,
}: {
  subjectsData: ISubject[];
  isLoading: boolean;
  updateSubjects: () => void;
  selectedData: any[];
  registeredSubjects: any[];
  periodId: number;
  setSufficiencyDrawerVisible: () => void;
}) => {
  const { setValue, getValues } = useFormContext();
  const dispatch: any = useAppDispatch();
  const { subjectCodeForChange } = useAppSelector((state: any) => state.subjectSelection);
  const [modal, contextHolder] = Modal.useModal();
  const [selectedSubject, setSelectedSubject] = React.useState<ISelectedSubject | undefined>();
  const [subjects, setSubjects] = React.useState<ISubject[]>([]);

  const esMatriculaAdministrativa = localStorage.getItem("matriculaAdministrativa") === "true";
  React.useEffect(() => {
    setSubjects(subjectsData);

    if (selectedSubject) {
      const subSelected = subjectsData?.find(
        (sub) => sub.asignaturaId == selectedSubject?.asignaturaId,
      );
      setSelectedSubject({
        ...selectedSubject,
        secciones: subSelected?.secciones?.map((sec: ISection) => ({
          ...sec,
        })) as ISection[],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsData]);

  React.useEffect(() => {
    const subjectForChange = subjects?.find((sub) => sub.asignaturaCodigo === subjectCodeForChange);
    if (subjectForChange)
      setSelectedSubject({
        ...subjectForChange,
        seccionId: 0,
        horarios: [],
        secciones: subjectForChange.secciones as ISection[],
      });
  }, [subjectCodeForChange, subjects]);

  React.useEffect(() => {
    return () => {
      dispatch(setSubjectCodeForChange(""));
    };
  }, [dispatch]);

  const confirm = (newSection: ISection, actualSection: ISelectedSubject) => {
    if (actualSection?.estaInscrita) {
      modal.info({
        title: "No es posible matricular en este horario",
        icon: <Icon name="" color="#FFC53D" />,
        content: `Ya ha matriculado la asignatura ${actualSection?.asignaturaNombre}, que coincide con este horario.`,
        okText: "Aceptar",
        style: { top: 200 },
      });
    } else {
      modal.confirm({
        title: `¿Desea agregar la sección de ${selectedSubject?.asignaturaNombre} en este horario?`,
        icon: <Icon name="" color="#FFC53D" />,
        content: `La sección ${newSection.asignaturaSeccionCodigo} anteriormente escogida de ${actualSection.asignaturaNombre} será removida.`,
        okText: "Aceptar",
        onOk: () => {
          addSection(newSection);
        },
        cancelText: "Cancelar",
        style: { top: 200 },
      });
    }
  };

  const {
    getSlotsBySection,
    findScheduleCoincidence,
    removeSection,
    handleCollision,
    addSection,
    verifyCollision,
    getAvailableSlots,
    isLoadingSomething,
    selectedSections,
  } = useManageSubjects(
    selectedData,
    registeredSubjects,
    subjects,
    selectedSubject,
    confirm,
    updateSubjects,
    periodId,
  );

  React.useEffect(() => {
    setValue("materiasSeleccionadas", selectedSections);
  }, [selectedSections, setValue]);

  const getSelectedSectionsAndCollisions = (startHour: { twentyFour: string; twelve: string }) => {
    const sectionsWithButtons = findScheduleCoincidence(startHour, selectedSections)?.map(
      ({ seccion, horarios }) => {
        const button = (
          <ButtonSubject
            randomColor={seccion.color}
            section={seccion.asignaturaNombre}
            type="selected"
            handleChange={
              selectedSections?.find((sect) => sect.seccionId === seccion.seccionId)
                ? () => {
                    removeSection(seccion);
                  }
                : () => handleCollision(verifyCollision(seccion))
            }
          />
        );

        return { ...seccion, horarios, button };
      },
    );

    const sectionsToAdd = getSectionActiveByHour(startHour).filter(
      (sec) =>
        !sectionsWithButtons?.find(
          (s) =>
            s.seccionId === sec.seccionId &&
            s.horarios[0].diaId === sec.horarios[0].diaId &&
            s.horarios[0].horaInicio === sec.horarios[0].horaInicio &&
            s.horarios[0].horaFin === sec.horarios[0].horaFin,
        ),
    );

    const alreadyRegisteredSections = getAlreadyRegisteredSectionsByHour(startHour).filter(
      (sec) =>
        !sectionsWithButtons?.find(
          (s) =>
            s.seccionId === sec.seccionId &&
            s.horarios[0].diaId === sec.horarios[0].diaId &&
            s.horarios[0].horaInicio === sec.horarios[0].horaInicio &&
            s.horarios[0].horaFin === sec.horarios[0].horaFin,
        ),
    );

    return [...sectionsWithButtons, ...alreadyRegisteredSections, ...sectionsToAdd];
  };

  const getAlreadyRegisteredSectionsByHour = (startHour: {
    twentyFour: string;
    twelve: string;
  }) => {
    const sectionsWithButtons = findScheduleCoincidence(startHour, registeredSubjects ?? [])?.map(
      ({ seccion, horarios }) => {
        const button = (
          <ButtonSubject
            type="registered"
            randomColor="rgba(206, 161, 0, "
            section={seccion.asignaturaNombre}
            status="Inscrita"
            handleChange={() => null}
          />
        );

        return { ...seccion, horarios, button };
      },
    );
    return sectionsWithButtons;
  };

  const getSectionActiveByHour = (startHour: { twentyFour: string; twelve: string }) => {
    const sectionsWithButtons = findScheduleCoincidence(
      startHour,
      selectedSubject?.secciones ?? [],
    )?.map(({ seccion, horarios }) => {
      const collisionResult = verifyCollision(seccion);
      const isCollision = collisionResult.subjectCollision;

      const button = (
        <ButtonSubject
          type={isCollision ? "collision" : "avaliable"}
          randomColor="rgba(206, 161, 0, "
          section={`sección ${seccion.asignaturaSeccionCodigo}`}
          status={getSlotsBySection(seccion.seccionId)}
          handleChange={() => handleCollision(collisionResult)}
        />
      );

      return { ...seccion, horarios, button };
    });
    return sectionsWithButtons;
  };

  const getSesion = (monedaId: number) => {
    return monedaId === 2 ? "₡" : "$";
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Oferta`,
      children: (
        <SubjectTableSchedule
          isLoading={isLoadingSomething || isLoading}
          secciones={selectedSubject?.secciones?.map((sc) => {
            const cupo: string = sc.cupo;
            // const cupo: string = getSlotsBySection(sc.seccionId);
            const estaSeleccionada = selectedSections?.find(
              (sect) => sect.seccionId === sc.seccionId,
            );
            return {
              ...sc,
              cupo,
              seccionPrecio: `${getSesion(selectedSubject.monedaId ?? 2)} ${FormatterUtils.number(
                (sc.seccionPrecio as number)?.toFixed(2),
              )}`,
              acciones: (
                <Button
                  type="link"
                  disabled={
                    (cupo === "lleno" || cupo == "0" || cupo === "Indefinido") && !estaSeleccionada
                  }
                  onClick={
                    estaSeleccionada
                      ? () => {
                          removeSection(sc);
                        }
                      : () => {
                          handleCollision(verifyCollision(sc));
                        }
                  }
                >
                  {estaSeleccionada ? "Remover selección" : "Seleccionar"}
                  {verifyCollision(sc).subjectCollision !== undefined ? (
                    <ErrorTableMessage>Choque de horarios</ErrorTableMessage>
                  ) : null}
                </Button>
              ),
            };
          })}
        />
      ),
    },
    {
      key: "2",
      label: `Oferta en vista de calendario`,
      children: (
        <SubjectCalendarSchedule
          isLoading={isLoadingSomething || isLoading}
          secciones={horas.map((hr) => ({
            hora: hr.twelve,
            secciones: getSelectedSectionsAndCollisions(hr)
              .sort((a, b) => a.seccionId - b.seccionId)
              ?.map((sc) => ({
                ...sc,
                // cupo: getSlotsBySection(sc.seccionId),
              })),
          }))}
        />
      ),
    },
    {
      key: "3",
      label: `Descripción de la materia`,
      children: (
        <SubjectDescriptionDisplay
          descripcion={selectedSubject?.descripcion ?? ""}
          asignaturaNombre={
            selectedSubject?.asignaturaNombre ??
            "Selecciona una materia para ver su información y horario."
          }
          contenido={selectedSubject?.contenido ?? []}
          asignaturaCodigo={selectedSubject?.asignaturaCodigo ?? ""}
          asignaturaId={0}
        />
      ),
    },
  ];

  return (
    <Box style={{ position: "relative" }}>
      {subjects?.length == 0 ? (
        <>
          {esMatriculaAdministrativa && (
            <Button
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                zIndex: 20,
                borderColor: "#006aea",
                color: "#006aea",
              }}
              type="default"
              onClick={setSufficiencyDrawerVisible}
            >
              Agregar suficiencia
            </Button>
          )}
          <NoSubjectsText />
        </>
      ) : (
        <CardCalendarSubjectsStyled>
          <Box>
            <SubjectsListStyled>
              <SubjectsContainerStyled>
                {esMatriculaAdministrativa && (
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: "#006aea",
                      color: "#006aea",
                    }}
                    type="default"
                    onClick={setSufficiencyDrawerVisible}
                  >
                    <Icon name="" />
                    Suficiencia
                  </Button>
                )}
                <Skeleton loading={isLoading} active round />
                <Skeleton loading={isLoading} active round />
                <Skeleton loading={isLoading} active round />
                {!isLoading &&
                  subjects?.map((subject) => (
                    <SubjectButtonStyled
                      key={subject.asignaturaCodigo}
                      style={{ alignItems: "start" }}
                      type="text"
                      onClick={() => {
                        getAvailableSlots(
                          subject.asignaturaId ?? 0,
                          getValues().programa.carreraId,
                        ).then(() => {
                          setSelectedSubject({
                            ...subject,
                            seccionId: 0,
                            horarios: [],
                            secciones: subject.secciones?.map((sec) => ({
                              ...sec,
                            })) as ISection[],
                          });
                        });
                      }}
                      className={
                        selectedSubject?.asignaturaCodigo === subject.asignaturaCodigo
                          ? "is-selected"
                          : ""
                      }
                    >
                      <span>
                        {subject.asignaturaCodigo} {subject.asignaturaNombre}
                      </span>
                      <Box>Créditos: {subject.creditos}</Box>
                      <Box>H.S: {subject.hs}</Box>
                      {subject.esSuficiencia && (
                        <span className="cost-container">
                          <Tag color="processing" bordered={false}>
                            Suficiencia
                          </Tag>
                        </span>
                      )}
                      <hr />
                    </SubjectButtonStyled>
                  ))}
              </SubjectsContainerStyled>
            </SubjectsListStyled>
            <Box>
              <TabContainerStyled
                defaultActiveKey={subjectCodeForChange === "" ? "1" : "2"}
                items={items}
              />
            </Box>
          </Box>
        </CardCalendarSubjectsStyled>
      )}
      {contextHolder}
    </Box>
  );
};

export default RegisterSelection;
