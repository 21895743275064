import { api } from "./apiBaseQuery";

export const generalSettingsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralSettings: builder.mutation({
      query: () => {
        return {
          url: `GeneralSettings`,
          method: "GET",
        };
      },
    }),
    saveGeneralSettings: builder.mutation({
      query: (body) => {
        return {
          url: `GeneralSettings`,
          method: "PUT",
          body,
        };
      },
    }),
    resetGeneralSettings: builder.mutation({
      query: (clave) => {
        return {
          url: `GeneralSettings/Default?clave=${clave}`,
          method: "PUT",
        };
      },
    }),
  }),
});

export const {
  useGetGeneralSettingsMutation,
  useSaveGeneralSettingsMutation,
  useResetGeneralSettingsMutation,
} = generalSettingsServices;
