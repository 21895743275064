import React, { useEffect } from "react";
import { Image } from "@nubeteck/components";
import LogoCenfotec from "src/assets/LogoCenfotec.png";
import LoginImg from "src/assets/Settings.svg";
import {
  FlexContainerStyled,
  ImageContainerStyled,
  FormSection,
  SubHeadingStyled,
  Frame,
  LoginButtonContainer,
} from "./loginAdm.styled";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useLoginAdmUserMutation } from "src/services";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import toast from "react-hot-toast";
import { toastErrorStyle } from "src/constants";
import { Button } from "@nubeteck/components";

const LoginAdm = () => {
  const [loginAdmUser, { data, isLoading, isSuccess, isError, error }] = useLoginAdmUserMutation();

  const navigate = useNavigate();
  const handleCallbackResponse = async (response: CredentialResponse) => {
    loginAdmUser({ credential: response.credential });
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", JSON.stringify(data.data.refreshToken));
      navigate("/");
    }
    if (error) {
      if ("status" in error) {
        const errStatus = "error" in error ? error.status : error.status;
        if (errStatus == 401) toast.error("Usuario no autorizado.", toastErrorStyle);
        else
          toast.error("Ha ocurrido un error iniciando sesión, Intente de nuevo.", toastErrorStyle);
      }
    }
  }, [isSuccess, data, navigate, isError, error]);

  return (
    <Spin spinning={isLoading} size="large">
      <FlexContainerStyled style={{ justifyContent: "space-between" }}>
        <ImageContainerStyled>
          <Image src={LoginImg} width={300} preview={false} />
        </ImageContainerStyled>
        <Frame>
          <FormSection>
            <Image src={LogoCenfotec} width={145} preview={false} />
            <SubHeadingStyled>Autoservicio administradores</SubHeadingStyled>
            <LoginButtonContainer>
              <SubHeadingStyled>Inicio de sesión</SubHeadingStyled>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleCallbackResponse(credentialResponse);
                }}
                onError={() => {
                  toast.error("Ha ocurrido un error", toastErrorStyle);
                }}
              />
              <Button type="link" onClick={() => navigate("/login")}>
                Iniciar sesión como estudiante
              </Button>
            </LoginButtonContainer>
          </FormSection>
        </Frame>
      </FlexContainerStyled>
    </Spin>
  );
};

export default LoginAdm;
