import { api } from "./apiBaseQuery";

export const importsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getCareersImports: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Carreras`,
          method: "POST",
          body,
        };
      },
    }),
    getCareersByStudentId: builder.mutation({
      query: (matricula) => {
        return {
          url: `Admins/Estudiantes/Carreras/${matricula}`,
          method: "GET",
        };
      },
    }),
    getCareersWithCodeByStudentId: builder.mutation({
      query: (matricula: string) => {
        return {
          url: `Admins/Estudiantes/CarrerasConCodigo/${matricula}`,
          method: "GET",
        };
      },
    }),
    updateSubjectsWeight: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Asignatura`,
          method: "PUT",
          body,
        };
      },
    }),
    getSubjectsImports: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Asignaturas`,
          method: "POST",
          body,
        };
      },
    }),
    getStudentsImports: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Estudiantes/`,
          method: "POST",
          body,
        };
      },
    }),
    getProjectionsImports: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/Proyecciones/`,
          method: "POST",
          body,
        };
      },
    }),
    getStudentsProjectionsExcel: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/ProyeccionesExcel/`,
          method: "POST",
          body,
          keepUnusedDataFor: 0.0001,
        };
      },
    }),
    getCoursesProjectionsImports: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/ProyeccionesAsignaturas/`,
          method: "POST",
          body,
        };
      },
    }),
    getCoursesProjectionsExcel: builder.mutation({
      query: (body) => {
        return {
          url: `/Admins/ProyeccionesAsignaturasExcel/`,
          method: "POST",
          body,
          keepUnusedDataFor: 0.0001,
        };
      },
    }),
    importData: builder.mutation({
      query: (periodoId) => {
        return {
          url: `Admins/Importar/${periodoId}`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetCareersImportsMutation,
  useGetCareersByStudentIdMutation,
  useGetCareersWithCodeByStudentIdMutation,
  useGetStudentsImportsMutation,
  useGetSubjectsImportsMutation,
  useGetProjectionsImportsMutation,
  useGetCoursesProjectionsImportsMutation,
  useGetCoursesProjectionsExcelMutation,
  useGetStudentsProjectionsExcelMutation,
  useUpdateSubjectsWeightMutation,
  useImportDataMutation,
} = importsServices;
