import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    estudianteId: 0,
    fullName: "",
    hayProcesoAbierto: false,
    bloqueos: [],
    estaSuspendido: false,
    id: "",
    saldoVencidoColones: 0,
    saldoVencidoDolares: 0,
    correoElectronico: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    cedula: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
