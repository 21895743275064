import { Button, Card, Constants, Flex, Modal, Space, Text } from "@nubeteck/components";
import styled from "styled-components";
import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";

const { device, maxDevice } = Constants;

export const CalendarContainer = styled(Flex)`
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  gap: 16px;

  .ant-tabs-nav-wrap {
    flex: none !important;
  }

  .rbc-btn-group:last-child {
    display: none;
  }

  .rbc-toolbar {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary800};
    font-weight: bold;
  }

  .rbc-toolbar-label {
    text-align: center;

    @media ${device.mobileM} {
      text-align: right;
    }
  }

  @media (max-width: 640px) {
    .rbc-toolbar .rbc-btn-group button:first-child {
      background: ${({ theme }) => theme.colors.primary700} !important;
      position: absolute;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bolder !important;
      color: #fff;
      box-sizing: border-box;
      padding: 8px;
      cursor: pointer;
    }

    .rbc-toolbar .rbc-btn-group button:nth-child(2) {
      margin-left: 54px !important;
    }

    .rbc-toolbar .rbc-btn-group button:nth-child(2),
    .rbc-toolbar .rbc-btn-group button:nth-child(3) {
      border: 2px solid ${({ theme }) => theme.colors.primary700} !important;
      font-size: 14px;
      font-weight: bolder !important;
      color: ${({ theme }) => theme.colors.primary700};
      box-sizing: border-box;
      background-color: #fff !important;
      border-radius: 9999px;
      padding: 0px;
      width: 24px;
      height: 24px;
      margin: 5px;
      cursor: pointer;
    }
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }

  .rbc-event {
    background: ${({ theme }) => theme.colors.primary100};
    border: 1px solid ${({ theme }) => theme.colors.primary300};
    color: ${({ theme }) => theme.colors.colorAccent};
    font-weight: 600;
  }

  .rbc-show-more {
    color: ${({ theme }) => theme.colors.colorAccent};
  }
`;

export const CalendarText = styled(LargeHeadingStyled)`
  margin-bottom: 0;
  justify-self: flex-start;
`;

export const CalendarBody = styled(Card)`
border-radius: 10px;
background-color: ${({ theme }) => theme.colors.primary100};

  .ant-card-body {

    .rbc-toolbar, .rbc-toolbar button {
        font-weight: bold;        
    }

    .rbc-toolbar {
        background-color: ${({ theme }) => theme.colors.primary100};
        font-size: 20px;
        color: ${({ theme }) => theme.colors.primary800};
        padding-bottom: 8px;
        justify-content: flex-start;
    }

    .rbc-toolbar .rbc-toolbar-label {
        flex-grow: 0;
    }
    

    .rbc-toolbar > .rbc-btn-group > button:first-child, .rbc-btn-group:last-child {
        display: none;
    }

    .rbc-toolbar button {
        border: 2px solid ${({ theme }) => theme.colors.primary700} !important;
        font-size: 14px;
        font-weight: bolder !important;
        color: ${({ theme }) => theme.colors.primary700};
        box-sizing: border-box;
        background-color: #fff !important;
        border-radius: 9999px;
        padding: 0px;
        width: 24px;
        height: 24px;
        margin: 5px;
    }


    .rbc-btn-group button {
        border: none;
        cursor: pointer;
    }

    .rbc-toolbar button:hover, .rbc-toolbar .rbc-active {
        background: none;
        border-bottom: 3px solid ${({ theme }) => theme.colors.primary600};
        box-shadow: none;
    }

    .rbc-toolbar .rbc-btn-group:first-child > button:nth-child(2), .rbc-toolbar .rbc-btn-group:first-child > button:nth-child(3) {
        background: none;
        border: none;
    }

    .rbc-month-view {
        border: none;
    }

    @media ${maxDevice.tablet} {
        .rbc-btn-group {
            display: none;
        }
  }
`;

export const CalendarBodyOld = styled(Card)`
  .ant-card-body {
    height: 75vh;
    padding: 0px;
    border-left: 10px solid ${({ theme }) => theme.colors.primary100};
    border-bottom: 18px solid ${({ theme }) => theme.colors.primary100};
    border-right: 10px solid ${({ theme }) => theme.colors.primary100};
    border-radius: 10px;

    .ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel {
      height: 65vh;
      overflow-y: auto;
    }

    .Kalend__CalendarDesktopNavigation__container,
    .Kalend__CalendarDesktopNavigation__container-mobile {
      .Kalend__ButtonIcon__svg,
      .Kalend__ButtonIcon__svg-normal,
      .Kalend__ButtonIcon__svg-normal-dark,
      .Kalend__ButtonIcon__svg-dark {
        background: white;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.primary500};
        padding: 5px;
        box-sizing: border-box;
        color: ${({ theme }) => theme.colors.primary500};
      }

      background-color: ${({ theme }) => theme.colors.primary100};
      border-radius: 0px;
      .Kalend__HeaderCalendarTitle,
      .Kalend__HeaderCalendarTitle-mobile {
        color: ${({ theme }) => theme.colors.colorAccent};
      }
    }
  }
`;

export const CalendarButtonStyled = styled(Button)`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primary500};
  font-size: 12px;
  width: 100%;
  height: 25px;
  padding: 3px;
  display: flex;
  justify-content: center;
  font-weight: bold;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const CalendarListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 0;
  padding: 0;
  list-style: none;

  &.filled-list::before {
    content: "";
    position: absolute;
    height: 96%;
    width: 99%;
    top: 0;
    left: 0;
    background-color: rgba(46, 131, 234, 0.15);
    border-radius: 15px;
  }
`;

export const ModalHead = styled(Space)`
  background: ${({ theme }) => theme.colors.primary500};
  width: calc(100% - 32px);
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ModalBody = styled(Flex)`
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
`;
export const TimeContainer = styled(Space)`
  flex-direction: column;
  border-left: 5px solid ${({ theme }) => theme.colors.colorAccent};
  padding-left: 8px;
`;

export const ModalText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary600};
  font-size: 15px;
  font-weight: 600;
`;

export const ModalSubjectText = styled(Text)`
  font-size: 15px;
  font-weight: bold;
`;

export const ModalShowMore = styled(Modal)`
  min-width: 300px;

  @media ${device.tablet} {
    min-width: 500px;
  }
`;
